import React, { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import { ShipmentAction, DisplayableShipment } from 'types/Shipments'
import { useShipmentActions, ActionType as ShipmentActionType } from 'services/ShipmentActions'
import Menu from '../../data/DataGrid/ActionMenu'
import { ListItemIcon } from './styled'

export type ActionType = ShipmentActionType

export type ActionMenuProps = {
  shipment: DisplayableShipment | DisplayableShipment[]
  handleShipmentAction: (
    action: ShipmentAction,
    shipment: DisplayableShipment | DisplayableShipment[]
  ) => void
  disabledActions?: ShipmentAction[]
}

const ActionMenu = ({ shipment, handleShipmentAction, disabledActions }: ActionMenuProps) => {
  const actions = useShipmentActions(shipment)
  const [availableActions, setAvailableActions] = useState<ShipmentAction[]>([])

  useEffect(() => {
    const available = actions.getAvailableActions()

    setAvailableActions(
      available.filter((a) => (disabledActions ? !disabledActions.includes(a) : true))
    )
  }, [actions, disabledActions])

  return (
    <Menu disabled={Boolean(!availableActions.length)}>
      {({ onClick }) =>
        availableActions.map((action) => (
          <MenuItem
            key={action}
            onClick={() => {
              onClick()
              handleShipmentAction(action, shipment)
            }}
          >
            <ListItemIcon type={actions[action].getType()}>
              {actions[action].getIcon()}
            </ListItemIcon>
            {actions[action].getLabel()}
          </MenuItem>
        ))
      }
    </Menu>
  )
}

export default ActionMenu
