import { useState, useEffect } from 'react'
import { Itinerary } from 'types'
import { getItineraryActions, ItineraryActionsOptions } from './index'

export const useItineraryActions = <T extends Itinerary>(itinerary: T | T[]) => {
  const [actions, setActions] = useState<ItineraryActionsOptions>(getItineraryActions(itinerary))

  useEffect(() => {
    setActions(getItineraryActions(itinerary))
  }, [itinerary])

  return actions
}

export default useItineraryActions
