import { LocationOnOutlined, StoreOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { ItineraryStop, ItineraryStopActionType } from 'types'

type StopAddressProps = {
  stop: ItineraryStop
}

const StopAddress = ({ stop }: StopAddressProps) => {
  const names = stop.actions.flatMap(({ type, shipment }) => {
    switch (type) {
      case ItineraryStopActionType.Deliver:
        return shipment.destinationAddress.name
      case ItineraryStopActionType.PickUp:
        return shipment.sourceAddress.name
    }
  })

  type Name = keyof typeof names

  const counts = names.reduce(
    (acc, name) => ({
      ...acc,
      [name]: (acc[name as Name] ?? 0) + 1,
    }),
    {} as Record<Name, number>
  )

  const companies = Array.from(new Set(names)).sort((a, b) => counts[a as Name] - counts[b as Name])

  return (
    <Box
      display="inline-flex"
      flexWrap="wrap"
      flexGrow="1"
      columnGap="2em"
      fontFamily="Courier New, monospace"
      my={1}
    >
      <Box display="inline-flex" alignItems="center">
        <LocationOnOutlined color="primary" sx={{ marginX: 1 }} />
        <span style={{ marginTop: 2 }}>{stop.addressString}</span>
      </Box>
      <Box display="inline-flex" alignItems="center">
        <StoreOutlined color="primary" sx={{ marginX: 1 }} />
        <span style={{ marginTop: 2 }}>
          {companies?.[0]} {companies.length > 1 && <>(+{companies.length - 1})</>}
        </span>
      </Box>
    </Box>
  )
}

export default StopAddress
