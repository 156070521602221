import { createAction } from '@reduxjs/toolkit'
import { GetDataArgs } from 'store/root'
import { StoreNames } from 'types/Misc'
import {
  createAsyncAction,
  createActionWithCallback as withCallback,
} from 'store/utils/actionCreators'
import {} from 'react-router-dom'
import { Itinerary } from 'types'
import {
  ItineraryAddShipmentsParams,
  ItineraryAssignParams,
  ItineraryCancelParams,
  ItineraryCreateParams,
  ItineraryRemoveShipmentsParams,
  ItineraryReorderStopParams,
  ItinerarySetStopOrderParams,
  ItineraryUnassignParams,
} from 'api/types'

const name = StoreNames.itineraries

export const setItinerary = createAction<Itinerary>(`${name}_SET_ONE`)
export const setItineraries = createAction<Itinerary[]>(`${name}_SET_MANY`)

export const createItinerary = withCallback<ItineraryCreateParams>(`${name}_CREATE`)
export const addShipmentsToItinerary = createAction<ItineraryAddShipmentsParams>(
  `${name}_ADD_SHIPMENTS`
)
export const removeShipmentsFromItinerary = createAction<ItineraryRemoveShipmentsParams>(
  `${name}_REMOVE_SHIPMENTS`
)
export const reorderItineraryStops = createAction<ItineraryReorderStopParams>(
  `${name}_REORDER_STOP`
)
export const setItineraryStopOrder = createAction<ItinerarySetStopOrderParams>(
  `${name}_SET_STOP_ORDER`
)
export const cancelItinerary = createAction<ItineraryCancelParams>(`${name}_CANCEL`)
export const assignItinerary = withCallback<ItineraryAssignParams>(`${name}_ASSIGN`)
export const unassignItinerary = withCallback<ItineraryUnassignParams>(`${name}_UNASSIGN`)

export const getItineraries = createAsyncAction<GetDataArgs, undefined, Itinerary[], string>(name)
export const getItinerary = createAction<{ id: string }>(name)

export const pollItineraries = createAction(`${name}_POLL`)
export const clearItineraries = createAction(`${name}_CLEAR`)
