import React, { useEffect, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Grid, InputLabel, MenuItem, Select } from '@mui/material'

import { Role } from 'types'
import { UserTemplate } from '../index'
import {
  ValidationError,
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
} from './validators'
import { InputField, SelectChip, SelectFormControl } from './styled'

type SelectEntitlementsProps = {
  role: Role
  availableRoles: string[]
  onChange: (role: Role) => void
}

const SelectEntitlements = ({ role, availableRoles, onChange }: SelectEntitlementsProps) => (
  <SelectFormControl key="entitlements">
    <InputLabel id="select-entitlement-label" shrink>
      <Translate value="UI.Users.Invite.Entitlements" />
    </InputLabel>
    <Select
      id="select-entitlement"
      labelId="select-entitlement-label"
      label={<Translate value="UI.Users.Invite.Entitlements" />}
      value={role}
      onChange={(e) => onChange(e.target.value as Role)}
      renderValue={(r: any) => (
        <>
          <SelectChip size="small" label={<Translate value={`Entities.User.Roles.${r}`} />} />
        </>
      )}
    >
      {availableRoles.map((r) => (
        <MenuItem key={r} value={r}>
          <Translate value={`Entities.User.Roles.${r}`} />
        </MenuItem>
      ))}
    </Select>
  </SelectFormControl>
)

const validators: { [field: string]: ValidationError } = {
  email: {
    valid: false,
    error: undefined,
  },
  password: {
    valid: false,
    error: undefined,
  },
  firstName: {
    valid: false,
    error: undefined,
  },
  lastName: {
    valid: false,
    error: undefined,
  },
}

export type InviteUserFormProps = {
  onChange: (user: UserTemplate) => void
  onValidation: (valid: boolean) => void
}

export const InviteUserForm = ({ onChange, onValidation }: InviteUserFormProps) => {
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [role, setRole] = useState(Role.Courier)

  const [emailValidation, setEmailValidation] = useState(validators.email)
  const [passwordValidation, setPasswordValidation] = useState(validators.password)
  const [firstNameValidation, setFirstNameValidation] = useState(validators.firstName)
  const [lastNameValidation, setLastNameValidation] = useState(validators.lastName)

  const [allValid, setAllValid] = useState(false)

  useEffect(() => {
    setFirstNameValidation(validateFirstName(firstName))
    setLastNameValidation(validateLastName(lastName))
    setPasswordValidation(validatePassword(password))
    setEmailValidation(validateEmail(email))
  }, [firstName, email, lastName, password])

  useEffect(() => {
    const allValidated =
      firstNameValidation.valid &&
      lastNameValidation.valid &&
      passwordValidation.valid &&
      emailValidation.valid

    setAllValid(allValidated)
  }, [firstNameValidation, lastNameValidation, passwordValidation, emailValidation])

  useEffect(() => {
    onValidation(allValid)
  }, [allValid, onValidation])

  useEffect(() => {
    const allSet = !!email && !!password && !!firstName && !!lastName && !!role

    if (allSet && allValid) {
      const user = { firstName, lastName, email, password, role } as UserTemplate
      onChange(user)
    }
  }, [email, password, firstName, lastName, role, onChange, allValid])

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Users.Invite.FistName')}
          value={firstName}
          error={!!firstNameValidation.error}
          helperText={firstNameValidation.error ?? ''}
          onChange={(e: any) => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Users.Invite.LastName')}
          value={lastName}
          error={!!lastNameValidation.error}
          helperText={lastNameValidation.error ?? ''}
          onChange={(e: any) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Users.Invite.Email')}
          value={email}
          error={!!emailValidation.error}
          helperText={emailValidation.error ?? ''}
          onChange={(e: any) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Users.Invite.Password')}
          value={password}
          error={!!passwordValidation.error}
          helperText={passwordValidation.error ?? ''}
          onChange={(e: any) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <SelectEntitlements role={role} availableRoles={Object.keys(Role)} onChange={setRole} />
      </Grid>
    </Grid>
  )
}

export default InviteUserForm
