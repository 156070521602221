import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Box, Button, IconButton, ListItem, Tooltip } from '@mui/material'
import { ArrowUpward, DragIndicator, ExpandLess, ExpandMore } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'

import {
  ItineraryStop,
  ItineraryStopAction,
  ItineraryStopActionStatus,
  ItineraryStopActionType,
  ShipmentStatus,
} from 'types'
import { pickupShipment } from 'store/shipments/actions'
import groupBy from 'helpers/groupBy'

import Paper from './styled'
import StopActions from './StopActions'
import StopProgressBar from './StopProgresBar'
import StopDetails from './StopDetails'
import { getItinerary } from 'store/itineraries/actions'
import StopAddress from './StopAddress'

export type ItineraryStopProps = {
  stop: ItineraryStop
  index: number
  itineraryId: string
}

const shipmentsToPickup = (actions: ItineraryStopAction[]) =>
  actions
    .filter(
      (a) =>
        a.status === ItineraryStopActionStatus.Incomplete &&
        a.shipment.status === ShipmentStatus.Reserved
    )
    .map((a) => a.shipment)

type PickupButtonProps = {
  onClick: () => void
  disabled: boolean
}

const PickupButton = ({ onClick, disabled }: PickupButtonProps) => (
  <Tooltip title={<Translate value="UI.Itineraries.Details.Stops.Actions.PickupAll" />}>
    <Box display="flex">
      <Button
        sx={{ paddingX: 1, paddingY: 0, minWidth: '2rem' }}
        size="small"
        onClick={onClick}
        disabled={disabled}
      >
        <ArrowUpward />
      </Button>
    </Box>
  </Tooltip>
)

const ItineraryStopItem = ({ stop, index, itineraryId }: ItineraryStopProps) => {
  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState(false)

  const [actionsPerType, setActionsPerType] =
    useState<{ [key in ItineraryStopActionType]: ItineraryStopAction[] }>()

  const [dragHandleHovered, setDragHandleHovered] = useState(false)

  // update actions when stop changes
  useEffect(() => {
    const grouped = groupBy(stop.actions, (a: ItineraryStopAction) => a.type)
    setActionsPerType(grouped)
  }, [stop])

  const handlePickup = (actions: ItineraryStopAction[]) => {
    const eligibleShipments = shipmentsToPickup(actions)

    dispatch(
      pickupShipment({
        data: eligibleShipments,
        callback: (successful) => {
          if (successful) dispatch(getItinerary({ id: itineraryId }))
        },
      })
    )
  }

  return (
    <Draggable key={stop.id} draggableId={stop.id} index={index}>
      {(provided, snapshot) => (
        <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ paddingX: 0 }}>
          <Paper $isDragging={snapshot.isDragging} $isDragHandleHovered={dragHandleHovered}>
            <Box display="flex" flexGrow={1} flexDirection="column">
              <Box display="flex">
                <Box
                  {...provided.dragHandleProps}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onMouseEnter={() => {
                    setDragHandleHovered(true)
                  }}
                  onMouseLeave={() => {
                    setDragHandleHovered(false)
                  }}
                  paddingX={2}
                >
                  <DragIndicator
                    fontSize="medium"
                    sx={[
                      { margin: -1, ml: -2 },
                      (theme) => ({
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      }),
                    ]}
                  />
                </Box>
                <Box display="flex" flexGrow={1} flexWrap="wrap" sx={{ columnGap: '2em' }}>
                  <PickupButton
                    onClick={() => handlePickup(actionsPerType?.PickUp ?? [])}
                    disabled={!shipmentsToPickup(actionsPerType?.PickUp ?? []).length}
                  />
                  <StopActions actionsPerType={actionsPerType} />
                  <StopAddress stop={stop} />
                  <Box display="flex">
                    <IconButton
                      color="primary"
                      onClick={() => setExpanded(!expanded)}
                      component="span"
                    >
                      {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <StopProgressBar stop={stop} />
                  </Box>
                </Box>
              </Box>
              <StopDetails stop={stop} expanded={expanded} />
            </Box>
          </Paper>
        </ListItem>
      )}
    </Draggable>
  )
}

export default ItineraryStopItem
