import * as GQL from 'gql/graphql'

export type TransportRequest = GQL.ShipmentRequest

export enum TransportRequestAction {
  Accept = 'accept',
  Reject = 'reject',
}

export { ShipmentRequestStatus as TransportRequestStatus } from 'gql/graphql'

export default TransportRequest
