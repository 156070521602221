import { ItineraryAction } from 'types'

export type ActionType = 'default' | 'danger'

export const getActionType = (action: ItineraryAction) => {
  switch (action) {
    case ItineraryAction.Cancel:
      return 'danger'
    default:
      return 'default'
  }
}
