import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { MUIDataTableOptions } from 'mui-datatables'

import { DisplayableShipment } from 'types/Shipments'
import flattenObject from 'helpers/flattenObject'
import { selectAllUsers } from 'store/users/selectors'
import DataGrid, { SORT_ORDER } from 'components/features/data/DataGrid'

import getColumns, { ShipmentColumns } from './columns'

export type ShipmentsListProps = {
  name: string
  data: DisplayableShipment[]
  onSelect: (shipment: DisplayableShipment[]) => void
}

const defaultOptions: MUIDataTableOptions = {
  selectableRows: 'multiple',
  responsive: 'simple',
  tableBodyMaxHeight: '50vh',
  customToolbarSelect: () => {
    return <Box height="3rem" />
  },
}

const ShipmentsList = ({ name, data, onSelect }: ShipmentsListProps) => {
  const flattenedData = useMemo(() => data.map(flattenObject), [data])

  const users = useSelector(selectAllUsers)
  const columns = getColumns(data, users)

  const options: MUIDataTableOptions = {
    ...defaultOptions,
    onRowSelectionChange: (_currentRowsSelected, allRowsSelected) => {
      const shipments = allRowsSelected.map((selected) => data[selected.dataIndex])

      onSelect(shipments as DisplayableShipment[])
    },
  }

  const defaults = {
    sort: {
      name: ShipmentColumns.Date,
      direction: SORT_ORDER.DESCENDING,
    },
  }

  return (
    <DataGrid
      name={name}
      data={flattenedData}
      columns={columns ?? []}
      loading={false}
      options={options}
      defaults={defaults}
    />
  )
}

export default ShipmentsList
