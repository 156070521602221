import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { Translate } from 'react-redux-i18n'
import { PackageItem as ShipmentItem } from 'types/Shipments'
import { Field, Label } from '../styled'
import { Cross, PotentiallyLast } from './styled'

const X = () => <Cross>x</Cross>

const PackageItem = ({ item }: { item: ShipmentItem }) => (
  <Box margin={2}>
    <Paper>
      <Box padding={4}>
        <Grid container direction="column" spacing={1}>
          <PotentiallyLast container spacing={1} $last={!item.goodsDescription}>
            <Field as={Grid} item>
              <Label>
                <Translate value="UI.Shipments.Details.Item.Weight" />
              </Label>
              {item.grossWeight} kg
            </Field>
            {!!item.volume ?? (
              <Field as={Grid} item>
                <Label>
                  <Translate value="UI.Shipments.Details.Item.Volume" />
                </Label>
                <span>
                  {item.volume} m<sup style={{ verticalAlign: 'top' }}>3</sup>
                </span>
              </Field>
            )}
            {(item.width !== 0 || item.length !== 0 || item.height !== 0) && (
              <Field as={Grid} item>
                <Label>
                  <Translate value="UI.Shipments.Details.Item.Size" />
                </Label>
                <span>
                  {item.width}m <X /> {item.length}m <X /> {item.height}m
                </span>
              </Field>
            )}
          </PotentiallyLast>
          {item.goodsDescription && (
            <Field>
              <Label>
                <Translate value="UI.Shipments.Details.Item.Description" />
              </Label>
              {item.goodsDescription}
            </Field>
          )}
        </Grid>
      </Box>
    </Paper>
  </Box>
)

export default PackageItem
