import { graphql } from 'gql/gql'

export const GetCarrierProductsQuery = graphql(`
  query GetCarrierProducts {
    products {
      id
      name
      carrierProductCode
    }
  }
`)
