import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Grid } from '@mui/material'

import FloatingField from 'components/ui/DetailPages/FloatingField'
import ItineraryStatusChip from 'components/features/itineraries/ItineraryStatusChip'
import { dayMonth } from 'helpers/formatDate'
import { Itinerary } from 'types'

type ItinerariyDetailDataProps = {
  itinerary: Itinerary
}

const ItinerariyDetailData = ({ itinerary }: ItinerariyDetailDataProps) => {
  return (
    <Grid container spacing={2}>
      <FloatingField label={<Translate value="UI.Itineraries.Details.Status" />}>
        <ItineraryStatusChip status={itinerary.status} />
      </FloatingField>
      <FloatingField label={<Translate value="UI.Itineraries.Details.StartDate" />}>
        {dayMonth(itinerary.startDate)}
      </FloatingField>
      <FloatingField label={<Translate value="UI.Itineraries.Details.Name" />}>
        {itinerary.name}
      </FloatingField>
    </Grid>
  )
}

export default ItinerariyDetailData
