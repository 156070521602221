import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useLogtradeAuth } from '@logtrade-technology-ab/logtrade-lib'

const AuthGate = () => {
  const location = useLocation()
  const { isAuthenticated } = useLogtradeAuth()

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
}

export default AuthGate
