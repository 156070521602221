import React, { ReactNode } from 'react'
import { Translate } from 'react-redux-i18n'
import { Box } from '@mui/material'

import { ItineraryStopActionType } from 'types'

type StopActionDataProps = {
  action: ItineraryStopActionType
  amount: number
  icon: ReactNode
}

const StopActionData = ({ action, amount, icon }: StopActionDataProps) => {
  if (amount === 0) return <></>

  let actionText: ReactNode

  switch (action) {
    case ItineraryStopActionType.Deliver:
      actionText = <Translate value="UI.Itineraries.Details.Stops.Deliver" />
      break
    case ItineraryStopActionType.PickUp:
      actionText = <Translate value="UI.Itineraries.Details.Stops.PickUp" />
      break
    default:
      actionText = null
      break
  }

  return (
    <Box display="flex" alignItems="center" paddingX={1}>
      {icon}
      <Box ml={1} fontFamily="Courier New, monospace" sx={{ whiteSpace: 'nowrap' }}>
        {`${amount} `}
        {actionText}
      </Box>
    </Box>
  )
}

export default StopActionData
