import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DisplayData, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables'
import { Translate } from 'react-redux-i18n'
import { Replay } from '@mui/icons-material'

import { APIStatus, DisplayableShipment } from 'types'
import { selectAllUsers } from 'store/users/selectors'
import DataGrid from 'components/features/data/DataGrid'
import flattenObject from 'helpers/flattenObject'
import { getGlobalData } from 'store/root'
import { RootState } from 'store'
import ActionButton from 'components/features/data/DataGrid/ActionButton'

import ActionMenu from './ActionMenu'
import getColumns from './columns'

const isLoading = (status: APIStatus): boolean => status === APIStatus.Loading ?? false

export type ItineraryShipmentListProps = {
  name: string
  data: DisplayableShipment[]
  onRemove: (shipment: DisplayableShipment | DisplayableShipment[]) => void
}

const ItineraryShipmentList = ({ name, data, onRemove }: ItineraryShipmentListProps) => {
  const dispatch = useDispatch()
  const [anyRowsSelected, setAnyRowsSelected] = useState(false)
  const [options, setOptions] = useState<MUIDataTableOptions>({
    onRowSelectionChange: (_, allSelected) => {
      setAnyRowsSelected(!!allSelected.length)
    },
  })

  const users = useSelector(selectAllUsers)
  const flattenedData = useMemo(() => data.map(flattenObject), [data]) // Mui-Datatable prefers flattened data
  const shipmentApiStatus = useSelector((state: RootState) => state.shipments.status)

  const handleRemove = (shipment: DisplayableShipment | DisplayableShipment[]) => {
    onRemove(shipment)
    setOptions({ rowsSelected: [] })
    setAnyRowsSelected(false)
  }

  const toolbar = () => (
    <ActionButton
      icon={<Replay />}
      label={<Translate value="UI.Table.Reload" />}
      onClick={() => dispatch(getGlobalData())}
    />
  )

  const actions = (_displayData: DisplayData, selectedIndices: number[]) => {
    const shipments = selectedIndices.map((i) => data[i])

    return <ActionMenu onClick={() => handleRemove(shipments)} />
  }

  const rowActions = (tableMeta: MUIDataTableMeta) => {
    const shipment = data[tableMeta.rowIndex]

    return <ActionMenu onClick={() => handleRemove(shipment)} disabled={anyRowsSelected} />
  }

  const columns = getColumns(data, users, rowActions)

  return (
    <DataGrid
      name={name}
      data={flattenedData}
      columns={columns ?? []}
      loading={isLoading(shipmentApiStatus)}
      actions={actions}
      toolbar={toolbar}
      options={options}
    />
  )
}

export default React.memo(ItineraryShipmentList)
