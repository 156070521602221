import React, { useState, useEffect, useCallback } from 'react'
import { Translate } from 'react-redux-i18n'
import { isEqual } from 'lodash'

import api from 'api'
import { RootState } from 'store'
import { APIStatus, Itinerary } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { newSnackbar } from 'store/snackbars/actions'
import { selectItineraryById } from 'store/itineraries/selectors'

export const useItinerary = (
  id: string | undefined
): [Itinerary | undefined, (i: Itinerary | undefined) => void] => {
  const dispatch = useDispatch()
  const [itinerary, setItinerary] = useState<Itinerary>()

  const storedItinerary = useSelector((state: RootState) => selectItineraryById(state, id ?? ''))

  const getItinerary = useCallback(
    async (itineraryId: string) => {
      try {
        const i = await api.Itinerary.get(itineraryId)
        setItinerary(i)
      } catch (error: any) {
        dispatch(
          newSnackbar({
            message: <Translate value="UI.Itineraries.Details.Errors.LoadFailed" />,
            status: APIStatus.Failed,
          })
        )
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (storedItinerary) {
      setItinerary((prevItinerary) =>
        isEqual(prevItinerary, storedItinerary) ? prevItinerary : storedItinerary
      )
    } else {
      if (id) getItinerary(id)
    }
  }, [id, getItinerary, storedItinerary])

  return [itinerary, setItinerary]
}

export default useItinerary
