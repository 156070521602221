import { createAction } from '@reduxjs/toolkit'

export const createAsyncAction = <R, P, S, F>(name: string) => {
  const request = createAction<R>(`${name}_REQUEST`)
  return Object.assign(request, {
    pending: createAction<P>(`${name}_PENDING`),
    success: createAction<S>(`${name}_SUCCESS`),
    partialSuccess: createAction<F>(`${name}_PARTIAL_SUCCESS`),
    failure: createAction<F>(`${name}_FAILURE`),
    type: request.type,
  })
}

type EffectAction<R> = { data: R; callback?: (...args: any[]) => void }

export const createActionWithCallback = <R>(name: string) => createAction<EffectAction<R>>(name)
