import { nanoid } from '@reduxjs/toolkit'
import api from 'api'
import { I18n } from 'react-redux-i18n'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getGlobalData } from 'store/root'
import { newRequest } from 'store/snackbars/actions'
import { IolConnection } from 'types'
import { connectToIol, createConnection, getConnections } from './actions'

function* getHandler({ payload = {} }: ReturnType<typeof getConnections>) {
  try {
    if (!payload.silent) yield put(getConnections.pending())

    const data: IolConnection[] = yield call(api.IolConnection.getAll)
    yield put(getConnections.success(data))
  } catch (error: any) {
    if (!payload.silent) yield put(getConnections.failure(error.message))
  }
}

function* createHandler({ payload }: ReturnType<typeof createConnection>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Connections.StatusMessages.Create.Loading'),
    success: I18n.t('UI.Connections.StatusMessages.Create.Success'),
    failure: I18n.t('UI.Connections.StatusMessages.Create.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    yield call(api.IolConnection.createConnection, payload)
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    yield put(getGlobalData())
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* connectToIolHandler({ payload }: ReturnType<typeof connectToIol>) {
  let response: string

  try {
    response = yield call(api.IolConnection.connectToIol, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      iolBaseUrl: process.env.REACT_APP_IOL_API_URL!,
    })

    payload.callback?.(response)
  } catch {
    // eslint-disable-next-line no-console
    console.log('Error connecting to iol')
  }
}

export default [
  takeLatest(getConnections.type, getHandler),
  takeLatest(createConnection.type, createHandler),
  takeLatest(connectToIol.type, connectToIolHandler),
]
