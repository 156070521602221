import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { I18n, Translate } from 'react-redux-i18n'
import { LogtradeLogin, useLogtradeAuth } from '@logtrade-technology-ab/logtrade-lib'
import { LoginDetails } from '@logtrade-technology-ab/logtrade-lib/build/api/types'

import api from 'api'
import { Role } from 'types'

const LoginPage = () => {
  const { isAuthenticated } = useLogtradeAuth()
  const location = useLocation()

  const Login = LogtradeLogin.Page

  if (isAuthenticated) {
    const state = location.state as any // https://github.com/reach/router/issues/414
    return <Navigate to={state?.from ?? '/'} />
  }

  return (
    <Login
      title="Logtrade Move"
      bodyText={{
        email: <Translate value="UI.Auth.Email" />,
        password: <Translate value="UI.Auth.Password" />,
        login: <Translate value="UI.Auth.Login" />,
        remember: <Translate value="UI.Auth.Remember" />,
      }}
      onRequest={async ({ email, password }: LoginDetails) => {
        if (!email || !password) {
          return { code: 'Input' }
        }

        try {
          const { token } = await api.Auth.login({ email, password })
          const { role } = await api.Auth.getUser(token)

          if (role?.toLowerCase() !== Role.Admin.toLowerCase())
            return { code: 'Custom', message: I18n.t('General.Forbidden') }

          return token
        } catch (e: any) {
          switch (e.status) {
            case 401:
              return {
                code: 'Unauthorized',
              }
            case 403:
              return {
                code: 'Custom',
                message: 'Forbidden',
              }
            default:
              return {
                code: 'Unknown',
              }
          }
        }
      }}
    />
  )
}

export default LoginPage
