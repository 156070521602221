import React from 'react'
import { Box } from '@mui/material'
import { Done, Pause, Close } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { Translate } from 'react-redux-i18n'

import { ItineraryStopAction, ItineraryStopActionStatus } from 'types'
import TextLink from 'components/ui/TextLink'

type StopDetailItemProps = {
  action: ItineraryStopAction
}

const isDone = (action: ItineraryStopAction) => action.status === ItineraryStopActionStatus.Complete

const getStatusIcon = (status: ItineraryStopActionStatus) => {
  switch (status) {
    case ItineraryStopActionStatus.Complete:
      return <Done color="success" fontSize="small" sx={{ mt: '-4px' }} />
      break
    case ItineraryStopActionStatus.OnHold:
      return <Pause color="warning" fontSize="small" sx={{ mt: '-4px' }} />
      break
    case ItineraryStopActionStatus.Cancelled:
      return <Close color="error" fontSize="small" sx={{ mt: '-4px' }} />
      break
    default:
      return null
  }
}

const StopDetailItem = ({ action }: StopDetailItemProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      mb={0.5}
      paddingX={2}
      color={isDone(action) ? 'text.disabled' : 'inherit'}
      style={{
        textDecoration: `${isDone(action) ? 'line-through' : 'inherit'}`,
      }}
    >
      <Translate value={`UI.Itineraries.Details.Stops.${action.type}`} />
      <TextLink
        color="textSecondary"
        as={Link}
        to={`/shipments/${action.shipment.id}`}
        style={{ marginLeft: '8px' }}
      >
        {action.shipment.shipmentNumber}
      </TextLink>
      {getStatusIcon(action.status)}
    </Box>
  )
}

export default StopDetailItem
