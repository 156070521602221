import { graphql } from 'gql/gql'

/**
 * Pickup shipments
 */

export const PickupShipmentMutation = graphql(`
  mutation PickupShipment($data: PickupShipmentInput!) {
    pickupShipment(data: $data) {
      results {
        shipmentId
        shipment {
          ...ShipmentFragment
        }
        errorType
      }
    }
  }
`)

/**
 * Assign shipments to a specific user
 */

export const AssignShipmentMutation = graphql(`
  mutation AssignShipment($data: AssignShipmentInput!) {
    assignShipment(data: $data) {
      results {
        shipmentId
        shipment {
          ...ShipmentFragment
        }
        errorType
      }
    }
  }
`)

/**
 * Unassign shipments
 */

export const UnassignShipmentMutation = graphql(`
  mutation UnassignShipment($data: UnassignShipmentInput!) {
    unassignShipment(data: $data) {
      results {
        shipmentId
        shipment {
          ...ShipmentFragment
        }
        errorType
      }
    }
  }
`)

/**
 * Cancel shipments
 */

export const CancelShipmentMutation = graphql(`
  mutation CancelShipment($data: CancelShipmentInput!) {
    cancelShipment(data: $data) {
      results {
        shipmentId
        shipment {
          ...ShipmentFragment
        }
        errorType
      }
    }
  }
`)
