import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Translate } from 'react-redux-i18n'
import { useLogtradeAuth } from '@logtrade-technology-ab/logtrade-lib'
import {
  AppBar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { AccountCircle, Brightness4, Menu as MenuIcon } from '@mui/icons-material'

import { User } from 'types'
import { clearGlobalData } from 'store/root'
import { selectProfile } from 'store/user/selectors'
import PollIndicator from './PollIndicator'
import LanguageSelect from './LanguageSelect'
import { LogoutText, Toolbar } from './styled'
import { useThemeSelector } from 'helpers/hooks'

const Header = ({ onNavOpen }: { onNavOpen: () => void }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const profile = useSelector(selectProfile)
  const authState = useLogtradeAuth()
  const [, toggleDarkMode] = useThemeSelector()

  const [menuAnchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const AccountMenu = ({ user }: { user: User }) => (
    <Menu
      id="menu-appbar"
      anchorEl={menuAnchorEl}
      keepMounted
      open={Boolean(menuAnchorEl)}
      onClose={handleClose}
    >
      <MenuItem disabled>
        <Box display="flex" flexDirection="column">
          <Typography>{user.fullName}</Typography>
          <Typography variant="caption">{user.email}</Typography>
        </Box>
      </MenuItem>
      {/* <Divider variant="middle" /> */}
      <MenuItem
        onClick={() => {
          navigate('/settings')
          handleClose()
        }}
      >
        <Translate value="UI.Settings.Title" />
      </MenuItem>
      <Divider variant="middle" />
      <MenuItem
        onClick={() => {
          authState.signOut()
          dispatch(clearGlobalData())
          handleClose()
        }}
      >
        <LogoutText>
          <Translate value="UI.Auth.Logout" />
        </LogoutText>
      </MenuItem>
    </Menu>
  )

  return (
    <AppBar position="static" variant="elevation" elevation={0} color="transparent">
      <Toolbar>
        <div>
          <Hidden mdUp>
            <IconButton
              edge="start"
              onClick={onNavOpen}
              aria-label="open navigation menu"
              color="inherit"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </div>
        <Box
          display="flex"
          sx={{
            '> *': {
              marginRight: 1,
            },
          }}
        >
          <PollIndicator />
          <Button color="inherit" onClick={() => toggleDarkMode()}>
            <Brightness4 />
          </Button>
          <LanguageSelect />
          <Button
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountMenu}
            color="inherit"
            startIcon={<AccountCircle />}
            sx={{ paddingX: 2 }}
          >
            &nbsp;
            {profile.fullName}
          </Button>
          <AccountMenu user={profile} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
