import { FetchResult } from '@apollo/client'
import { apolloClient } from 'api/client'
import { parseResult as parseQueryResult } from 'api/util'

import {
  AssignShipmentMutation,
  CancelShipmentMutation,
  PickupShipmentMutation,
  UnassignShipmentMutation,
} from './mutations'

import { GetShipmentQuery, GetShipmentsQuery } from './queries'

import * as GQL from 'gql/graphql'

export type ShipmentMutationResult = {
  results: {
    shipmentId: string
    shipment?: GQL.Maybe<GQL.Shipment>
    errorType?: GQL.Maybe<GQL.ShipmentErrorType>
  }[]
}

export type MultipleShipmentActionResponse = {
  successful: GQL.Shipment[]
  errors: {
    [shipmentId: string]: GQL.ShipmentErrorType
  }
}

const parseMutationResult = (res: ShipmentMutationResult) =>
  res.results.reduce<MultipleShipmentActionResponse>(
    (acc, result) => {
      if (result.errorType) {
        acc.errors = {
          ...acc.errors,
          [result.shipmentId]: result.errorType,
        }
      }

      acc.successful = acc.successful.concat(result.shipment ?? [])
      return acc
    },
    {
      successful: [],
      errors: {},
    }
  )

const parseResult = <T>(res: FetchResult<T>, dataAccessor: (t: T) => ShipmentMutationResult) => {
  if (res.data) {
    const data = dataAccessor(res.data)
    return parseMutationResult(data)
  }

  if (res.errors) {
    throw new Error(`GQL error ${res.errors.map((e) => e.message).join(' ')}`)
  }

  throw new Error('Unknown error')
}

const api = {
  getAll: async () => {
    const res = await apolloClient.query({
      query: GetShipmentsQuery,
      variables: { filter: {} },
    })

    return (parseQueryResult(res, 'shipments') ?? []) as GQL.Shipment[]
  },
  get: async (id: GQL.GetShipmentQueryVariables['shipmentId']) => {
    const res = await apolloClient.query({
      query: GetShipmentQuery,
      variables: { shipmentId: id },
    })

    return parseQueryResult(res, 'shipment') as GQL.Shipment
  },
  assign: async (params: GQL.AssignShipmentInput) => {
    const res = await apolloClient.mutate({
      mutation: AssignShipmentMutation,
      variables: { data: { shipmentId: params.shipmentId, userId: params.userId } },
    })

    return parseResult(res, (d) => d.assignShipment as GQL.AssignShipmentResponse)
  },
  unassign: async (ids: GQL.UnassignShipmentInput['shipmentId']) => {
    const res = await apolloClient.mutate({
      mutation: UnassignShipmentMutation,
      variables: { data: { shipmentId: ids } },
    })

    return parseResult(res, (d) => d.unassignShipment as GQL.UnassignShipmentResponse)
  },
  cancel: async (params: GQL.CancelShipmentInput) => {
    const res = await apolloClient.mutate({
      mutation: CancelShipmentMutation,
      variables: {
        data: {
          shipmentId: params.shipmentId,
          ...(params.reasonCode ? { reasonCode: params.reasonCode } : {}),
          ...(params.reasonText ? { reasonText: params.reasonText } : {}),
        },
      },
    })

    return parseResult(res, (d) => d.cancelShipment as GQL.CancelShipmentResponse)
  },
  pickup: async (ids: GQL.PickupShipmentInput['shipmentId']) => {
    const res = await apolloClient.mutate({
      mutation: PickupShipmentMutation,
      variables: { data: { shipmentId: ids } },
    })

    return parseResult(res, (d) => d.pickupShipment as GQL.PickupShipmentResponse)
  },
}

export default api
