import { nanoid } from '@reduxjs/toolkit'
import { I18n } from 'react-redux-i18n'
import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects'
import {
  getShipments,
  assignShipment,
  unassignShipment,
  pickupShipment,
  setShipments,
  pollShipments,
  setPollData,
  applyPollData,
} from 'store/shipments/actions'
import { newRequest } from 'store/snackbars/actions'
import { MultipleShipmentActionResponse } from 'api/types'
import { Shipment } from 'types'
import { selectPollData, selectShipmentIds } from './selectors'
import { UserSettings } from 'store/user/reducer'
import api from 'api'
import { selectSettings } from 'store/user/selectors'

function* getHandler({ payload = {} }: ReturnType<typeof getShipments>) {
  try {
    if (!payload.silent) yield put(getShipments.pending())

    const data: Shipment[] = yield call(api.Shipment.getAll)
    yield put(getShipments.success(data))
    yield put(setPollData([]))
  } catch (error: any) {
    if (!payload.silent) yield put(getShipments.failure(error.message))
  }
}

function* pollHandler() {
  const shipmentIds: string[] = yield select(selectShipmentIds)
  const settings: UserSettings = yield select(selectSettings)

  try {
    const data: Shipment[] = yield call(api.Shipment.getAll)

    if (settings.autoUpdate) yield put(setShipments(data))
    else {
      let areEqual = data.length === shipmentIds.length
      if (!areEqual) {
        areEqual = data.reduce<boolean>((acc, next) => acc && shipmentIds.includes(next.id), true)
      }

      if (!areEqual) yield put(setPollData(data))
    }
  } catch (error: any) {}
}

function* applyPollHandler() {
  const polledShipments: Shipment[] = yield select(selectPollData)
  yield put(setPollData([]))
  yield put(setShipments(polledShipments))
}

function* assignHandler({ payload }: ReturnType<typeof assignShipment>) {
  let response: MultipleShipmentActionResponse
  const single = payload.shipmentId.length < 2
  const snackId = nanoid()

  const { user, shipmentId } = payload

  const messages = {
    pending: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Assign.Loading')
      : () => I18n.t('UI.Shipments.StatusMessages.AssignMultiple.Loading'),
    success: single
      ? () =>
          I18n.t('UI.Shipments.StatusMessages.Assign.Success', {
            user: user.fullName,
          })
      : (amount: number) =>
          I18n.t('UI.Shipments.StatusMessages.AssignMultiple.Success', {
            amount,
            user: user.fullName,
          }),
    partial: (successful: number, failed: number) =>
      I18n.t('UI.Shipments.StatusMessages.AssignMultiple.Partial', { successful, failed }),
    failure: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Assign.Failed')
      : () => I18n.t('UI.Shipments.StatusMessages.AssignMultiple.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending() }))
    response = yield call(api.Shipment.assign, { shipmentId, userId: user.id })

    const hasSuccessful = response.successful.length > 0
    const hasFailures = Object.keys(response.errors).length > 0

    if (hasSuccessful && !hasFailures) {
      yield put(
        newRequest.success({ id: snackId, message: messages.success(response.successful.length) })
      )
    }

    if (!hasSuccessful && hasFailures) {
      yield put(newRequest.failure({ id: snackId, message: messages.failure() }))
    }

    if (hasSuccessful && hasFailures) {
      yield put(
        newRequest.partialSuccess({
          id: snackId,
          message: messages.partial(
            response.successful.length,
            Object.keys(response.errors).length
          ),
        })
      )
    }

    yield put(setShipments(response.successful))
  } catch (error: any) {
    yield put(newRequest.failure({ id: snackId, message: error.message }))
  }
}

function* unassignHandler({ payload: p }: ReturnType<typeof unassignShipment>) {
  let response: MultipleShipmentActionResponse
  const payload = Array.isArray(p) ? p : [p]
  const shipments = payload.map(({ id }) => id)
  const single = shipments.length === 1
  const snackId = nanoid()

  const messages = {
    pending: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Unassign.Loading')
      : () => I18n.t('UI.Shipments.StatusMessages.UnassignMultiple.Loading'),
    success: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Unassign.Success')
      : (amount: number) =>
          I18n.t('UI.Shipments.StatusMessages.UnassignMultiple.Success', {
            amount,
          }),
    partial: (successful: number, failed: number) =>
      I18n.t('UI.Shipments.StatusMessages.UnassignMultiple.Partial', { successful, failed }),
    failure: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Unassign.Failed')
      : () => I18n.t('UI.Shipments.StatusMessages.UnassignMultiple.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending() }))
    response = yield call(api.Shipment.unassign, shipments)

    const hasSuccessful = response.successful.length > 0
    const hasFailures = Object.keys(response.errors).length > 0

    if (hasSuccessful && !hasFailures) {
      yield put(
        newRequest.success({ id: snackId, message: messages.success(response.successful.length) })
      )
    }

    if (!hasSuccessful && hasFailures) {
      yield put(newRequest.failure({ id: snackId, message: messages.failure() }))
    }

    if (hasSuccessful && hasFailures) {
      yield put(
        newRequest.partialSuccess({
          id: snackId,
          message: messages.partial(
            response.successful.length,
            Object.keys(response.errors).length
          ),
        })
      )
    }

    yield put(setShipments(response.successful))
  } catch (error: any) {
    yield put(newRequest.failure({ id: snackId, message: error.message }))
  }
}

function* pickupHandler({ payload }: ReturnType<typeof pickupShipment>) {
  let response: MultipleShipmentActionResponse
  const shipments = Array.isArray(payload.data) ? payload.data : [payload.data]
  const shipmentIds = shipments.map(({ id }) => id)
  const single = shipmentIds.length === 1
  const snackId = nanoid()

  const messages = {
    pending: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Pickup.Loading')
      : () => I18n.t('UI.Shipments.StatusMessages.PickupMultiple.Loading'),
    success: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Pickup.Success')
      : (amount: number) =>
          I18n.t('UI.Shipments.StatusMessages.PickupMultiple.Success', {
            amount,
          }),
    partial: (successful: number, failed: number) =>
      I18n.t('UI.Shipments.StatusMessages.PickupMultiple.Partial', { successful, failed }),
    failure: single
      ? () => I18n.t('UI.Shipments.StatusMessages.Pickup.Failed')
      : () => I18n.t('UI.Shipments.StatusMessages.PickupMultiple.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending() }))
    response = yield call(api.Shipment.pickup, shipmentIds)

    const hasSuccessful = response.successful.length > 0
    const hasFailures = Object.keys(response.errors).length > 0

    if (hasSuccessful && !hasFailures) {
      yield put(
        newRequest.success({ id: snackId, message: messages.success(response.successful.length) })
      )
      payload.callback?.(true)
    }

    if (!hasSuccessful && hasFailures) {
      yield put(newRequest.failure({ id: snackId, message: messages.failure() }))
      payload.callback?.(false)
    }

    if (hasSuccessful && hasFailures) {
      yield put(
        newRequest.partialSuccess({
          id: snackId,
          message: messages.partial(
            response.successful.length,
            Object.keys(response.errors).length
          ),
        })
      )
      payload.callback?.(true)
    }

    yield put(setShipments(response.successful))
  } catch (error: any) {
    put(newRequest.failure({ id: snackId, message: error.message }))
  }
}

export default [
  takeLatest(getShipments.type, getHandler),
  takeEvery(pollShipments.type, pollHandler),
  takeEvery(applyPollData.type, applyPollHandler),
  takeEvery(unassignShipment.type, unassignHandler),
  takeEvery(assignShipment.type, assignHandler),
  takeEvery(pickupShipment.type, pickupHandler),
]
