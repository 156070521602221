import React from 'react'
import { FormControlLabel, Switch, Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'

import { selectProfile } from 'store/user/selectors'
import FloatingField from 'components/ui/DetailPages/FloatingField'
import EntitlementChip from 'components/features/user/UserList/styled'
import ChangePassword from './ChangePassword'
import { useThemeSelector, useSettings } from 'helpers/hooks'
import Title from 'components/ui/Title'

const Settings = () => {
  const profile = useSelector(selectProfile)
  const [darkMode, toggleDarkMode] = useThemeSelector()
  const [settings, updateSettings] = useSettings()

  return (
    <div>
      <Title>
        <Translate value="UI.Settings.Title" />
      </Title>
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <FloatingField label={<Translate value="UI.Users.Details.Identity" />}>
            {profile.id}
          </FloatingField>
          <FloatingField label={<Translate value="UI.Settings.Username" />}>
            {profile.fullName}
          </FloatingField>
          <FloatingField label={<Translate value="UI.Settings.Email" />}>
            {profile.email}
          </FloatingField>
          <FloatingField label={<Translate value="UI.Users.Details.Entitlements" />}>
            <EntitlementChip
              key={profile.role}
              label={
                <Translate
                  value={`Entities.User.Roles.${
                    profile.role.charAt(0).toUpperCase() + profile.role.slice(1)
                  }`}
                />
              }
            />
          </FloatingField>
        </Grid>
        <FormControlLabel
          style={{ pointerEvents: 'none' }}
          control={
            <Switch checked={darkMode} onClick={toggleDarkMode} style={{ pointerEvents: 'auto' }} />
          }
          label={<Translate value="UI.Settings.DarkMode" />}
        />
        <FormControlLabel
          style={{ pointerEvents: 'none' }}
          disabled={true}
          control={
            <Switch
              checked={settings.autoUpdate}
              onClick={() => updateSettings({ autoUpdate: !settings.autoUpdate })}
              style={{ pointerEvents: 'auto' }}
            />
          }
          label={<Translate value="UI.Settings.AutoUpdate" />}
        />
        <ChangePassword userId={profile.id} />
      </Box>
    </div>
  )
}

export default Settings
