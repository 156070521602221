import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { APIStatus, TransportRequest } from 'types'
import { StoreNames } from 'types/Misc'
import { getTransportRequests, clearTransportRequests } from './actions'

type InitialState = EntityState<TransportRequest> & {
  status: APIStatus
  error: string | undefined
}

export const entityAdapter = createEntityAdapter<TransportRequest>({
  selectId: (transportRequest) => transportRequest.id,
})

const transportRequestSlice = createSlice({
  name: StoreNames.transportRequests,
  initialState: entityAdapter.getInitialState({
    status: APIStatus.Idle,
    error: undefined,
  }) as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransportRequests.pending, (state) => {
      state.status = APIStatus.Loading
    })
    builder.addCase(getTransportRequests.success, (state, { payload }) => {
      state.status = APIStatus.Succeeded
      entityAdapter.setAll(state, payload)
    })
    builder.addCase(getTransportRequests.failure, (state, { payload }) => {
      state.status = APIStatus.Failed
      state.error = payload
    })
    builder.addCase(clearTransportRequests, (state) => {
      state.status = APIStatus.Idle
      state.error = undefined
      entityAdapter.setAll(state, [])
    })
  },
})

export default transportRequestSlice
