import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { I18n, Translate } from 'react-redux-i18n'

import { Role as UserRole } from 'types/User'
import { CustomColumnOptions } from 'helpers/types'
import columnConstructors from 'components/features/data/columnConstructors'
import { SORT_ORDER } from 'components/features/data/DataGrid'
import TextLink from 'components/ui/TextLink'

import shortenUUID from 'helpers/shortenUUID'
import EntitlementChip from './styled'
import mergeColumnOptions from 'helpers/mergeColumnOptions'
import { compareStrings } from 'helpers/compare'

export const USER_ID_INDEX = 0

export enum UserColumns {
  Id = 'id',
  Email = 'email',
  FullName = 'fullName',
  Role = 'role',
  Actions = 'actions',
}

const getUserColumns: (
  actions: (tableMeta: MUIDataTableMeta) => ReactNode,
  columnOptions?: CustomColumnOptions
) => MUIDataTableColumn[] = (actions, columnOptions) => {
  const columns: MUIDataTableColumn[] = [
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Id'),
      name: UserColumns.Id,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Id" />,
        customBodyRender: (id) => (
          <TextLink color="textSecondary" as={Link} to={`/users/${id}`}>
            {shortenUUID(id)}
          </TextLink>
        ),
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Users.Table.Name'),
      name: UserColumns.FullName,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Users.Table.Name" />,
        customBodyRender: (name) => name ?? '-',
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Users.Table.Email'),
      name: UserColumns.Email,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Users.Table.Email" />,
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Users.Table.Entitlements'),
      name: UserColumns.Role,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Users.Table.Entitlements" />,
        customBodyRender: (role) => {
          if (role === undefined) return '-'
          return <EntitlementChip label={<Translate value={`Entities.User.Roles.${role}`} />} />
        },
        filterType: 'checkbox',
        customFilterListOptions: {
          render: (role) => <Translate value={`Entities.User.Roles.${role}`} />,
        },
        filterOptions: {
          renderValue: (role) => I18n.t(`Entities.User.Roles.${role}`),
          names: Object.values(UserRole),
        },
        sort: false,
      },
    },
    columnConstructors.actions(actions, UserColumns.Actions),
  ]

  mergeColumnOptions(columnOptions, columns)

  return columns
}

export default getUserColumns
