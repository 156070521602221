import * as GQL from 'gql/graphql'
import User from './User'

export type Shipment = GQL.Shipment
export type ShipmentEvent = GQL.ShipmentEvent
export type ShipmentAddress = GQL.ShipmentAddress
export type ShipmentService = GQL.ShipmentService
export type AssignShipmentInput = GQL.AssignShipmentInput
export type AssignShipmentResponse = GQL.AssignShipmentResponse
export type PackageItem = GQL.PackageItem

export interface DisplayableShipment extends GQL.Shipment {
  user: User | undefined
}

export enum ShipmentServiceCodes {
  SmartLock = 'PredefinedCarrier_LogtradeLock',
}

export enum ShipmentAction {
  Assign = 'assign',
  Unassign = 'unassign',
  Pickup = 'pickup',
  Cancel = 'cancel',
}

export { ShipmentStatus, ShipmentEventType } from 'gql/graphql'
