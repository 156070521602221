import { createAction } from '@reduxjs/toolkit'

import { UserInviteParams, UserRemoveParams } from 'api/types'
import { GetDataArgs } from 'store/root'
import { User, StoreNames } from 'types'
import { createAsyncAction, createActionWithCallback } from 'store/utils/actionCreators'

const name = StoreNames.users

export const setUser = createAction<User>(`${name}_SET_ONE`)
export const setUsers = createAction<User[]>(`${name}_SET_MANY`)
export const removeUser = createAction<string>(`${name}_REMOVE_ONE`)
export const removeUsers = createAction<string[]>(`${name}_REMOVE_MANY`)

export const getUsers = createAsyncAction<GetDataArgs, undefined, User[], string>(`${name}_GET`)
export const clearUsers = createAction(`${name}_CLEAR`)
export const inviteUser = createActionWithCallback<UserInviteParams>(`${name}_INVITE`)
export const uninviteUser = createAction<UserRemoveParams>(`${name}_REMOVE`)
