import { DateRangePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, TextField } from '@mui/material'
import columnConstructors from 'components/features/data/columnConstructors'
import { SORT_ORDER } from 'components/features/data/DataGrid'
import ShipmentStatusChip from 'components/features/shipments/ShipmentStatusChip'
import TextLink from 'components/ui/TextLink'
import { compareStrings } from 'helpers/compare'
import { dayMonth } from 'helpers/formatDate'
import mergeColumnOptions from 'helpers/mergeColumnOptions'
import { CustomColumnOptions } from 'helpers/types'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import React, { ReactNode } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { DisplayableShipment, ShipmentStatus } from 'types/Shipments'
import User from 'types/User'

export const UNASSIGNED = 'unassigned'

export const SHIPMENT_STATUS_INDEX = 3
export const SHIPMENT_ID_INDEX = 0

// Internal column names, cached config verification
export enum ShipmentColumns {
  Id = 'id',
  Date = 'shipmentDate',
  Shipper = 'sourceAddress.name',
  Recipient = 'destinationAddress.name',
  Number = 'shipmentNumber',
  FullName = 'user.fullName',
  Status = 'status',
  PickupAddress = 'sourceAddress.address1',
  PickupCity = 'sourceAddress.city',
  PickupZipCode = 'sourceAddress.zipCode',
  DeliveryAddress = 'destinationAddress.address1',
  DeliveryCity = 'destinationAddress.city',
  DeliveryZipCode = 'destinationAddress.zipCode',
  Actions = 'actions',
}

const addressColumns = [
  {
    label: 'Shipper',
    name: ShipmentColumns.Shipper,
  },
  {
    label: 'PickupAddress',
    name: ShipmentColumns.PickupAddress,
  },
  {
    label: 'PickupCity',
    name: ShipmentColumns.PickupCity,
  },
  {
    label: 'PickupZipCode',
    name: ShipmentColumns.PickupZipCode,
  },
  {
    label: 'Recipient',
    name: ShipmentColumns.Recipient,
  },
  {
    label: 'DeliveryAddress',
    name: ShipmentColumns.DeliveryAddress,
  },
  {
    label: 'DeliveryCity',
    name: ShipmentColumns.DeliveryCity,
  },
  {
    label: 'DeliveryZipCode',
    name: ShipmentColumns.DeliveryZipCode,
  },
]

export type GetShipmentColumnsProps = {
  data: DisplayableShipment[]
  users: User[]
  actions: (tableMeta: MUIDataTableMeta) => ReactNode
  columnOptions?: CustomColumnOptions
  locale?: string
}

const getShipmentColumns = ({
  data,
  users,
  actions,
  columnOptions,
  locale = 'sv-SE',
}: GetShipmentColumnsProps): MUIDataTableColumn[] => {
  const columns: MUIDataTableColumn[] = [
    columnConstructors.hiddenField(ShipmentColumns.Id),
    {
      label: I18n.t('UI.Shipments.Table.Columns.ShipmentId'),
      name: ShipmentColumns.Number,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.ShipmentId" />,
        customBodyRender: (number, meta) => (
          <TextLink
            color="textSecondary"
            as={Link}
            to={`/shipments/${meta.rowData[SHIPMENT_ID_INDEX]}`}
          >
            {number}
          </TextLink>
        ),
        sortCompare: (order) => (a, b) => {
          return (a.data - b.data) * (order === SORT_ORDER.DESCENDING ? 1 : -1)
        },
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.AssignedUser'),
      name: ShipmentColumns.FullName,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.AssignedUser" />,
        customBodyRenderLite: (dataIndex) => {
          const { user } = data[dataIndex]
          if (user === undefined) {
            return <Translate value="Entities.Shipment.Unassigned" />
          }
          return (
            <TextLink color="textSecondary" as={Link} to={`/users/${user.id}`}>
              {user.fullName}
            </TextLink>
          )
        },
        customFilterListOptions: {
          render: (name) =>
            name === UNASSIGNED ? <Translate value="Entities.Shipment.Unassigned" /> : name,
        },
        filterOptions: {
          renderValue: (name) =>
            name === UNASSIGNED ? I18n.t('Entities.Shipment.Unassigned') : name,
          names: users.map((user) => user.fullName).concat([UNASSIGNED]),
          logic: (value, filters) => {
            if (filters.length === 0) return false
            if (value === undefined && filters.includes(UNASSIGNED)) return false
            return !filters.includes(value)
          },
        },
        filterType: 'multiselect',
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Column.Status'),
      name: ShipmentColumns.Status,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Column.Status" />,
        customBodyRenderLite: (dataIndex) => {
          const { status } = data[dataIndex]

          return <ShipmentStatusChip status={status} />
        },
        customFilterListOptions: {
          render: (status) => <Translate value={`Entities.Shipment.Status.${status}`} />,
        },
        filterOptions: {
          renderValue: (status) => I18n.t(`Entities.Shipment.Status.${status}`),
          names: Object.values(ShipmentStatus),
        },
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.ShipmentDate'),
      name: ShipmentColumns.Date,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.ShipmentDate" />,
        customBodyRenderLite: (dataIndex) => dayMonth(data[dataIndex].shipmentDate),
        filterOptions: {
          logic: (prop, filterValue) => {
            if (!filterValue.length) return false

            const { from, to } = JSON.parse(filterValue[0])
            if (!from || !to) return false

            const startDate = new Date(from)
            const endDate = new AdapterDateFns().addDays(new Date(to), 1)
            const shipmentDate = prop || null
            if (shipmentDate) {
              const thisDate = new Date(shipmentDate)
              const isAfterStart = startDate.getTime() <= thisDate.getTime()
              const isBeforeEnd = thisDate.getTime() < endDate.getTime()
              return !isAfterStart || !isBeforeEnd
            } else return false
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index].length
              ? filterList[index]
                  .map((s) => JSON.parse(s))
                  .map(({ from, to }) => [from, to].map((d) => (!!d ? new Date(d) : null)))[0]
              : [null, null]

            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Earliest delivery date"
                  endText="Latest delivery date"
                  inputFormat={locale === 'sv' ? 'dd MMM. yyyy' : 'MMM. dd, yyyy'}
                  mask=""
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                  onChange={([from, to]) => {
                    filterList[index] = [
                      JSON.stringify({
                        from: from?.toDateString(),
                        to: to?.toDateString(),
                      }),
                    ]
                    onChange(filterList[index], index, column)
                  }}
                  value={[value[0], value[1]]}
                  clearable={true}
                />
              </LocalizationProvider>
            )
          },
        },
        customFilterListOptions: {
          render: (value) => {
            const { from, to } = JSON.parse(value)
            const fromStr = new Date(from).toLocaleDateString(locale, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })
            const toStr = new Date(to).toLocaleDateString(locale, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })
            return `${fromStr} - ${toStr}`
          },
        },
        filterType: 'custom',
      },
    },
    ...addressColumns.map((col) => {
      const column: MUIDataTableColumn = {
        label: I18n.t(`UI.Shipments.Table.Columns.${col.label}`),
        name: col.name,
        options: {
          customHeadLabelRender: () => (
            <Translate value={`UI.Shipments.Table.Columns.${col.label}`} /> // ADD KEYS TO PHRASE
          ),
          filterType: 'multiselect',
        },
      }

      return column
    }),
    columnConstructors.actions(actions, ShipmentColumns.Actions),
  ]

  mergeColumnOptions(columnOptions, columns)

  return columns
}

export default getShipmentColumns
