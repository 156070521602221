import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import { TransportRequestStatus } from 'types'

type StatusChipProps = {
  status: TransportRequestStatus
}

export const StatusChip = styled(Chip)<StatusChipProps>`
  color: ${(p) => p.theme.transportRequest.status[p.status]};
  border-color: ${(p) => p.theme.transportRequest.status[p.status]};
  font-weight: 600;
`

export default StatusChip
