import { Shipment, ShipmentStatus, ShipmentAction } from 'types/Shipments'

// ----- Assign -----

export const isAssignAvailable = (status: ShipmentStatus) =>
  status === ShipmentStatus.Available || status === ShipmentStatus.Reserved

export const isAssignable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.every((s) => isAssignAvailable(s.status))
  }

  return isAssignAvailable(shipment.status)
}

export const isAnyAssignable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.some((s) => isAssignAvailable(s.status))
  }

  return isAssignAvailable(shipment.status)
}

// ----- Unassign -----

export const isUnassignAvailable = (status: ShipmentStatus) => status === ShipmentStatus.Reserved

export const isUnassignable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.every((s) => isUnassignAvailable(s.status))
  }

  return isUnassignAvailable(shipment.status)
}

export const isAnyUnassignable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.some((s) => isUnassignAvailable(s.status))
  }

  return isUnassignAvailable(shipment.status)
}

// ----- Pickup -----

export const isPickupAvailable = (shipment: Shipment) =>
  shipment.status === ShipmentStatus.Reserved && !!shipment.assignedUserId

export const isPickable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.every((s) => isPickupAvailable(s))
  }

  return isPickupAvailable(shipment)
}

export const isAnyPickable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.some((s) => isPickupAvailable(s))
  }

  return isPickupAvailable(shipment)
}

// ----- Cancel -----

export const isCancelAvailable = (status: ShipmentStatus) =>
  status === ShipmentStatus.Available ||
  status === ShipmentStatus.Reserved ||
  status === ShipmentStatus.Transit ||
  status === ShipmentStatus.OnHold

export const isCancellable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.every((s) => isCancelAvailable(s.status))
  }

  return isCancelAvailable(shipment.status)
}

export const isAnyCancellable = <T extends Shipment>(shipment: T | T[]): boolean => {
  if (Array.isArray(shipment)) {
    return shipment.some((s) => isCancelAvailable(s.status))
  }

  return isCancelAvailable(shipment.status)
}

// ----- Generic -----

export const isActionAvailable = <T extends Shipment>(
  shipment: T | T[],
  action: ShipmentAction
): boolean => {
  switch (action) {
    case ShipmentAction.Assign:
      return isAssignable(shipment)
    case ShipmentAction.Unassign:
      return isUnassignable(shipment)
    case ShipmentAction.Pickup:
      return isPickable(shipment)
    case ShipmentAction.Cancel:
      return isCancellable(shipment)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}

// ----- Generic -----

export const isActionAvailableOnAny = <T extends Shipment>(
  shipment: T | T[],
  action: ShipmentAction
): boolean => {
  switch (action) {
    case ShipmentAction.Assign:
      return isAnyAssignable(shipment)
    case ShipmentAction.Unassign:
      return isAnyUnassignable(shipment)
    case ShipmentAction.Pickup:
      return isAnyPickable(shipment)
    case ShipmentAction.Cancel:
      return isAnyCancellable(shipment)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
