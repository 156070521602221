import React from 'react'
import { Translate } from 'react-redux-i18n'
import { IolConnectionStatus } from 'types'
import { StatusChip } from './styled'

export type ConnectionStatusChipProps = {
  status: IolConnectionStatus
}

const ConnectionStatusChip = ({ status }: ConnectionStatusChipProps) => (
  <StatusChip
    variant="outlined"
    size="small"
    label={<Translate value={`Entities.Connection.Status.${status}`} />}
    status={status}
  />
)

export default ConnectionStatusChip
