import styled from 'styled-components'
import { Toolbar as MuiToolbar, Typography, IconButton } from '@mui/material'

export const Toolbar = styled(MuiToolbar)`
  justify-content: space-between;
`

export const LogoutText = styled(Typography)`
  color: ${(p) => p.theme.palette.error.light};
`
export const UserButton = styled(IconButton)`
  border-radius: 10px;
  font-size: 1.5em;
`
