import moment from 'moment'

import 'moment/locale/sv'
import 'moment/locale/pt'

export const setMomentLocale = (locale: string) => moment.locale(locale)

export const dayMonth = (date?: string) => (date ? moment(date).format('ll') : '-')

export const dateAndTime = (date?: string) =>
  date ? `${moment(date).format('L')} ${moment(date).format('LT')}` : '-'

export const dateOnly = (date?: string) => (date ? moment(date).format('L') : '-')

export const weekday = (date?: string) => {
  if (date) {
    const wd = new Date(date).toLocaleTimeString(moment.locale(), { weekday: 'long' }).split(' ')[0]
    return wd.charAt(0).toUpperCase() + wd.slice(1)
  }

  return '-'
}

export const weekdayNumber = (date: string) => moment(date).weekday()

export const weekdayString = (wd: number) => weekday(moment().day(wd).toString())
