import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as LogoSquare } from './logo_square.svg'

const StyledLogo = styled(Logo)`
  path,
  polygon {
    fill: ${(p) => p.theme.palette.text.primary};
  }
  height: 3.5rem;
`
const StyledLogoSquare = styled(LogoSquare)`
  path,
  polygon {
    fill: ${(p) => p.theme.palette.common.white};
  }
  height: 6rem;
`

type LogoProps = {
  square?: boolean
}

const LogtradeLogo = ({ square }: LogoProps) => {
  return square ? <StyledLogoSquare /> : <StyledLogo />
}

export default LogtradeLogo
