import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MUIDataTableOptions } from 'mui-datatables'
import { Box, TableCell, TableRow } from '@mui/material'

import { ShipmentEvent } from 'types'
import { selectUserEntities } from 'store/users/selectors'
import getLocale from 'store/i18n/selectors'
import flattenObject from 'helpers/flattenObject'
import DataGrid, { SORT_ORDER } from 'components/features/data/DataGrid'

import getColumns, { ShipmentEventColumns } from './columns'
import { Translate } from 'react-redux-i18n'

type ShipmentEventListProps = {
  data: ShipmentEvent[]
}

const ShipmentEventList = ({ data }: ShipmentEventListProps) => {
  const name = 'TABLE_CONFIG_SHIPMENT_EVENT_LIST'
  const users = useSelector(selectUserEntities)

  const locale = useSelector(getLocale)
  const flatData = useMemo(() => data.map(flattenObject), [data])
  const columns = useMemo(() => getColumns(data, users, locale), [data, users, locale])
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      expandableRows: true,
      expandableRowsOnClick: true,
      expandableRowsHeader: false,
      renderExpandableRow: (rowData, rowMeta) => {
        const colSpan = rowData.length + 1
        const event = data[rowMeta.dataIndex]

        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Box sx={{ paddingX: 8 }}>
                <Translate value="UI.Shipments.Events.Table.Columns.ReasonCode" />
                {': '}
                {event?.reasonCode ?? '-'}{' '}
                <Translate value="UI.Shipments.Events.Table.Columns.ReasonText" />
                {': '}
                {event?.reasonText ?? '-'}
              </Box>
            </TableCell>
          </TableRow>
        )
      },
    }),
    [data]
  )

  const defaults = {
    sort: {
      name: ShipmentEventColumns.CreatedAt,
      direction: SORT_ORDER.DESCENDING,
    },
  }

  return (
    <DataGrid
      name={name}
      data={flatData}
      columns={columns ?? []}
      loading={false}
      defaults={defaults}
      options={options}
    />
  )
}

export default ShipmentEventList
