import React from 'react'
import { Translate } from 'react-redux-i18n'
import { ShipmentStatus } from 'types/Shipments'
import { StatusChip } from './styled'

export type ShipmentStatusChipProps = {
  status: ShipmentStatus
}

const ShipmentStatusChip = ({ status }: ShipmentStatusChipProps) => {
  return (
    <StatusChip
      variant="outlined"
      size="small"
      label={<Translate value={`Entities.Shipment.Status.${status}`} />}
      status={status}
    />
  )
}

export default ShipmentStatusChip
