import React, { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'

import { Itinerary, ItineraryAction } from 'types'
import { useItineraryActions, ActionType as ItineraryActionType } from 'services/ItineraryActions'
import Menu from '../../data/DataGrid/ActionMenu'
import { ListItemIcon } from './styled'

export type ActionType = ItineraryActionType

export type ActionMenuProps = {
  itinerary: Itinerary | Itinerary[]
  handleItineraryAction: (action: ItineraryAction, itinerary: Itinerary | Itinerary[]) => void
}

const ActionMenu = ({ itinerary, handleItineraryAction }: ActionMenuProps) => {
  const actions = useItineraryActions(itinerary)
  const [availableActions, setAvailableActions] = useState<ItineraryAction[]>([])

  useEffect(() => {
    const available = actions.getAvailableActions()

    setAvailableActions(available)
  }, [actions])

  return (
    <Menu disabled={Boolean(!availableActions.length)}>
      {({ onClick }) =>
        availableActions.map((action) => (
          <MenuItem
            key={action}
            onClick={() => {
              onClick()
              handleItineraryAction(action, itinerary)
            }}
          >
            <ListItemIcon type={actions[action].getType()}>
              {actions[action].getIcon()}
            </ListItemIcon>
            {actions[action].getLabel()}
          </MenuItem>
        ))
      }
    </Menu>
  )
}

export default ActionMenu
