import { RootState } from 'store'
import { entityAdapter } from './reducer'

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers,
} = entityAdapter.getSelectors((state: RootState) => state.users)
