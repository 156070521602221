import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { configureTranslations } from './i18n/translations'
import rootReducer from './root'
import rootSaga from './sagas'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose

const saga = createSagaMiddleware()

const middlewares = [thunk, saga]

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

configureTranslations(store)

saga.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>

export default store
