import React, { useState } from 'react'
import { Box } from '@mui/material'
import { AddCircleOutline, CancelOutlined } from '@mui/icons-material'
import { Translate } from 'react-redux-i18n'

import { DisplayableShipment } from 'types'
import Title from 'components/ui/Title'
import ShipmentList from './ShipmentList'
import { Button, Modal } from './styled'

const SHIPMENT_TABLE_CONFIG_NAME = 'ITINERARY_AVAILABLE_SHIPMENTS'

export type AddShipmentsModalProps = {
  open: boolean
  onClose: () => void
  data: DisplayableShipment[]
  onSelect: (shipment: DisplayableShipment[]) => void
}

const AddShipmentsModal = ({
  open,
  onClose,
  data: shipments,
  onSelect,
}: AddShipmentsModalProps) => {
  const [selected, setSelected] = useState<DisplayableShipment[]>([])

  const handleSelect = (s: DisplayableShipment[]) => {
    setSelected(s)
  }

  const handleClose = (withCallback: boolean) => {
    if (withCallback) onSelect(selected)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="100vh"
        width="100vw"
        paddingX={4}
      >
        <Title>
          <Translate value="UI.Itineraries.AddShipments.SelectShipments" />
        </Title>
        <Box mb={4} width="100%">
          <ShipmentList
            data={shipments}
            name={SHIPMENT_TABLE_CONFIG_NAME}
            onSelect={handleSelect}
          />
        </Box>
        <Box display="flex" flexShrink={0}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => handleClose(true)}
            disabled={selected.length < 1}
          >
            {selected.length < 2 ? (
              <Translate value="UI.Itineraries.AddShipments.Add.Single" />
            ) : (
              <Translate
                value="UI.Itineraries.AddShipments.Add.Multiple"
                amount={selected.length}
              />
            )}
          </Button>
          <Button
            color="error"
            variant="contained"
            startIcon={<CancelOutlined />}
            onClick={() => handleClose(false)}
          >
            <Translate value="General.Close" />
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddShipmentsModal
