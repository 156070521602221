import { Paper as MuiPaper } from '@mui/material'
import styled from 'styled-components'

type DraggablePaperProps = {
  $isDragging?: boolean
  $isDragHandleHovered?: boolean
}

const Paper = styled(MuiPaper)<DraggablePaperProps>`
  width: 100%;
  display: flex;
  padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};

  ${(p) => p.$isDragging && `border: 2px solid ${p.theme.palette.primary.main}`}
`

export default Paper
