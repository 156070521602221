import styled from 'styled-components'
import { Button as MuiButton, Modal as MuiModal } from '@mui/material'

export const Button = styled(MuiButton)`
  margin-right: 1rem;
`

export const Modal = styled(MuiModal)`
  background: ${(p) => p.theme.palette.background.default};
`
