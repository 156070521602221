import { Chip, FormControl, TextField } from '@mui/material'
import styled from 'styled-components'

const defaultInputProps: any = {
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
}

export const InputField = styled(TextField).attrs(defaultInputProps)``

export const SelectFormControl = styled(FormControl).attrs(defaultInputProps)``

export const SelectChip = styled(Chip)`
  margin: -${(p) => p.theme.spacing(2)} 0;
  margin-right: ${(p) => p.theme.spacing(0.5)};
`
