import { graphql } from 'gql/gql'

/**
 * Change user password
 */

export const ChangeUserPasswordMutation = graphql(`
  mutation UpdateUserPassword($data: UpdateUserPasswordInput!) {
    updateUserPassword(data: $data) {
      ...UserFragment
    }
  }
`)
