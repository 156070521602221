import { graphql } from 'gql/gql'

export const OrganizationFragment = graphql(`
  fragment OrganizationFragment on Organization {
    id
    name
    users {
      firstName
      lastName
      fullName @client
      role
      id
      email
    }
  }
`)

/**
 * Get organization
 */

export const GetOrganizationQuery = graphql(`
  query GetOrganization($orgId: String) {
    organization(orgId: $orgId) {
      ...OrganizationFragment
    }
  }
`)
