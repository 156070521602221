import React from 'react'
import { Box } from '@mui/material'
import { DeliverIcon, PickupIcon } from 'components/ui/icons'
import { ItineraryStopAction, ItineraryStopActionType } from 'types'
import StopActionData from './StopActionData'

type StopActionsProps = {
  actionsPerType: { [key in ItineraryStopActionType]: ItineraryStopAction[] } | undefined
}

const StopActions = ({ actionsPerType }: StopActionsProps) => {
  return (
    <Box>
      <Box display="flex" flexDirection="column" height="100%" width="12em" justifyContent="center">
        <StopActionData
          action={ItineraryStopActionType.PickUp}
          amount={actionsPerType?.PickUp?.length || 0}
          icon={<PickupIcon />}
        />
        <StopActionData
          action={ItineraryStopActionType.Deliver}
          amount={actionsPerType?.Deliver?.length || 0}
          icon={<DeliverIcon />}
        />
      </Box>
    </Box>
  )
}

export default StopActions
