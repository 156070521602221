import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { uninviteUser } from 'store/users/actions'
import { selectUserById } from 'store/users/selectors'
import { RootState } from 'store'
import User from 'types/User'

const RemoveDialog = ({
  userId,
  isOpen,
  onClose,
}: {
  userId: User['id']
  isOpen: boolean
  onClose: () => void
}) => {
  const dispatch = useDispatch()
  const user: User | undefined = useSelector((state: RootState) => selectUserById(state, userId))

  const onRemove = () => {
    if (user) {
      dispatch(uninviteUser({ userId: user.id }))
    }
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="remove-dialog-title"
      aria-describedby="remove-dialog-description"
    >
      {user && (
        <>
          <DialogTitle id="remove-dialog-title">
            <Translate value="UI.Users.Remove.Title" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="remove-dialog-description">
              <Translate
                value="UI.Users.Remove.Content"
                identity={`${user.fullName} (${user.id})`}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onRemove} color="error" autoFocus>
              <Translate value="General.Remove" />
            </Button>
            <Button onClick={onClose} color="primary">
              <Translate value="General.Cancel" />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default RemoveDialog
