import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import { IolConnectionStatus } from 'types'

type StatusChipProps = {
  status: IolConnectionStatus
}

export const StatusChip = styled(Chip)<StatusChipProps>`
  color: ${(p) => p.theme.connection.status[p.status]};
  border-color: ${(p) => p.theme.connection.status[p.status]};
  font-weight: 600;
`

export default StatusChip
