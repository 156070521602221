import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { ContentCopy, Link } from '@mui/icons-material'

import { APIStatus } from 'types'
import { newSnackbar } from 'store/snackbars/actions'

type TitleProps = {
  children: ReactNode
  id?: string
}

const Title = ({ id, children }: TitleProps) => {
  const dispatch = useDispatch()

  const handleIdCopy = async () => {
    try {
      if (!id) throw new Error('No ID found')

      await navigator.clipboard.writeText(id ?? '')

      dispatch(
        newSnackbar({
          message: <Translate value="General.Copy.Id.Success" />,
          status: APIStatus.Succeeded,
        })
      )
    } catch (e: any) {
      dispatch(
        newSnackbar({
          message: <Translate value="General.Copy.Id.Failed" />,
          status: APIStatus.Failed,
        })
      )
    }
  }

  const handleLinkCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href)

      dispatch(
        newSnackbar({
          message: <Translate value="General.Copy.Link.Success" />,
          status: APIStatus.Succeeded,
        })
      )
    } catch (e: any) {
      dispatch(
        newSnackbar({
          message: <Translate value="General.Copy.Link.Failed" />,
          status: APIStatus.Failed,
        })
      )
    }
  }

  return (
    <Box mb={6} display="flex">
      <Typography variant="h1" sx={{ fontSize: '2rem', mr: 2, mt: 0.7 }}>
        {children}
      </Typography>
      {id && (
        <>
          <Tooltip title={<Translate value="General.Copy.Id.Label" />}>
            <Button sx={{ mr: 1, p: 1, minWidth: '2rem' }} onClick={() => handleIdCopy()}>
              <ContentCopy />
            </Button>
          </Tooltip>
          <Tooltip title={<Translate value="General.Copy.Link.Label" />}>
            <Button sx={{ mr: 1, p: 1, minWidth: '2rem' }} onClick={() => handleLinkCopy()}>
              <Link />
            </Button>
          </Tooltip>
        </>
      )}
    </Box>
  )
}

export default Title
