import { MUIDataTableColumn } from 'mui-datatables'
import React, { createContext, useMemo, useContext } from 'react'
import ConfigPreserver, { TableConfig } from './index'

const PreserverContext = createContext<ConfigPreserver>({} as ConfigPreserver)

export const usePreserver = () => useContext(PreserverContext)

export type PreserverProviderProps = {
  name: string
  columns: MUIDataTableColumn[]
  defaults?: TableConfig
  children?: React.ReactNode
}

export const PreserverProvider = ({
  name,
  columns,
  defaults,
  children,
}: PreserverProviderProps) => {
  const preserver = useMemo(
    () =>
      new ConfigPreserver(
        name,
        columns.map((col) => col.name),
        {
          showColumns: columns
            .filter((col) => col.options?.display !== false)
            .map((col) => col.name),
          ...defaults,
        }
      ),
    [name, columns, defaults]
  )

  return <PreserverContext.Provider value={preserver}>{children}</PreserverContext.Provider>
}

export const PreserverConsumer = PreserverContext.Consumer
