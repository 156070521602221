import React from 'react'
import { Translate } from 'react-redux-i18n'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { Perhaps, User } from 'types'

export type SelectUserProps = {
  users: User[]
  user: Perhaps<User>
  onChange: (selectedUser?: User) => void
}

const SelectUser = ({ user, users, onChange }: SelectUserProps) => {
  const handleChange = (userId: string) => {
    const selectedUser = users.find((u) => u.id === userId)
    onChange(selectedUser)
  }

  return (
    <FormControl sx={{ minWidth: '12rem' }}>
      <InputLabel>
        <Translate value="UI.Itineraries.Properties.Courier" />
      </InputLabel>
      <Select
        value={user?.id ?? '_unassigned_'}
        label={<Translate value={'UI.Itineraries.Properties.Courier'} />}
        onChange={(e) => handleChange(e.target.value)}
      >
        <MenuItem value="_unassigned_">
          <Translate value="Entities.Itinerary.Unassigned" style={{ fontStyle: 'italic' }} />
        </MenuItem>
        {users.map((u) => (
          <MenuItem value={u.id} key={u.id}>
            {u.fullName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectUser
