import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'

import { AddCircleOutline, LinkOutlined } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'
import ConnectionsList from 'components/features/connections/ConnectionsList'
import Title from 'components/ui/Title'
import { connectToIol, createConnection } from 'store/connections/actions'
import { selectAllConnections, selectConnectionsStatus } from 'store/connections/selectors'
import styled from 'styled-components'
import { APIStatus } from 'types'

export const CONNECTION_LIST = 'CONNECTION_LIST'

type ModalResult = {
  connectionName: string
  partnerEmailAddress: string
}

type CreateConnectionModalProps = {
  open: boolean
  onClose: (data: ModalResult | null) => void
}

const defaultInputProps: any = {
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
}

export const InputField = styled(TextField).attrs(defaultInputProps)``

const emailRegEx = /^[^@\s]+@[^@\s]+\.[^@\s]+$/

const CreateConnectionModal = ({ open, onClose }: CreateConnectionModalProps) => {
  const [partnerName, setPartnerName] = useState<string>('')
  const [partnerEmail, setPartnerEmail] = useState<string>('')

  const handleClose = (didConfirm: boolean) => {
    onClose(
      didConfirm && partnerName && partnerEmail
        ? {
            connectionName: partnerName,
            partnerEmailAddress: partnerEmail,
          }
        : null
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="create-conenction-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="create-conenction-dialog-title">
        <Translate value="UI.Connections.Create.Title" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item md={6} sm={12} xs={12}>
            <InputField
              label={I18n.t('UI.Connections.Create.PartnerName')}
              value={partnerName}
              onChange={(e: any) => setPartnerName(e.target.value)}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <InputField
              label={I18n.t('UI.Connections.Create.PartnerEmail')}
              value={partnerEmail}
              error={partnerEmail && !emailRegEx.test(partnerEmail)}
              onChange={(e: any) => setPartnerEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            disabled={!partnerName || !partnerEmail || !emailRegEx.test(partnerEmail)}
            onClick={() => handleClose(true)}
            color="primary"
          >
            <Translate value="UI.Connections.Create.SendEmail" />
          </Button>
          <Button onClick={() => handleClose(false)} color="error">
            <Translate value="General.Close" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const ConnectionsPage = () => {
  const dispatch = useDispatch()
  const connections = useSelector(selectAllConnections)
  const connectionsStatus = useSelector(selectConnectionsStatus)

  const [isCreateOpen, setCreateOpen] = useState(false)

  const handleCreateModalClosed = (result: ModalResult | null) => {
    setCreateOpen(false)

    if (result) {
      dispatch(
        createConnection({
          ...result,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          iolBaseUrl: process.env.REACT_APP_IOL_API_URL!,
        })
      )
    }
  }

  const isConnectedToIol =
    connectionsStatus === APIStatus.Succeeded &&
    !!connections.find((c) => c.connectionToSelf && c.status === 'Authorized')

  return (
    <>
      <Title>
        <Translate value="UI.Connections.Title" />
      </Title>
      <Grid container columns={2} gap={2} mb={4} mt={-4}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setCreateOpen(true)}
          startIcon={<AddCircleOutline />}
        >
          <Translate value="UI.Connections.Create.Title" />
        </Button>
        {!isConnectedToIol && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              dispatch(connectToIol({ data: undefined, callback: (url) => window.open(url) }))
            }
            startIcon={<LinkOutlined />}
          >
            <Translate value="UI.Connections.ConnectToIol" />
          </Button>
        )}
      </Grid>
      <ConnectionsList name={CONNECTION_LIST} data={connections} />
      {isCreateOpen && (
        <CreateConnectionModal open={isCreateOpen} onClose={handleCreateModalClosed} />
      )}
    </>
  )
}

export default ConnectionsPage
