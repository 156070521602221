import React from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { Shipment, DisplayableShipment } from 'types/Shipments'
import User from 'types/User'
import { assignShipment } from 'store/shipments/actions'
import UserAvatar from 'components/features/user/UserAvatar'

export type AssignUserDialogProps = {
  users: User[]
  onClose: (() => void) | ((wasActionPerformed: boolean) => void)
  shipments: Array<Shipment | DisplayableShipment>
}

const AssignUserDialog = ({ users, onClose, shipments }: AssignUserDialogProps) => {
  const dispatch = useDispatch()

  const handleAssignUser = (user: User) => {
    onClose(true)

    const ids = shipments.map((s) => s.id)

    dispatch(
      assignShipment({
        user: user,
        shipmentId: ids,
      })
    )
  }

  return (
    <Dialog open onClose={() => onClose(false)} aria-labelledby="assign-user-dialog-title">
      <DialogTitle id="assign-user-dialog-title">
        {(Array.isArray(shipments) && shipments.length > 1 && (
          <Translate value="UI.Shipments.Table.AssignShipments" />
        )) || <Translate value="UI.Shipments.Table.AssignShipment" />}
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {users.map((user) => (
            <ListItem button onClick={() => handleAssignUser(user)} key={user.id}>
              <ListItemAvatar>
                <UserAvatar user={user} />
              </ListItemAvatar>
              <ListItemText>
                {user.fullName} ({user.id})
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default AssignUserDialog
