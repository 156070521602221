import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'

import ItineraryList from 'components/features/itineraries/ItineraryList'
import { selectAllItineraries } from 'store/itineraries/selectors'
import Title from 'components/ui/Title'

export const ITINERARY_LIST = 'ITINERARY_LIST'

const Itineraries = () => {
  const navigate = useNavigate()
  const itineraries = useSelector(selectAllItineraries)

  return (
    <>
      <Title>
        <Translate value="UI.Itineraries.Title" />
      </Title>
      <Box mb={4} mt={-4}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate('/itineraries/create')}
          startIcon={<AddCircleOutline />}
        >
          <Translate value="UI.Itineraries.Create.Create" />
        </Button>
      </Box>
      <ItineraryList name={ITINERARY_LIST} data={itineraries} />
    </>
  )
}

export default Itineraries
