import { useState, useEffect } from 'react'
import { TransportRequest } from 'types'
import { getTransportRequestActions, TransportRequestActionsOptions } from './index'

export const useTransportRequestActions = <T extends TransportRequest>(request: T | T[]) => {
  const [actions, setActions] = useState<TransportRequestActionsOptions>(
    getTransportRequestActions(request)
  )

  useEffect(() => {
    setActions(getTransportRequestActions(request))
  }, [request])

  return actions
}

export default useTransportRequestActions
