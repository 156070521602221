import React from 'react'
import { SvgIconProps } from '@mui/material'
import { Itinerary, ItineraryAction } from 'types'

import { getActionIcon } from './icons'
import { getLabel } from './labels'
import { getActionType, ActionType } from './actionTypes'
import { isActionAvailable } from './predicates'

export { useItineraryActions } from './hooks'
export type { ActionType } from './actionTypes'

type LabelParams = {
  plural?: boolean
  options?: any
  nullIfUnavailable?: boolean
}

export type ItineraryActionOptions = {
  getIcon: (props?: SvgIconProps) => React.ReactNode
  getLabel: (params?: LabelParams) => string | null
  getType: () => ActionType
  isAvailable(): boolean
}

export type ItineraryActionsOptions = {
  [key in ItineraryAction]: ItineraryActionOptions
} & {
  getAvailableActions: () => ItineraryAction[]
}

export const availableActions = <T extends Itinerary>(itinerary: T | T[]) =>
  Object.values(ItineraryAction).reduce((acc, action) => {
    if (isActionAvailable(itinerary, action as ItineraryAction)) {
      return acc.concat(action as ItineraryAction)
    }
    return acc
  }, [] as ItineraryAction[])

export const getItineraryAction = <T extends Itinerary>(
  itinerary: T | T[],
  action: ItineraryAction
): ItineraryActionOptions => {
  return {
    getIcon: (props?: SvgIconProps) => getActionIcon(action, props),
    getLabel: (params) => {
      if (params?.nullIfUnavailable && !isActionAvailable(itinerary, action)) return null
      return getLabel(action, params?.plural || Array.isArray(itinerary), params?.options)
    },
    getType: () => getActionType(action),
    isAvailable: () => isActionAvailable(itinerary, action),
  }
}

export const getItineraryActions = <T extends Itinerary>(
  itinerary: T | T[]
): ItineraryActionsOptions => {
  return {
    [ItineraryAction.Cancel]: getItineraryAction(itinerary, ItineraryAction.Cancel),
    getAvailableActions: () => availableActions(itinerary),
  }
}
