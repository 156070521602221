import { RootState } from 'store'
import { StoreNames } from 'types/Misc'

const getState = (state: RootState) => state[StoreNames.user]

export const selectProfile = (state: RootState) => getState(state).profile

export const selectSettings = (state: RootState) => getState(state).settings

export const isDarkMode = (state: RootState) => getState(state).settings.darkMode
