import styled from 'styled-components'
import { Paper } from '@mui/material'

export const StyledBox = styled(Paper)<{ clickable: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => p.theme.spacing(2)};
  transition: background 0.2s;

  &:hover {
    ${(p) =>
      p.clickable === 'true' &&
      `
        background: ${p.theme.palette.action.hover};
        cursor: pointer;
      `}
  }

  > span {
    font-size: 1.5rem;
  }
`
export const Header = styled.div`
  color: ${(p) => p.theme.palette.text.secondary};
  margin-bottom: 0.5rem;
  font-size: 0.9em;
`

export const ChipWrapper = styled.div`
  > div {
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
    font-size: 0.7rem;
  }
`
