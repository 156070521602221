import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import APIStatus from 'types/Status'
import User from 'types/User'
import { StoreNames } from 'types/Misc'
import { getUsers, clearUsers, setUser, setUsers, removeUser, removeUsers } from './actions'

type InitialState = EntityState<User> & {
  status: APIStatus
  error: string | undefined
}

export const entityAdapter = createEntityAdapter<User>({
  selectId: (user) => user.id,
})

const usersSlice = createSlice({
  name: StoreNames.users,
  initialState: entityAdapter.getInitialState({
    status: APIStatus.Idle,
    error: undefined,
  }) as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.status = APIStatus.Loading
    })
    builder.addCase(getUsers.success, (state, { payload }) => {
      state.status = APIStatus.Succeeded
      entityAdapter.setAll(state, payload)
    })
    builder.addCase(getUsers.failure, (state, { payload }) => {
      state.status = APIStatus.Failed
      state.error = payload
    })
    builder.addCase(setUser, (state, { payload }) => {
      entityAdapter.setOne(state, payload)
    })
    builder.addCase(setUsers, (state, { payload }) => {
      entityAdapter.setMany(state, payload)
    })
    builder.addCase(removeUser, (state, { payload }) => {
      entityAdapter.removeOne(state, payload)
    })
    builder.addCase(removeUsers, (state, { payload }) => {
      entityAdapter.removeMany(state, payload)
    })
    builder.addCase(clearUsers, (state) => {
      state.status = APIStatus.Idle
      state.error = undefined
      entityAdapter.setAll(state, [])
    })
  },
})

export default usersSlice
