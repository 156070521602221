import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { LogtradeProvider } from '@logtrade-technology-ab/logtrade-lib'
import App from './components/App'

import store from './store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LogtradeProvider>
        <App />
      </LogtradeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
