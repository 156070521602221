import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { RootState } from 'store'

import { selectUserById } from 'store/users/selectors'
import ShipmentStatusChip from 'components/features/shipments/ShipmentStatusChip'
import { Shipment } from 'types'
import Field from 'components/ui/DetailPages/FloatingField'
import TextLink from 'components/ui/TextLink'
import { dayMonth } from 'helpers/formatDate'
import { DateDisplay } from './styled'
import shortenUUID from 'helpers/shortenUUID'

const FloatingData = ({ shipment }: { shipment: Shipment }) => {
  const assignedUser = useSelector((state: RootState) => {
    if (!shipment?.assignedUserId) return undefined
    return selectUserById(state, shipment.assignedUserId)
  })

  return (
    <Grid container spacing={2}>
      <Field label={<Translate value="UI.Shipments.Details.Status" />}>
        <ShipmentStatusChip status={shipment.status} />
      </Field>
      <Field label={<Translate value="UI.Shipments.Details.AssignedUser" />}>
        {(assignedUser !== undefined && (
          <TextLink
            color="textSecondary"
            as={Link}
            to={`/users/${encodeURIComponent(assignedUser.id)}`}
          >
            {assignedUser.fullName}
          </TextLink>
        )) || <Translate value="Entities.Shipment.Unassigned" />}
      </Field>
      <Field label={<Translate value="UI.Shipments.Details.ShipmentDate" />}>
        <DateDisplay>{dayMonth(shipment.shipmentDate)}</DateDisplay>
      </Field>
      <Field label={<Translate value="UI.Shipments.Details.Itineraries" />}>
        {shipment.itineraryIds.length
          ? shipment.itineraryIds.map((id, i) => (
              <TextLink key={id} color="textSecondary" as={Link} to={`/itineraries/${id}`}>
                {i > 0 ? ', ' : ''}
                {shortenUUID(id)}
              </TextLink>
            ))
          : '-'}
      </Field>
    </Grid>
  )
}

export default FloatingData
