import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSettings } from 'store/user/actions'
import { UserSettings } from 'store/user/reducer'
import { selectSettings } from 'store/user/selectors'

const useSettings = (): [UserSettings, (ns: Partial<UserSettings>) => void] => {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  const updateSettings = useCallback(
    (newSettings: Partial<UserSettings>) => {
      dispatch(setSettings({ ...settings, ...newSettings }))
    },
    [dispatch, settings]
  )

  return [settings, updateSettings]
}

export default useSettings
