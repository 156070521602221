import { CircularProgress, Snackbar, Alert, AlertColor } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import APIStatus from 'types/Status'
import { removeRequest, Request } from 'store/snackbars/actions'
import { selectNextSnackbar } from 'store/snackbars/selectors'

const severities: Record<APIStatus, AlertColor> = {
  [APIStatus.Loading]: 'info',
  [APIStatus.Idle]: 'info',
  [APIStatus.Succeeded]: 'success',
  [APIStatus.PartiallySucceeded]: 'warning',
  [APIStatus.Failed]: 'error',
}

const SNACKBAR_EXIT_TIMER = 200

/* 
  Material design says to only display one snackbar at a time, 
  and show consecutive ones after one another.
*/

const RequestService = () => {
  const dispatch = useDispatch()
  const nextRequest = useSelector((state: RootState) => selectNextSnackbar(state))

  const [request, setRequest] = useState<Request | undefined>()

  useEffect(() => {
    setRequest(nextRequest)
  }, [nextRequest])

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (request?.status === undefined || request.status === APIStatus.Idle) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }, [request, setIsOpen])

  const onClose = () => {
    if (request?.status === APIStatus.Succeeded || request?.status === APIStatus.Failed) {
      setTimeout(() => dispatch(removeRequest(request.id)), SNACKBAR_EXIT_TIMER)
    }
    setIsOpen(false)
  }

  if (!request) {
    return <></>
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={
        request.status === APIStatus.Loading ? 3000 : 2000
      } /* These need to be different to force component to restart timer when status changes */
      open={isOpen}
      onClose={onClose}
    >
      <Alert
        icon={
          request.status === APIStatus.Loading ? (
            <CircularProgress size={22} color="inherit" />
          ) : undefined
        }
        variant="filled"
        severity={severities[request.status]}
      >
        {request.message}
      </Alert>
    </Snackbar>
  )
}

export default RequestService
