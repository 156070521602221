import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const OptionalText = styled.div<{ isHidden?: boolean }>`
  opacity: ${(p) => (p.isHidden ? 0 : 1)};
`

export const FloatingCircularProgress = styled(CircularProgress).attrs({
  color: 'inherit',
  size: 24,
})`
  position: absolute;
  margin: auto;
`
