import React, { ReactNode } from 'react'
import {
  FloatingDataContent,
  FloatingDataDisplay,
  FloatingDataHeader,
  NaNPlaceholder,
} from './styled'

// Stat display: Header then value
// See /shipments/:id or /users/:id for example

export type FloatingFieldProps = {
  label: ReactNode
  children: ReactNode
  defaultChildren?: ReactNode // Used if no children
}

const FloatingField = ({
  label,
  children,
  defaultChildren = <NaNPlaceholder>-</NaNPlaceholder>,
}: FloatingFieldProps) => {
  return (
    <FloatingDataDisplay item xs={12} sm="auto">
      <FloatingDataHeader>{label}</FloatingDataHeader>
      <FloatingDataContent>{children || defaultChildren}</FloatingDataContent>
    </FloatingDataDisplay>
  )
}

export default FloatingField
