import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isDarkMode } from 'store/user/selectors'
import { setDarkMode } from 'store/user/actions'

const useThemeSelector = (): [boolean, () => void] => {
  const dispatch = useDispatch()
  const darkMode: boolean = useSelector(isDarkMode)

  const toggleDarkMode = useCallback(() => {
    dispatch(setDarkMode(!darkMode))
  }, [darkMode, dispatch])

  return [darkMode, toggleDarkMode]
}

export default useThemeSelector
