import moment from 'moment'
import { TransportRequest } from 'types'
// import { I18n } from 'react-redux-i18n'

// TODO: BM fix translations

export type ValidationError = {
  valid: boolean
  error: string | undefined
}

export const validateProductCode = (productCode: string | undefined): ValidationError => {
  // No interaction from user if value is null
  if (productCode === undefined) return { valid: false, error: undefined }

  let error

  if (!productCode) {
    error = 'Product code is required'
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}

export const validateDeliveryDate = (
  deliveryDate: Date | null,
  { latestDeliveryDate, earliestDeliveryDate }: TransportRequest
): ValidationError => {
  // No interaction from user if value is null
  if (deliveryDate === null) return { valid: false, error: undefined }

  let error

  if (!deliveryDate) {
    error = 'Delivery date is required'
  } else if (!moment(deliveryDate).isValid()) {
    error = 'Delivery date is invalid'
  } else if (latestDeliveryDate && new Date(latestDeliveryDate) < new Date(deliveryDate)) {
    error = 'Delivery date too late'
  } else if (earliestDeliveryDate && new Date(earliestDeliveryDate) > new Date(deliveryDate)) {
    error = 'Delivery date too early'
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}

export const validatePickupDate = (
  pickupDate: Date | null,
  { earliestPickupDate, latestPickupDate }: TransportRequest
): ValidationError => {
  // No interaction from user if value is null
  if (pickupDate === null) return { valid: false, error: undefined }

  let error

  if (!pickupDate) {
    error = 'Pickup date is required'
  } else if (!moment(pickupDate).isValid()) {
    error = 'Pickup date is invalid'
  } else if (earliestPickupDate && new Date(earliestPickupDate) > new Date(pickupDate)) {
    error = 'Pickup date too early'
  } else if (latestPickupDate && new Date(latestPickupDate) < new Date(pickupDate)) {
    error = 'Pickup date too late'
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}

export const validateFreightCharge = (
  freightCharge: string | undefined,
  { maxFreightCharge }: TransportRequest
): ValidationError => {
  // No interaction from user if value is null
  if (freightCharge === undefined) return { valid: false, error: undefined }

  let error

  if (!freightCharge) {
    error = 'Freight charge is required'
  } else if (isNaN(parseFloat(freightCharge))) {
    error = 'Freight charge must be a number'
  } else if (maxFreightCharge && parseFloat(freightCharge) > maxFreightCharge) {
    error = 'Freight charge too high'
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}
