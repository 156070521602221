import { Button, Menu, MenuItem } from '@mui/material'
import { Translate as LanguageIcon } from '@mui/icons-material'
import React, { useState } from 'react'
import { setLocale } from 'react-redux-i18n'
import store from '../../../../store'
import {
  FALLBACK_LANG,
  LanguageCode,
  languageOptions,
  LANGUAGE_SETTING_KEY,
} from '../../../../store/i18n/translations'

const setLanguage = (lang: LanguageCode) => {
  ;(store as any).dispatch(setLocale(lang))
}

const LanguageSelect = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [currentLang, setLang] = useState<string>(
    window.localStorage.getItem(LANGUAGE_SETTING_KEY) || FALLBACK_LANG
  )
  const setCurrentLang = (lang: LanguageCode) => {
    handleClose()
    setLang(lang)
    window.localStorage.setItem(LANGUAGE_SETTING_KEY, lang)
    setLanguage(lang)
  }

  const LanguageMenu = (
    <Menu id="language-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
      {languageOptions.map((language) => (
        <MenuItem
          data-no-link="true"
          selected={currentLang === language.code}
          onClick={() => setCurrentLang(language.code)}
          key={language.code}
        >
          {language.name}
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <>
      <Button
        sx={{ paddingX: 2 }}
        aria-owns="language-menu"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        startIcon={<LanguageIcon />}
      >
        {languageOptions.find((lang) => lang.code === currentLang)?.name || ''}
      </Button>
      {LanguageMenu}
    </>
  )
}

export default LanguageSelect
