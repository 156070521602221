import { useEffect, useCallback, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { newSnackbar } from 'store/snackbars/actions'
import { APIStatus } from 'types'

export const useGetOnLoad = (
  apiStatus: APIStatus,
  action: any,
  failMessage: string | ReactNode
) => {
  const dispatch = useDispatch()
  const statusChecker = useCallback(
    (status: APIStatus, loadAction: any, errorMsg: ReactNode) => {
      if (status === APIStatus.Idle) {
        dispatch(loadAction())
      } else if (status === APIStatus.Failed) {
        dispatch(
          newSnackbar({
            message: errorMsg,
            status: APIStatus.Failed,
          })
        )
      }
    },
    [dispatch]
  )

  useEffect(
    () => statusChecker(apiStatus, action, failMessage),
    [apiStatus, action, failMessage, statusChecker]
  )
}

export default useGetOnLoad
