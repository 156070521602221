/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { IconButton, Menu } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

export type ActionMenuProps = {
  children: (childProps: ChildProps) => React.ReactNode
  disabled?: boolean
  icon?: React.ReactNode
}

// Used to notify the menu to drop the anchor (close) on click
type ChildProps = {
  onClick: () => void
}

const ActionMenu = ({ children, icon, disabled }: ActionMenuProps) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const open = Boolean(anchor)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <IconButton
        disabled={disabled}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        {icon ?? <MoreHoriz />}
      </IconButton>
      <Menu id="long-menu" anchorEl={anchor} keepMounted open={open} onClose={handleClose}>
        {children({
          onClick: () => {
            handleClose()
          },
        })}
      </Menu>
    </>
  )
}

export default ActionMenu
