import { useEffect, useState } from 'react'

import { DisplayableShipment } from 'types'

/**
 * Returns shipments not present in the toBeExcluded array
 */
const useDisjointShipments = (
  all: DisplayableShipment[],
  toBeExcluded: DisplayableShipment[]
): DisplayableShipment[] => {
  const [ids, setIds] = useState<string[]>([])
  const [excludedIds, setExcludedIds] = useState<string[]>([])
  const [availableIdsMap, setAvailableIdsMap] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setIds(all.map((s) => s.id))
  }, [all])

  useEffect(() => {
    setExcludedIds(toBeExcluded.map((s) => s.id))
  }, [toBeExcluded])

  useEffect(() => {
    const available: Record<string, boolean> = {}
    for (const id of ids) {
      available[id] = true
    }
    for (const id of excludedIds) {
      delete available[id]
    }
    setAvailableIdsMap(available)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ids), JSON.stringify(excludedIds)])

  return all.reduce<DisplayableShipment[]>((acc, next) => {
    if (availableIdsMap[next.id]) acc.push(next)
    return acc
  }, [])
}

export default useDisjointShipments
