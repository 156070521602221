import { requests } from 'api/client'
import resolvers from 'api/resolvers'
import * as GQL from 'gql/graphql'

type RESTUser = GQL.User & { organization: { id: string } }
type LoginResponse = { token: string }
type LoginParams = {
  email: string
  password: string
}

export type AuthAPI = {
  login: (details: LoginParams) => Promise<LoginResponse>
  getUser: (token?: string) => Promise<RESTUser>
}

const api: AuthAPI = {
  login: async (details) => requests.post('/auth/login', details),
  getUser: (token) =>
    requests
      .get('/auth/me', token)
      .then((data) => ({ ...data, fullName: resolvers.User.fullName(data) })),
}

export default api
