import { apolloClient } from 'api/client'
import { parseResult } from 'api/util'
import { GetOrganizationQuery } from 'api/organization/queries'
import {
  OrganizationInviteUserMutation,
  OrganizationRemoveUserMutation,
} from 'api/organization/mutations'

import { GetUserQuery } from './queries'
import { ChangeUserPasswordMutation } from './mutations'

import * as GQL from 'gql/graphql'

const api = {
  getAll: async (params: GQL.GetOrganizationQueryVariables = {}) => {
    const res = await apolloClient.query({
      query: GetOrganizationQuery,
      variables: params,
    })

    const org = parseResult(res, 'organization') as GQL.Organization
    return (org.users ?? []) as GQL.User[]
  },
  get: async (id: GQL.GetUserQueryVariables['userId']) => {
    const res = await apolloClient.query({
      query: GetUserQuery,
      variables: { userId: id },
    })

    return parseResult(res, 'user') as GQL.User | undefined
  },
  invite: async (data: GQL.InviteUserInput) => {
    const res = await apolloClient.mutate({
      mutation: OrganizationInviteUserMutation,
      variables: { data },
    })

    return parseResult(res, 'inviteUser') as GQL.User
  },
  remove: async (data: GQL.RemoveUserInput) => {
    const res = await apolloClient.mutate({
      mutation: OrganizationRemoveUserMutation,
      variables: { data },
    })

    return parseResult(res, 'removeUser') as GQL.User
  },
  changePassword: async (data: GQL.UpdateUserPasswordInput) => {
    const res = await apolloClient.mutate({
      mutation: ChangeUserPasswordMutation,
      variables: { data },
    })

    return parseResult(res, 'updateUserPassword') as GQL.User
  },
}

export default api
