import styled from 'styled-components'

import { ShipmentEventType } from 'types'

type ColoredEventProps = {
  $eventType: ShipmentEventType
}

const ColoredEvent = styled.div<ColoredEventProps>`
  color: ${(p) => p.theme.event.eventType[p.$eventType]};
`

export default ColoredEvent
