import { Box, Collapse } from '@mui/material'
import { useTheme } from 'helpers/hooks'
import _ from 'lodash'
import React from 'react'
import { ItineraryStop, ItineraryStopActionType } from 'types'
import StopDetailItem from './StopDetailItem'

type StopDetailsProps = {
  stop: ItineraryStop
  expanded: boolean
}

const typeOrder = [ItineraryStopActionType.PickUp, ItineraryStopActionType.Deliver]

const StopDetails = ({ stop, expanded }: StopDetailsProps) => {
  const sortedActions = _(stop.actions)
    .groupBy((a) => a.type)
    .mapValues((as) => as.sort())
    .thru((a) => typeOrder.map((o) => a[o] ?? []))
    .flatten()
    .value()

  const theme = useTheme()

  return (
    <Collapse in={expanded}>
      <Box
        display="flex"
        flexDirection="column"
        borderTop={`1px solid ${theme.palette.divider}`}
        paddingY={2}
        marginY={1}
        paddingX={8}
        fontSize="0.8rem"
      >
        {sortedActions.map((action) => (
          <StopDetailItem action={action} key={`${action.type}.${action.shipment.id}`} />
        ))}
      </Box>
    </Collapse>
  )
}

export default StopDetails
