import React from 'react'
import PersonIcon from '@mui/icons-material/Person'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import User from 'types/User'
import { selectUserById } from 'store/users/selectors'
import { Avatar } from './styled'

const UserAvatar = ({ user: userIdentity }: { user: User | User['id'] }) => {
  const userId = typeof userIdentity === 'object' ? userIdentity.id : userIdentity
  const user: User | undefined = useSelector((state: RootState) => selectUserById(state, userId))

  if (!user) {
    return <></>
  }

  const fullName = user.fullName

  return (
    <Avatar colorseed={fullName}>
      {fullName ? (
        fullName
          .split(' ')
          .map((word) => word[0])
          .join('')
      ) : (
        <PersonIcon />
      )}
    </Avatar>
  )
}

export default UserAvatar
