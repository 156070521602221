import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getGlobalData, clearGlobalData, pollGlobalData } from 'store/root'
import { clearShipments, getShipments, pollShipments } from 'store/shipments/actions'
import { clearUsers, getUsers } from 'store/users/actions'
import shipmentHandlers from 'store/shipments/sagas'
import usersHandlers from 'store/users/sagas'
import userHandlers from 'store/user/sagas'
import { setMomentLocale } from 'helpers/formatDate'
import { clearUser, getUser } from 'store/user/actions'
import { clearItineraries, getItineraries, pollItineraries } from './itineraries/actions'
import itineraryHandlers from './itineraries/sagas'
import transportRequestHandlers from './transportRequests/sagas'
import {
  clearTransportRequests,
  getTransportRequests,
  pollTransportRequests,
} from './transportRequests/actions'
import { clearCarrierProducts, getCarrierProducts } from './carrierProducts/actions'
import carrierProductHandlers from './carrierProducts/sagas'
import connectionHandlers from './connections/sagas'
import { clearConnections, getConnections } from './connections/actions'

function* getGlobalDataHandler({ payload }: ReturnType<typeof getGlobalData>) {
  yield put(getUser())
  yield put(getShipments(payload))
  yield put(getUsers(payload))
  yield put(getItineraries(payload))
  yield put(getTransportRequests(payload))
  yield put(getCarrierProducts(payload))
  yield put(getConnections(payload))
}

function* clearGlobalDataHandler() {
  yield put(clearUser())
  yield put(clearShipments())
  yield put(clearUsers())
  yield put(clearItineraries())
  yield put(clearTransportRequests())
  yield put(clearCarrierProducts())
  yield put(clearConnections())
}

function* pollGlobalDataHandler() {
  yield put(pollShipments())
  yield put(pollItineraries())
  yield put(pollTransportRequests())
}

function* updateMomentLocale({ locale }: any) {
  yield call(setMomentLocale, locale)
}

const globalHandlers = [
  takeLatest(getGlobalData.type, getGlobalDataHandler),
  takeLatest(pollGlobalData.type, pollGlobalDataHandler),
  takeLatest(clearGlobalData.type, clearGlobalDataHandler),
  takeLatest('@@i18n/SET_LOCALE', updateMomentLocale),
]

function* rootSaga() {
  yield all([
    ...globalHandlers,
    ...shipmentHandlers,
    ...usersHandlers,
    ...userHandlers,
    ...itineraryHandlers,
    ...transportRequestHandlers,
    ...carrierProductHandlers,
    ...connectionHandlers,
  ])
}

export default rootSaga
