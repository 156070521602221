import React from 'react'
import { SvgIconProps } from '@mui/material'
import { ShipmentAction, Shipment } from 'types/Shipments'

import { getActionIcon } from './icons'
import { getLabel } from './labels'
import { getActionType, ActionType } from './actionTypes'
import { isActionAvailable, isActionAvailableOnAny } from './predicates'

export { useShipmentActions } from './hooks'
export type { ActionType } from './actionTypes'

type LabelParams = {
  plural?: boolean
  options?: any
  nullIfUnavailable?: boolean
}

export type ShipmentActionOptions = {
  getIcon: (props?: SvgIconProps) => React.ReactNode
  getLabel: (params?: LabelParams) => string | null
  getType: () => ActionType
  isAvailable(): boolean
  isAvailableOnAny(): boolean
}

export type ShipmentActionsOptions = {
  [key in ShipmentAction]: ShipmentActionOptions
} & {
  getAvailableActions: () => ShipmentAction[]
}

export const availableActions = <T extends Shipment>(shipment: T | T[]) =>
  Object.values(ShipmentAction).reduce((acc, action) => {
    if (isActionAvailable(shipment, action as ShipmentAction)) {
      return acc.concat(action as ShipmentAction)
    }
    return acc
  }, [] as ShipmentAction[])

export const getShipmentAction = <T extends Shipment>(
  shipment: T | T[],
  action: ShipmentAction
): ShipmentActionOptions => {
  return {
    getIcon: (props?: SvgIconProps) => getActionIcon(action, props),
    getLabel: (params) => {
      if (params?.nullIfUnavailable && !isActionAvailable(shipment, action)) return null
      return getLabel(action, params?.plural || Array.isArray(shipment), params?.options)
    },
    getType: () => getActionType(action),
    isAvailable: () => isActionAvailable(shipment, action),
    isAvailableOnAny: () => isActionAvailableOnAny(shipment, action),
  }
}

export const getShipmentActions = <T extends Shipment>(
  shipment: T | T[]
): ShipmentActionsOptions => {
  return {
    [ShipmentAction.Assign]: getShipmentAction(shipment, ShipmentAction.Assign),
    [ShipmentAction.Unassign]: getShipmentAction(shipment, ShipmentAction.Unassign),
    [ShipmentAction.Cancel]: getShipmentAction(shipment, ShipmentAction.Cancel),
    [ShipmentAction.Pickup]: getShipmentAction(shipment, ShipmentAction.Pickup),
    getAvailableActions: () => availableActions(shipment),
  }
}
