import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { ItineraryStop, ItineraryStopActionStatus } from 'types'
import theme from 'theme'

type StopProgressBarProps = {
  stop: ItineraryStop
}

type ProgressBarProps = {
  completed: number
  total: number
}

const ProgressBar = ({ completed, total }: ProgressBarProps) => (
  <Box borderRadius={2} display="flex" overflow="hidden" flexGrow={1} marginX={2}>
    <Box
      height={theme.dark.spacing(1)}
      bgcolor="success.main"
      width={`${(completed / total) * 100 || 0}%`}
    ></Box>
    <Box
      height={theme.dark.spacing(1)}
      bgcolor="background.default"
      width={`${100 - (completed / total) * 100}%`}
    ></Box>
  </Box>
)

const StopProgressBar = ({ stop }: StopProgressBarProps) => {
  const [totalActions, setTotalActions] = useState(0)
  const [completedActions, setCompletedActions] = useState(0)
  const [allDone, setAllDone] = useState(false)

  useEffect(() => {
    const completed = stop.actions.filter(
      (a) => a.status === ItineraryStopActionStatus.Complete
    ).length

    setCompletedActions(completed)
  }, [stop.actions])

  useEffect(() => {
    setTotalActions(stop.actions.length)
  }, [stop.actions])

  useEffect(() => {
    setAllDone(totalActions === completedActions)
  }, [totalActions, completedActions])

  return (
    <Box display="flex" alignItems="center" maxWidth="16rem" minWidth="16rem">
      <ProgressBar completed={completedActions} total={totalActions} />
      <Box paddingX={1} color={allDone ? 'success.main' : 'inherit'}>
        {completedActions} / {totalActions}
      </Box>
    </Box>
  )
}

export default StopProgressBar
