import React from 'react'
import { Translate } from 'react-redux-i18n'
import { TransportRequestStatus } from 'types'
import { StatusChip } from './styled'

export type TransportRequestStatusChipProps = {
  status: TransportRequestStatus
}

const TransportRequestStatusChip = ({ status }: TransportRequestStatusChipProps) => (
  <StatusChip
    variant="outlined"
    size="small"
    label={<Translate value={`Entities.TransportRequest.Status.${status}`} />}
    status={status}
  />
)

export default TransportRequestStatusChip
