import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DisplayData, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables'
import ReloadIcon from '@mui/icons-material/Replay'
import SaveIcon from '@mui/icons-material/SaveAs'

import { Itinerary, ItineraryAction } from 'types'
import APIStatus from 'types/Status'
import { RootState } from 'store'
import { getGlobalData } from 'store/root'
import { selectAllUsers } from 'store/users/selectors'
import DataGrid, { getDataIndex, SORT_ORDER } from 'components/features/data/DataGrid'
import flattenObject from 'helpers/flattenObject'
import ActionButton from 'components/features/data/DataGrid/ActionButton'

import useGetOnLoad from 'helpers/hooks/useGetOnLoad'
import { getItineraries } from 'store/itineraries/actions'
import getColumns, { ItineraryColumns } from './columns'
import ActionMenu from '../ActionMenu'
import CancelItineraryDialog from '../CanceltineraryDialog'
import { CustomColumnOptions } from 'helpers/types'
import { weekdayNumber } from 'helpers/formatDate'
import { PreserverProvider } from 'services/ConfigPreserver/PreserverContext'
import SaveConfigDialog from 'components/features/misc/SaveConfigDialog'

const isLoading = (itinerary: APIStatus): boolean => itinerary === APIStatus.Loading ?? false

export type ItineraryListProps = {
  name: string
  data: Itinerary[]
  columnOptions?: CustomColumnOptions
}

const ItineraryList = ({ name, data: _data, columnOptions }: ItineraryListProps) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState<MUIDataTableOptions>({})

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [dialogItineraries, setDialogItineraries] = useState<Itinerary[]>([])
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)

  const itineraryApiStatus = useSelector((state: RootState) => state.itineraries.status)

  useGetOnLoad(
    itineraryApiStatus,
    getItineraries,
    <Translate value="UI.Itineraries.Table.Errors.LoadFailed" />
  )

  // Add some column properties here to avoid the headache of doing it in columns
  const tableData = useMemo(
    () =>
      _data.map((itinerary) => ({
        ...itinerary,
        stopCount: itinerary.stops?.length,
        packageCount: itinerary?.stops?.reduce((acc, next) => {
          next.actions.forEach((a) => {
            acc += a.shipment.packageItems.length
          })
          return acc
        }, 0),
        shipmentCount: itinerary.stops?.reduce((acc, next) => {
          next.actions.forEach((a) => {
            acc.add(a.shipment.id)
          })
          return acc
        }, new Set<string>()).size,
        weekday: itinerary.startDate && weekdayNumber(itinerary.startDate),
      })),
    [_data]
  )

  const flattenedData = useMemo(() => tableData.map(flattenObject), [tableData])

  const handlePerformedAction = (reloadData: boolean) => {
    if (reloadData) {
      setOptions({ rowsSelected: [] })
    }
  }

  const handleItineraryAction = (action: ItineraryAction, itinerary: Itinerary | Itinerary[]) => {
    const itineraries = Array.isArray(itinerary) ? itinerary : [itinerary]

    switch (action) {
      case ItineraryAction.Cancel:
        setDialogItineraries(itineraries)
        setIsCancelDialogOpen(true)
        break
      default:
        break
    }
  }

  const actions = (_displayData: DisplayData, selectedIndices: number[]) => {
    const itinerary = selectedIndices.map((i) => tableData[i])

    return <ActionMenu itinerary={itinerary} handleItineraryAction={handleItineraryAction} />
  }

  const rowActions = (tableMeta: MUIDataTableMeta) => {
    const itinerary = tableData[getDataIndex(tableMeta)]

    return <ActionMenu itinerary={itinerary} handleItineraryAction={handleItineraryAction} />
  }

  const users = useSelector(selectAllUsers)
  const columns = getColumns(tableData, users, rowActions, columnOptions)

  const defaults = {
    sort: {
      name: ItineraryColumns.StartDate,
      direction: SORT_ORDER.DESCENDING,
    },
  }

  const toolbar = () => (
    <>
      <ActionButton
        icon={<SaveIcon />}
        label={<Translate value="UI.Table.Reload" />}
        onClick={() => setIsSaveDialogOpen(true)}
      />
      <ActionButton
        icon={<ReloadIcon />}
        label={<Translate value="UI.Table.Reload" />}
        onClick={() => dispatch(getGlobalData())}
      />
    </>
  )

  return (
    <PreserverProvider name={name} columns={columns} defaults={defaults}>
      {isCancelDialogOpen && (
        <CancelItineraryDialog
          itineraries={dialogItineraries}
          onClose={(wasActionPerformed) => {
            handlePerformedAction(wasActionPerformed)
            setIsCancelDialogOpen(false)
          }}
        />
      )}
      {isSaveDialogOpen && (
        <SaveConfigDialog
          onClose={() => {
            setIsSaveDialogOpen(false)
          }}
        />
      )}
      <DataGrid
        name={name}
        data={flattenedData}
        columns={columns ?? []}
        actions={actions}
        toolbar={toolbar}
        loading={isLoading(itineraryApiStatus)}
        defaults={defaults}
        options={options}
      />
    </PreserverProvider>
  )
}

export default ItineraryList
