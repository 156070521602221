import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isDarkMode } from 'store/user/selectors'
import { setDarkMode } from 'store/user/actions'

import defaultTheme, { MoveTheme } from 'theme'

const DARK_MODE_ON = 'on'
const DARK_MODE_OFF = 'off'
const DARK_MODE_KEY = 'dark-mode'

const useTheme = (): MoveTheme => {
  const dispatch = useDispatch()

  useEffect(() => {
    const savedDarkMode = window.localStorage.getItem(DARK_MODE_KEY)
    if (savedDarkMode !== undefined) {
      dispatch(setDarkMode(savedDarkMode === DARK_MODE_ON))
    }
  }, [dispatch])

  const darkMode = useSelector(isDarkMode)

  useEffect(() => {
    window.localStorage.setItem(DARK_MODE_KEY, darkMode ? DARK_MODE_ON : DARK_MODE_OFF)
  }, [darkMode])

  return darkMode ? defaultTheme.dark : defaultTheme.light
}

export default useTheme
