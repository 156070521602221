import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { I18n, Translate } from 'react-redux-i18n'

import TextLink from 'components/ui/TextLink'
import { CustomColumnOptions } from 'helpers/types'
import { Itinerary, ItineraryStatus, User } from 'types'
import columnConstructors from 'components/features/data/columnConstructors'
import { SORT_ORDER } from 'components/features/data/DataGrid'
import shortenUUID from 'helpers/shortenUUID'
import { dateAndTime, dayMonth, weekdayString } from 'helpers/formatDate'
import ItineraryStatusChip from '../ItineraryStatusChip'
import mergeColumnOptions from 'helpers/mergeColumnOptions'
import { compareStrings } from 'helpers/compare'

export enum ItineraryColumns {
  Id = 'id',
  Name = 'name',
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
  Weekday = 'weekday',
  Status = 'status',
  Courier = 'assignedUser.fullName',
  Stops = 'stopCount',
  Shipments = 'shipmentCount',
  Packages = 'packageCount',
  TopStops = 'topStops',
  Actions = 'actions',
}

type GetItineraryColumnsSignature = (
  data: Itinerary[],
  users: User[],
  actions: (tableMeta: MUIDataTableMeta) => ReactNode,
  columnOptions?: CustomColumnOptions
) => MUIDataTableColumn[]

const getItineraryColumns: GetItineraryColumnsSignature = (data, users, actions, columnOptions) => {
  const columns: MUIDataTableColumn[] = [
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Id'),
      name: ItineraryColumns.Id,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Id" />,
        customBodyRender: (id) => (
          <TextLink color="textSecondary" as={Link} to={`/itineraries/${id}`}>
            {shortenUUID(id)}
          </TextLink>
        ),
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.StartDate'),
      name: ItineraryColumns.StartDate,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.StartDate" />,
        customBodyRender: dayMonth,
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Weekday'),
      name: ItineraryColumns.Weekday,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Weekday" />,
        customBodyRender: (value) => (value ? weekdayString(value) : '-'),
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Courier'),
      name: ItineraryColumns.Courier,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Courier" />,
        customBodyRenderLite: (dataIndex) => {
          const { assignedUser } = data[dataIndex]
          if (!assignedUser) return <Translate value="Entities.Itinerary.Unassigned" />

          return (
            <TextLink color="textSecondary" as={Link} to={`/users/${assignedUser.id}`}>
              {assignedUser.fullName}
            </TextLink>
          )
        },
        filterOptions: {
          names: users
            .map((user) => user.fullName)
            .concat([I18n.t('Entities.Itinerary.Unassigned')]),
          logic: (value, filters) => {
            if (filters.length === 0) return false
            if (!value && filters.includes(I18n.t('Entities.Itinerary.Unassigned'))) return false
            return !filters.includes(value)
          },
        },
        filterType: 'multiselect',
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Name'),
      name: ItineraryColumns.Name,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Name" />,
        customBodyRenderLite: (dataIndex) => {
          const itinerary = data[dataIndex]

          if (!itinerary.name) return '-'
          if (itinerary.name?.length > 32) return `${itinerary.name.slice(0, 32)}...`

          return itinerary.name
        },
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Status'),
      name: ItineraryColumns.Status,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Status" />,
        customBodyRenderLite: (dataIndex) => {
          const { status } = data[dataIndex]

          return <ItineraryStatusChip status={status} />
        },
        customFilterListOptions: {
          render: (status) => I18n.t(`Entities.Itinerary.Status.${status}`),
        },
        filterOptions: {
          renderValue: (status) => I18n.t(`Entities.Itinerary.Status.${status}`),
          names: Object.values(ItineraryStatus),
        },
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Stops'),
      name: ItineraryColumns.Stops,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Stops" />,
        customBodyRender: (value) => value ?? '-',
        filter: false,
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            (a - b) * (order === SORT_ORDER.DESCENDING ? 1 : -1),
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Shipments'),
      name: ItineraryColumns.Shipments,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Shipments" />,
        customBodyRender: (value) => value ?? '-',
        filter: false,
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            (a - b) * (order === SORT_ORDER.DESCENDING ? 1 : -1),
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.TopStops'),
      name: ItineraryColumns.TopStops,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.TopStops" />,
        customBodyRenderLite: (dataIndex) => {
          const itinerary = data[dataIndex]
          const cities = itinerary?.stops.reduce((acc, next) => {
            acc[next.city] = acc[next.city] ? acc[next.city] + 1 : 1
            return acc
          }, {} as { [city: string]: number })

          const topCities = Object.entries(cities ?? {})
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3)
            .map(([city]) => city)

          return topCities.map((city) => (
            <p style={{ margin: 0 }} key={city}>
              {city}
            </p>
          ))
        },
        filter: false,
        sort: false,
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.CreatedAt'),
      name: ItineraryColumns.CreatedAt,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.CreatedAt" />,
        customBodyRender: dateAndTime,
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.Packages'),
      name: ItineraryColumns.Packages,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.Packages" />,
        customBodyRender: (value) => value ?? '-',
        filter: false,
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            (a - b) * (order === SORT_ORDER.DESCENDING ? 1 : -1),
      },
    },
    columnConstructors.actions(actions, ItineraryColumns.Actions),
  ]

  mergeColumnOptions(columnOptions, columns)

  return columns
}

export default getItineraryColumns
