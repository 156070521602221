import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { Error, LocalShipping } from '@mui/icons-material'
import { Translate } from 'react-redux-i18n'

import api from 'api'
import { DisplayableShipment } from 'types/Shipments'
import APIStatus from 'types/Status'

import { CheckCircle } from './styled'

const allCompleted = (trackable: TrackableShipment[]) =>
  trackable.length > 0 && trackable.every((t) => t.status === APIStatus.Succeeded)

const isAnyLoading = (trackable: TrackableShipment[]) =>
  trackable.some((t) => t.status === APIStatus.Loading)

const isNotCompleted = (trackable: TrackableShipment) => trackable.status !== APIStatus.Succeeded

type TrackableShipment = {
  shipment: DisplayableShipment
  status: APIStatus
}

export type CancelShipmentDialogProps = {
  onClose: (() => void) | ((wasActionPerformed: boolean) => void)
  shipments: DisplayableShipment[]
}

const CancelShipmentDialog = ({ shipments, onClose }: CancelShipmentDialogProps) => {
  const [trackable, setTrackable] = useState<TrackableShipment[]>([])
  const [isRetrying, setIsRetrying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const [userDidInteract, setUserDidInteract] = useState(false)
  const multiple = trackable.length > 1

  useEffect(() => {
    const items: TrackableShipment[] = shipments.map((shipment) => ({
      shipment,
      status: APIStatus.Idle,
    }))

    setTrackable(items)
  }, [shipments])

  useEffect(() => {
    setIsLoading(isAnyLoading(trackable))
    setIsDone(allCompleted(trackable))
  }, [trackable])

  useEffect(() => {
    if (isDone) {
      setIsClosing(true)
      setTimeout(() => {
        onClose(true)
      }, 1500)
    }
  }, [isDone, onClose])

  const handleClose = () => {
    if (userDidInteract) {
      onClose(true)
    } else {
      onClose(false)
    }
  }

  const cancelShipments = () => {
    setUserDidInteract(true)
    setTrackable((prevState) =>
      prevState.map((t) => {
        if (!isNotCompleted(t)) return t

        return { ...t, status: APIStatus.Loading }
      })
    )

    trackable.forEach((t, index) => {
      if (!isNotCompleted(t)) return

      api.Shipment.cancel({
        shipmentId: [t.shipment.id],
      })
        .then(() => {
          setTrackable((prevState) => {
            return Object.assign([], prevState, {
              [index]: {
                shipment: prevState[index].shipment,
                status: APIStatus.Succeeded,
              },
            })
          })
        })
        .catch(() => {
          setIsRetrying(true)
          setTrackable((prevState) => {
            return Object.assign([], prevState, {
              [index]: {
                shipment: prevState[index].shipment,
                status: APIStatus.Failed,
              },
            })
          })
        })
    })
  }

  const statusIcon = (status: APIStatus) => {
    switch (status) {
      case APIStatus.Loading:
        return <CircularProgress />
      case APIStatus.Succeeded:
        return <CheckCircle color="action" />
      case APIStatus.Failed:
        return <Error color="secondary" />
      default:
        return null
    }
  }

  const getContentText = () => {
    const basePath = `UI.Shipments.StatusMessages.Cancel${multiple ? 'Multiple' : ''}`
    const remainingAmount = trackable.filter(isNotCompleted).length
    const id = trackable[0]?.shipment?.shipmentNumber

    if (isDone) {
      return <Translate value={`${basePath}.Success`} amount={trackable.length} id={id} />
    }

    if (isRetrying) {
      return (
        <>
          <Translate value={`${basePath}.Failed`} amount={remainingAmount} id={id} />
          {'. '}
          <Translate value="General.Questions.Retry" />
        </>
      )
    }

    return <Translate value={`${basePath}.Prompt`} amount={remainingAmount} id={id} />
  }

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      aria-labelledby="assign-user-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="assign-user-dialog-title">
        <Translate value={`UI.Shipments.Table.CancelShipment${multiple ? 's' : ''}`} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{getContentText()}</DialogContentText>
        <List>
          {trackable.map((t) => (
            <ListItem key={t.shipment.id}>
              <ListItemAvatar>
                <Avatar>
                  <LocalShipping />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t.shipment.shipmentNumber}
                secondary={t.shipment.user?.fullName}
              />
              {statusIcon(t.status)}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button disabled={isClosing || isLoading} onClick={() => cancelShipments()} color="error">
          {isRetrying ? <Translate value="General.Retry" /> : <Translate value="General.Yes" />}
        </Button>
        <Button disabled={isClosing || isLoading} onClick={() => onClose(false)} color="primary">
          <Translate value="General.No" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelShipmentDialog
