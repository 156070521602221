import styled from 'styled-components'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import { ActionType } from './index'

type ListItemIconProps = {
  type?: ActionType
}

export const ListItemIcon = styled(MuiListItemIcon)<ListItemIconProps>`
  ${(p) => (p.type === 'danger' ? `color: ${p.theme.palette.error.main}` : '')};
`

export default ListItemIcon
