import { useState, useEffect } from 'react'
import { Shipment } from 'types/Shipments'
import { getShipmentActions, ShipmentActionsOptions } from './index'

export const useShipmentActions = <T extends Shipment>(shipment: T | T[]) => {
  const [actions, setActions] = useState<ShipmentActionsOptions>(getShipmentActions(shipment))

  useEffect(() => {
    setActions(getShipmentActions(shipment))
  }, [shipment])

  return actions
}

export default useShipmentActions
