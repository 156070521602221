import { MUIDataTableColumn } from 'mui-datatables'
import { CustomColumnOptions } from './types'

const mergeColumnOptions = (
  columnOptions?: CustomColumnOptions,
  columns?: MUIDataTableColumn[]
) => {
  if (!columnOptions) return

  Object.keys(columnOptions).forEach((columnName) => {
    const column = columns?.find((col) => col.name === columnName)

    if (!column) throw new Error(`No column with name ${columnName} found.`)

    column.options = { ...(column.options ?? {}), ...columnOptions[columnName] }
  })
}

export default mergeColumnOptions
