import styled from 'styled-components'
import { List as MuiList, alpha } from '@mui/material'
import theme from 'theme'

export const MenuHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7rem;
`

export const List = styled(MuiList)({
  '&& .Mui-selected': {
    backgroundColor: alpha(theme.dark.palette.primary.main, 0.16),
  },

  '&& .Mui-selected:hover': {
    backgroundColor: alpha(theme.dark.palette.primary.main, 0.24),
  },

  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'rgba(255, 255,255, 0.08)',
  },
})
