import React, { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

export type SelectDateProps = {
  value: Date
  onChange: (selectedDate: Date) => void
}

const SelectDate = ({ value, onChange }: SelectDateProps) => {
  const [selectedDate, setSelectedDate] = useState(value)

  useEffect(() => {
    setSelectedDate(value)
  }, [value])

  const handleChange = (newDate: Date | null) => {
    if (newDate) {
      setSelectedDate(newDate)
      onChange(newDate)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={<Translate value="UI.Itineraries.Properties.StartDate" />}
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        clearable={false}
      />
    </LocalizationProvider>
  )
}

export default SelectDate
