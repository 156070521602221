import { DisplayableShipment, ShipmentStatus } from 'types'

/**
 * Checks if a shipment is eligible to be added to an itinerary
 */
const isEligibleForItinerary = (shipment: DisplayableShipment, itineraryId?: string): boolean => {
  const isNotDelivered = shipment.status !== ShipmentStatus.Delivered
  const isNotCancelled = shipment.status !== ShipmentStatus.Cancelled
  const isNotAssignedToAnyItinerary = shipment.itineraryIds?.length === 0 ?? true
  const isAssignedToPassedItinerary = itineraryId
    ? shipment.itineraryIds?.includes(itineraryId) ?? false
    : false

  return (
    isNotDelivered && isNotCancelled && (isNotAssignedToAnyItinerary || isAssignedToPassedItinerary)
  )
}

export default isEligibleForItinerary
