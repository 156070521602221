import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Typography } from '@mui/material'

import { changePassword } from 'store/user/actions'
import LoadingButton from 'components/ui/LoadingButton'
import PasswordTextField from 'components/ui/PasswordTextField'

import { ChangePasswordBox } from './styled'

type ChangePasswordProps = {
  userId: string
}

const ChangePassword = ({ userId }: ChangePasswordProps) => {
  const dispatch = useDispatch()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [isDifferent, setDifferent] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const validate = () => {
    setDifferent(newPassword !== confirm)
    return newPassword === confirm && newPassword.length > 0
  }

  const callback = (success: boolean) => {
    setLoading(false)
    if (success) {
      setConfirm('')
      setNewPassword('')
      setOldPassword('')
    }
  }

  const passwordChange = () => {
    if (!validate()) return

    setLoading(true)
    dispatch(
      changePassword({
        data: { userId, oldPassword, newPassword },
        callback,
      })
    )
  }

  return (
    <ChangePasswordBox my={4}>
      <Typography variant="h5">
        <Translate value="UI.Settings.ChangePassword.Title" />
      </Typography>
      <PasswordTextField
        variant="outlined"
        fullWidth
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        label={<Translate value="UI.Settings.ChangePassword.CurrentPassword" />}
      />
      <PasswordTextField
        variant="outlined"
        fullWidth
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        label={<Translate value="UI.Settings.ChangePassword.NewPassword" />}
      />
      <br />
      <PasswordTextField
        variant="outlined"
        fullWidth
        value={confirm}
        onChange={(e) => setConfirm(e.target.value)}
        onBlur={validate}
        error={isDifferent}
        helperText={
          isDifferent && <Translate value="UI.Settings.ChangePassword.Validation.Different" />
        }
        label={<Translate value="UI.Settings.ChangePassword.ConfirmNewPassword" />}
      />
      <br />
      <LoadingButton
        variant="outlined"
        color="primary"
        onClick={passwordChange}
        label={<Translate value="UI.Settings.ChangePassword.Change" />}
        isLoading={isLoading}
        disabled={newPassword === ''}
      />
    </ChangePasswordBox>
  )
}

export default ChangePassword
