import { createAction } from '@reduxjs/toolkit'
import { i18nReducer } from 'react-redux-i18n'
import { combineReducers } from 'redux'
import { StoreNames } from 'types/Misc'
import snackbarReducer from './snackbars'
import shipmentReducer from './shipments'
import transportRequestReducer from './transportRequests'
import carrierProductsReducer from './carrierProducts'
import connectionReducer from './connections'
import itineraryReducer from './itineraries'
import usersReducer from './users'
import userReducer from './user'

export type GetDataArgs =
  | {
      silent?: boolean // no loading state, no error handling, updates only on a successful fetch
    }
  | undefined

const rootReducer = combineReducers({
  [StoreNames.shipments]: shipmentReducer,
  [StoreNames.itineraries]: itineraryReducer,
  [StoreNames.transportRequests]: transportRequestReducer,
  [StoreNames.carrierProducts]: carrierProductsReducer,
  [StoreNames.connections]: connectionReducer,
  [StoreNames.users]: usersReducer,
  [StoreNames.user]: userReducer,
  [StoreNames.snackbars]: snackbarReducer,
  [StoreNames.i18n]: i18nReducer,
})

export const getGlobalData = createAction<GetDataArgs>(`${StoreNames.root}_GET`)
export const pollGlobalData = createAction(`${StoreNames.root}_POLL`)
export const clearGlobalData = createAction(`${StoreNames.root}_CLEAR`)

export default rootReducer
