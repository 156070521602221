import React, { useEffect, useState } from 'react'
import { Grid, MenuItem } from '@mui/material'
import { InputField } from './styled'
import { I18n } from 'react-redux-i18n'
import {
  validateDeliveryDate,
  validateFreightCharge,
  validatePickupDate,
  validateProductCode,
  ValidationError,
} from './validators'
import { CarrierProduct, TransportRequest } from 'types'
import SelectDate from './SelectDate'

const validators: { [field: string]: ValidationError } = {
  productCode: {
    valid: false,
    error: undefined,
  },
  deliveryDate: {
    valid: false,
    error: undefined,
  },
  pickupDate: {
    valid: false,
    error: undefined,
  },
  freightCharge: {
    valid: false,
    error: undefined,
  },
}

export type AcceptRequestTemplate = {
  productCode: string
  deliveryDate: string
  pickupDate: string
  freightCharge: number
}

export type AcceptRequestFormProps = {
  products: CarrierProduct[]
  transportRequest: TransportRequest
  onChange?: (data: AcceptRequestTemplate) => void
  onValidation?: (valid: boolean) => void
}

const AcceptRequestForm = ({
  products,
  transportRequest,
  onChange,
  onValidation,
}: AcceptRequestFormProps) => {
  const [productCode, setProductCode] = useState<string>()
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null)
  const [pickupDate, setPickupDate] = useState<Date | null>(null)
  const [freightCharge, setFreightCharge] = useState<string>()

  const [productCodeValidation, setProductCodeValidation] = useState(validators.productCode)
  const [deliveryDateValidation, setDeliveryDateValidation] = useState(validators.deliveryDate)
  const [pickupDateValidation, setPickupDateValidation] = useState(validators.pickupDate)
  const [freightChargeValidation, setFreightChargeValidation] = useState(validators.freightCharge)

  const [allValid, setAllValid] = useState(false)

  useEffect(() => {
    setProductCodeValidation(validateProductCode(productCode))
    setDeliveryDateValidation(validateDeliveryDate(deliveryDate, transportRequest))
    setPickupDateValidation(validatePickupDate(pickupDate, transportRequest))
    setFreightChargeValidation(validateFreightCharge(freightCharge, transportRequest))
  }, [productCode, deliveryDate, pickupDate, freightCharge, , transportRequest])

  useEffect(() => {
    const allValidated =
      productCodeValidation.valid &&
      deliveryDateValidation.valid &&
      pickupDateValidation.valid &&
      freightChargeValidation.valid

    setAllValid(allValidated)
  }, [productCodeValidation, deliveryDateValidation, pickupDateValidation, freightChargeValidation])

  useEffect(() => {
    onValidation?.(allValid)
  }, [allValid, onValidation])

  useEffect(() => {
    const allSet = !!productCode && !!deliveryDate && !!pickupDate && !!freightCharge

    if (allSet && allValid) {
      onChange?.({
        productCode,
        deliveryDate: deliveryDate.toString(),
        pickupDate: pickupDate.toString(),
        freightCharge: parseFloat(freightCharge),
      } as AcceptRequestTemplate)
    }
    // eslint-disable-next-line
  }, [productCode, deliveryDate, pickupDate, freightCharge, allValid])

  return (
    <Grid container spacing={1}>
      <Grid item md={6} sm={12} xs={12}>
        <InputField
          select
          required
          label={I18n.t('UI.Requests.Accept.ProductCode')}
          value={productCode}
          error={!!productCodeValidation.error}
          onChange={(e: any) => setProductCode(e.target.value)}
        >
          {products.map((p) => (
            <MenuItem value={p.carrierProductCode} key={p.id}>
              {p.carrierProductCode} ({p.name})
            </MenuItem>
          ))}
        </InputField>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Requests.Accept.FreightCharge')}
          value={freightCharge}
          error={!!freightChargeValidation.error}
          helperText={freightChargeValidation.error ?? ''}
          onChange={(e: any) => setFreightCharge(e.target.value)}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <SelectDate
          label={I18n.t('UI.Requests.Accept.PickupDate')}
          value={pickupDate}
          helperText={pickupDateValidation.error ?? ''}
          onChange={setPickupDate}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <SelectDate
          label={I18n.t('UI.Requests.Accept.DeliveryDate')}
          value={deliveryDate}
          helperText={deliveryDateValidation.error ?? ''}
          onChange={setDeliveryDate}
        />
      </Grid>
    </Grid>
  )
}

export default AcceptRequestForm
