import { apolloClient } from 'api/client'
import { parseResult } from 'api/util'
import {
  AddShipmentsToItineraryMutation,
  AssignItineraryMutation,
  CancelItineraryMutation,
  CreateItineraryMutation,
  RemoveShipmentsFromItineraryMutation,
  ReorderItineraryStopMutation,
  SetItineraryStopOrderMutation,
  UnassignItineraryMutation,
} from './mutations'
import { GetItinerariesQuery, GetItineraryQuery } from './queries'

import * as GQL from 'gql/graphql'

const api = {
  get: async (id: string) => {
    const res = await apolloClient.query({
      query: GetItineraryQuery,
      variables: { itineraryId: id },
    })

    return parseResult(res, 'itinerary') as GQL.Itinerary
  },
  getAll: async () => {
    const res = await apolloClient.query({
      query: GetItinerariesQuery,
      variables: { filter: {} },
    })

    return (parseResult(res, 'itineraries') ?? []) as GQL.Itinerary[]
  },
  create: async (data: GQL.CreateItineraryInput) => {
    const res = await apolloClient.mutate({
      mutation: CreateItineraryMutation,
      variables: { data },
    })

    return parseResult(res, 'createItinerary') as GQL.Itinerary
  },
  addShipments: async (data: GQL.ModifyItineraryInput) => {
    const res = await apolloClient.mutate({
      mutation: AddShipmentsToItineraryMutation,
      variables: { data },
    })

    return parseResult(res, 'addShipmentsToItinerary') as GQL.Itinerary
  },
  removeShipments: async (data: GQL.ModifyItineraryInput) => {
    const res = await apolloClient.mutate({
      mutation: RemoveShipmentsFromItineraryMutation,
      variables: { data },
    })

    return parseResult(res, 'removeShipmentsFromItinerary') as GQL.Itinerary
  },
  reorderStop: async (data: GQL.ReorderStopInput) => {
    const res = await apolloClient.mutate({
      mutation: ReorderItineraryStopMutation,
      variables: { data },
    })

    return parseResult(res, 'reorderStop') as GQL.Itinerary
  },
  setStopOrder: async (data: GQL.SetStopOrderInput) => {
    const res = await apolloClient.mutate({
      mutation: SetItineraryStopOrderMutation,
      variables: { data },
    })

    return parseResult(res, 'setStopOrder') as GQL.Itinerary
  },
  cancel: async (data: GQL.CancelItineraryMutationVariables) => {
    const res = await apolloClient.mutate({
      mutation: CancelItineraryMutation,
      variables: data,
    })

    return parseResult(res, 'cancelItinerary') as GQL.Itinerary
  },
  assign: async (data: GQL.AssignItineraryInput) => {
    const res = await apolloClient.mutate({
      mutation: AssignItineraryMutation,
      variables: { data },
    })

    return parseResult(res, 'assignItinerary') as GQL.Itinerary
  },
  unassign: async (data: GQL.UnassignItineraryMutationVariables) => {
    const res = await apolloClient.mutate({
      mutation: UnassignItineraryMutation,
      variables: data,
    })

    return parseResult(res, 'unAssignItinerary') as GQL.Itinerary
  },
}

export default api
