import React from 'react'
import { SvgIconProps } from '@mui/material'
import { TransportRequest, TransportRequestAction } from 'types'

import { getActionIcon } from './icons'
import { getLabel } from './labels'
import { getActionType, ActionType } from './actionTypes'
import { isActionAvailable, isActionAvailableOnAny } from './predicates'

export { useTransportRequestActions } from './hooks'
export type { ActionType } from './actionTypes'

type LabelParams = {
  plural?: boolean
  options?: any
  nullIfUnavailable?: boolean
}

export type TransportRequestActionOptions = {
  getIcon: (props?: SvgIconProps) => React.ReactNode
  getLabel: (params?: LabelParams) => string | null
  getType: () => ActionType
  isAvailable(): boolean
  isAvailableOnAny(): boolean
}

export type TransportRequestActionsOptions = {
  [key in TransportRequestAction]: TransportRequestActionOptions
} & {
  getAvailableActions: () => TransportRequestAction[]
}

export const availableActions = <T extends TransportRequest>(request: T | T[]) =>
  Object.values(TransportRequestAction).reduce((acc, action) => {
    if (isActionAvailable(request, action as TransportRequestAction)) {
      return acc.concat(action as TransportRequestAction)
    }
    return acc
  }, [] as TransportRequestAction[])

export const getTransportRequestAction = <T extends TransportRequest>(
  request: T | T[],
  action: TransportRequestAction
): TransportRequestActionOptions => {
  return {
    getIcon: (props?: SvgIconProps) => getActionIcon(action, props),
    getLabel: (params) => {
      if (params?.nullIfUnavailable && !isActionAvailable(request, action)) return null
      return getLabel(action, params?.plural || Array.isArray(request), params?.options)
    },
    getType: () => getActionType(action),
    isAvailable: () => isActionAvailable(request, action),
    isAvailableOnAny: () => isActionAvailableOnAny(request, action),
  }
}

export const getTransportRequestActions = <T extends TransportRequest>(
  request: T | T[]
): TransportRequestActionsOptions => {
  return {
    [TransportRequestAction.Accept]: getTransportRequestAction(
      request,
      TransportRequestAction.Accept
    ),
    [TransportRequestAction.Reject]: getTransportRequestAction(
      request,
      TransportRequestAction.Reject
    ),
    getAvailableActions: () => availableActions(request),
  }
}
