import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { List } from '@mui/material'

import { ItineraryStop as ItineraryStopEntity } from 'types'
import { setItineraryStopOrder } from 'store/itineraries/actions'
import ItineraryStopItem from '../ItineraryStopItem'

const ascending = (stops: ItineraryStopEntity[]) => stops.slice().sort((a, b) => a.order - b.order)

const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export type ItineraryStopsProps = {
  itineraryId: string
  stops: ItineraryStopEntity[]
}

const ItineraryStopList = ({ itineraryId: iId, stops: unorderedStops }: ItineraryStopsProps) => {
  const dispatch = useDispatch()

  const [stops, setStops] = useState(ascending(unorderedStops))
  const itineraryId = useMemo(() => iId, [iId])

  useEffect(() => {
    setStops(ascending(unorderedStops))
  }, [unorderedStops])

  const handleStopOrderChange = (newStops: ItineraryStopEntity[]) => {
    setStops(newStops)
    const newStopOrder = newStops.map((s, index) => ({ stopId: s.id, newOrder: index }))

    dispatch(
      setItineraryStopOrder({
        itineraryId,
        stops: newStopOrder,
      })
    )
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return // dropped outside droppable content

    const newStops = reorder(stops, result.source.index, result.destination.index)

    const equal =
      JSON.stringify(newStops.map((s) => s.id)) ===
      JSON.stringify(ascending(stops).map((s) => s.id))

    if (!equal) handleStopOrderChange(newStops)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {stops.map((stop, index) => (
              <ItineraryStopItem
                stop={stop}
                key={stop.id}
                index={index}
                itineraryId={itineraryId}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ItineraryStopList
