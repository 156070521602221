import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'

import { selectProfile } from 'store/user/selectors'
import InviteUserModal from 'components/features/user/InviteUserModal'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import UserList from 'components/features/user/UserList'
import Title from 'components/ui/Title'

import { Box, Button } from '@mui/material'

const Users = () => {
  const profile = useSelector(selectProfile)
  const [modalOpen, setModalOpen] = useState(false)

  const onInvite = (success: boolean) => {
    if (success) {
      setModalOpen(false)
    }
  }

  return (
    <>
      <Title>
        <Translate value="UI.Users.Title" />
      </Title>
      <InviteUserModal
        onClose={() => setModalOpen(false)}
        onInvite={onInvite}
        isVisible={modalOpen}
      />
      <Box mb={4} mt={-4}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setModalOpen(true)}
          startIcon={<PersonAddIcon />}
        >
          <Translate value="UI.Users.Invite.InviteUser" />
        </Button>
      </Box>
      <UserList currentUserId={profile.id} />
    </>
  )
}

export default Users
