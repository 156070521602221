import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { selectAllUsers } from 'store/users/selectors'
import { entityAdapter } from './reducer'

export const {
  selectById: selectShipmentById,
  selectIds: selectShipmentIds,
  selectEntities: selectShipmentEntities,
  selectAll: selectAllShipments,
  selectTotal: selectTotalShipments,
} = entityAdapter.getSelectors((state: RootState) => state.shipments)

export const selectPollData = (state: RootState) => state.shipments.poll.data
export const selectNewDataAvailable = (state: RootState) => state.shipments.poll.newDataAvailable

export const selectDisplayableShipments = createSelector(
  selectAllShipments,
  selectAllUsers,
  (shipments, users) => {
    return shipments.map((shipment) => ({
      ...shipment,
      user: users.find((user) => user.id === shipment.assignedUserId),
    }))
  }
)
