import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import { Translate } from 'react-redux-i18n'

import { selectUserById } from 'store/users/selectors'
import { RootState } from 'store'
import LoadingBox from 'components/ui/LoadingBox'
import Title from 'components/ui/Title'
import useShipment from '../shared/useShipment'

import Address from './Address'
import FloatingData from './FloatingData'
import PackageItem from './PackageItem'
import ShipmentActions from './ShipmentActions'
import ShipmentService from './ShipmentService'
import { useParams } from 'react-router-dom'
import ShipmentEventList from './ShipmentEventList'

const ShipmentDetails = () => {
  const { id } = useParams()
  const [shipment] = useShipment(id)

  const assignedUser = useSelector((state: RootState) => {
    if (shipment === undefined) return undefined
    return selectUserById(state, shipment?.assignedUserId ?? '')
  })

  if (!shipment) {
    return (
      <LoadingBox>
        <Translate value="UI.Shipments.Details.Loading" />
      </LoadingBox>
    )
  }

  return (
    <>
      <Title id={shipment.id}>
        <Translate value="Entities.Shipment.Singular" />{' '}
        <span style={{ fontWeight: 'bold' }}>{shipment.shipmentNumber}</span>
      </Title>
      <FloatingData shipment={shipment} />
      <ShipmentActions shipment={shipment} user={assignedUser} />
      <Box marginY={4}>
        <Typography variant="h5">
          <Translate value="UI.Shipments.Details.OriginDestination" />
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Address address={shipment.sourceAddress} />
        <Address address={shipment.destinationAddress} isDestination />
      </Grid>
      {shipment.shipmentServices?.length > 0 && (
        <>
          <Box marginY={4}>
            <Typography variant="h5">
              <Translate value="UI.Shipments.Details.Services" />
            </Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" margin={-2}>
            {shipment.shipmentServices.map((service) => (
              <ShipmentService service={service} key={`${service.carrierServiceCode}`} />
            ))}
          </Box>
        </>
      )}
      <Box marginY={4}>
        <Typography variant="h5">
          <Translate value="UI.Shipments.Details.Contents" />
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" margin={-2}>
        {shipment.packageItems.map((item) => (
          <PackageItem item={item} key={item.packageNumber} />
        ))}
      </Box>
      <Box marginY={4}>
        <Typography variant="h5">
          <Translate value="UI.Shipments.Details.Events.Title" />
        </Typography>
      </Box>
      <ShipmentEventList data={shipment.events} />
    </>
  )
}

export default ShipmentDetails
