import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { APIStatus, IolConnection } from 'types'
import { StoreNames } from 'types/Misc'
import { clearConnections, getConnections } from './actions'

type InitialState = EntityState<IolConnection> & {
  status: APIStatus
  error: string | undefined
}

export const entityAdapter = createEntityAdapter<IolConnection>({
  selectId: (carrierProduct) => carrierProduct.id,
})

const connectionsSlice = createSlice({
  name: StoreNames.connections,
  initialState: entityAdapter.getInitialState({
    status: APIStatus.Idle,
    error: undefined,
  }) as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnections.pending, (state) => {
      state.status = APIStatus.Loading
    })
    builder.addCase(getConnections.success, (state, { payload }) => {
      state.status = APIStatus.Succeeded
      entityAdapter.setAll(state, payload)
    })
    builder.addCase(getConnections.failure, (state, { payload }) => {
      state.status = APIStatus.Failed
      state.error = payload
    })
    builder.addCase(clearConnections, (state) => {
      state.status = APIStatus.Idle
      state.error = undefined
      entityAdapter.setAll(state, [])
    })
  },
})

export default connectionsSlice
