import { createAction } from '@reduxjs/toolkit'
import { UserChangePasswordParams } from 'api/types'
import { createActionWithCallback } from 'store/utils/actionCreators'
import { StoreNames, User } from 'types'
import { UserSettings } from './reducer'

const name = StoreNames.user

export const getUser = createAction(`${name}_GET`)
export const setUser = createAction<User>(`${name}_SET`)
export const clearUser = createAction(`${name}_CLEAR`)
export const setDarkMode = createAction<boolean>(`${name}_SET_DARK_MODE`)
export const setSettings = createAction<UserSettings>(`${name}_SET_SETTINGS`)
export const changePassword = createActionWithCallback<UserChangePasswordParams>(
  `${name}_CHANGE_PASSWORD`
)
