import { Grid } from '@mui/material'
import styled from 'styled-components'

export const FloatingDataDisplay = styled(Grid)`
  margin-bottom: ${(p) => p.theme.spacing(4)};
  font-size: 1.1rem;
  margin-right: 2rem;
`

export const FloatingDataHeader = styled.div`
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: ${(p) => p.theme.spacing(1)};
`

export const NaNPlaceholder = styled.div`
  font-style: italic;
`

export const FloatingDataContent = styled.div``
