import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectDisplayableShipments } from 'store/shipments/selectors'
import { DisplayableShipment } from 'types'

import isEligible from 'helpers/isEligible'

const useItineraryEligibleShipments = (itineraryId?: string): DisplayableShipment[] => {
  const shipments = useSelector(selectDisplayableShipments)
  const [eligible, setEligible] = useState<DisplayableShipment[]>([])

  useEffect(() => {
    setEligible(shipments.filter((s) => isEligible(s, itineraryId)))
  }, [shipments, itineraryId])

  return eligible
}

export default useItineraryEligibleShipments
