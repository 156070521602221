import { graphql } from 'gql/gql'

/**
 * Create single itinerary
 */

export const CreateItineraryMutation = graphql(`
  mutation CreateItinerary($data: CreateItineraryInput!) {
    createItinerary(data: $data) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Add shipments to itinerary
 */

export const AddShipmentsToItineraryMutation = graphql(`
  mutation AddShipmentsToItinerary($data: ModifyItineraryInput!) {
    addShipmentsToItinerary(data: $data) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Remove shipments from itinerary
 */

export const RemoveShipmentsFromItineraryMutation = graphql(`
  mutation RemoveShipmentsFromItinerary($data: ModifyItineraryInput!) {
    removeShipmentsFromItinerary(data: $data) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Reorder itinerary stop - give it a new order
 */

export const ReorderItineraryStopMutation = graphql(`
  mutation ReorderItineraryStop($data: ReorderStopInput!) {
    reorderStop(data: $data) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Set itinerary stop order
 */

export const SetItineraryStopOrderMutation = graphql(`
  mutation SetItineraryStopOrder($data: SetStopOrderInput!) {
    setStopOrder(data: $data) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Cancel itinerary
 */

export const CancelItineraryMutation = graphql(`
  mutation CancelItinerary($itineraryId: String!) {
    cancelItinerary(itineraryId: $itineraryId) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Assign itinerary
 */

export const AssignItineraryMutation = graphql(`
  mutation AssignItinerary($data: AssignItineraryInput!) {
    assignItinerary(data: $data) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Unassign itinerary
 */

export const UnassignItineraryMutation = graphql(`
  mutation UnassignItinerary($itineraryId: String!) {
    unAssignItinerary(itineraryId: $itineraryId) {
      ...ItineraryFragment
    }
  }
`)
