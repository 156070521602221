import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import {
  DisplayData,
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUISortOptions,
} from 'mui-datatables'
import ConfigPreserver, { useConfigPreserver, TableConfig } from 'services/ConfigPreserver'
import { ToolbarWrapper, StyledMUIDataTable } from './styled'
import getTranslation from './translation'
import { useSelector } from 'react-redux'
import getLocale from 'store/i18n/selectors'
import { usePreserver } from 'services/ConfigPreserver/PreserverContext'

export const SORT_ORDER: {
  ASCENDING: MUISortOptions['direction']
  DESCENDING: MUISortOptions['direction']
} = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
}

// DataGrid/DataTable/Table based on mui-datatables
// See https://github.com/gregnb/mui-datatables for advanced docs

const defaultOptions: MUIDataTableOptions = {
  elevation: 0,
  download: false,
  print: false,
  pagination: true,
  draggableColumns: {
    enabled: true,
  },
  selectableRows: 'none',
  customToolbar: () => <></>,
}

/**
 * The MUIDataTableCurrentData is wrongly typed. It's true shape is { index: number, data: data[] }
 */
export const getDataIndex = (tableMeta: MUIDataTableMeta): number => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { index } = tableMeta.currentTableData[tableMeta.rowIndex]

  return index
}

const loadingIndicator = (
  <Box p={2}>
    <CircularProgress />
  </Box>
)

/**
 *  Temporary table options used while data is loading for table.
 *  Same options as usual except it shows a loading indicator.
 */
const setLoading =
  (loading: boolean) =>
  (current: MUIDataTableOptions): MUIDataTableOptions => ({
    ...current,
    textLabels: {
      ...current.textLabels,
      body: {
        ...current.textLabels?.body,
        noMatch: loading ? loadingIndicator : getTranslation().body.noMatch,
      },
    },
  })

type DataGridProps<T extends Record<string, unknown>> = {
  name: string
  data: T[]
  columns: MUIDataTableColumn[]
  actions?: (data: DisplayData, selectedIndices: number[]) => React.ReactNode
  toolbar?: (data: { displayData: DisplayData }) => React.ReactNode
  options?: MUIDataTableOptions
  loading: boolean
  defaults?: TableConfig
}

const addOnSelectToolbar = (
  options: MUIDataTableOptions,
  actions: (data: DisplayData, selectedIndices: number[]) => React.ReactNode
): MUIDataTableOptions => ({
  ...options,
  selectableRows: 'multiple',
  customToolbarSelect: (
    selectedRows: {
      data: Array<{ index: number; dataIndex: number }>
      lookup: { [key: number]: boolean }
    },
    displayData: DisplayData
  ) => {
    const selectedIndexes = selectedRows.data.map(
      (selection: { index: number; dataIndex: number }) => selection.dataIndex
    )
    return <ToolbarWrapper>{actions(displayData, selectedIndexes)}</ToolbarWrapper>
  },
})

const DataGrid = <T extends Record<string, unknown>>(props: DataGridProps<T>) => {
  // Quick hack for Kontorsspecial profile feature
  // Old approach is the hook preserver, but profiles need access to the preserver through context
  // Here, we call both hooks and check wether a preserver is provided through context.

  const contextPreserver = usePreserver()
  const hookPreserver = useConfigPreserver(
    props.name,
    props.columns.map((col) => col.name),
    {
      showColumns: props.columns
        .filter((col) => col.options?.display !== false)
        .map((col) => col.name),
      ...props.defaults,
    }
  )

  const preserver = contextPreserver instanceof ConfigPreserver ? contextPreserver : hookPreserver

  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [options, setOptions] = useState<MUIDataTableOptions>({
    ...preserver.apply(defaultOptions),
    ...props.options,
    textLabels: getTranslation(),
    customToolbar: props.toolbar,
    onRowSelectionChange: (d, all, selected) => {
      setRowsSelected(selected ?? [])
      props.options?.onRowSelectionChange?.(d, all, selected)
    },
  })

  // use preserver config on poll updates
  useEffect(() => {
    setOptions((o) => preserver.apply(o))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  // update options if row selection changes
  useEffect(() => {
    setOptions((o) => ({ ...o, rowsSelected }))
  }, [rowsSelected])

  // update row selection if passed from parent
  useEffect(() => {
    setRowsSelected(props.options?.rowsSelected || [])
  }, [props.options?.rowsSelected])

  const { actions } = props

  // add on select toolbar if actions are passed from parent
  useEffect(() => {
    if (actions) {
      setOptions((o) => addOnSelectToolbar(o, actions))
    }
  }, [actions, props])

  const locale = useSelector(getLocale)

  // update options if locale changes
  useEffect(() => {
    setOptions((o) => ({ ...o, textLabels: getTranslation() }))
  }, [locale])

  const { loading } = props

  // update options if loading
  useEffect(() => {
    setOptions(setLoading(loading))
  }, [loading])

  const columns = preserver.sync(props.columns)

  return <StyledMUIDataTable title="" data={props.data} columns={columns} options={options} />
}

export default DataGrid
