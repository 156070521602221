import React from 'react'
import { Translate } from 'react-redux-i18n'
import { ItineraryStatus } from 'types'
import { StatusChip } from './styled'

export type ItineraryStatusChipProps = {
  status: ItineraryStatus
}

const ItineraryStatusChip = ({ status }: ItineraryStatusChipProps) => {
  return (
    <StatusChip
      variant="outlined"
      size="small"
      label={<Translate value={`Entities.Shipment.Status.${status}`} />}
      status={status}
    />
  )
}

export default ItineraryStatusChip
