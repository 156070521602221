import { put, call, takeLatest } from 'redux-saga/effects'
import { I18n } from 'react-redux-i18n'
import { nanoid } from '@reduxjs/toolkit'

import api from 'api'
import { User } from 'types'
import { newRequest } from 'store/snackbars/actions'
import { changePassword, getUser, setUser } from './actions'

function* changePasswordHandler({ payload }: ReturnType<typeof changePassword>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Settings.ChangePassword.Loading'),
    success: I18n.t('UI.Settings.ChangePassword.Success'),
    failure: I18n.t('UI.Settings.ChangePassword.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    yield call(api.User.changePassword, payload.data)
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    payload?.callback?.(true)
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
    payload?.callback?.(false)
  }
}

function* getUserHandler() {
  let data: User

  try {
    data = yield call(api.Auth.getUser)
    yield put(setUser(data))
  } catch (error: any) {
    // This should not happen if the getUser action is
    // dispatched with a token set in the API. What do?
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export default [
  takeLatest(changePassword.type, changePasswordHandler),
  takeLatest(getUser.type, getUserHandler),
]
