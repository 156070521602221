import React from 'react'
import {
  AssignmentInd as AssignmentIndIcon,
  Cancel as CancelIcon,
  AssignmentLate as AssigmentLateIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { ShipmentAction } from 'types/Shipments'

// ----- Assign -----

export const assignIcon = (props?: SvgIconProps): React.ReactNode => (
  <AssignmentIndIcon {...props} />
)

// ----- Unassign -----

export const unassignIcon = (props?: SvgIconProps): React.ReactNode => (
  <AssigmentLateIcon {...props} />
)

// ----- Pickup -----

export const pickupIcon = (props?: SvgIconProps): React.ReactNode => <ArrowUpwardIcon {...props} />

// ----- Cancel -----

export const cancelIcon = (props?: SvgIconProps): React.ReactNode => <CancelIcon {...props} />

// ----- Generic -----

export const getActionIcon = (action: ShipmentAction, props?: SvgIconProps): React.ReactNode => {
  switch (action) {
    case ShipmentAction.Assign:
      return assignIcon(props)
    case ShipmentAction.Unassign:
      return unassignIcon(props)
    case ShipmentAction.Pickup:
      return pickupIcon(props)
    case ShipmentAction.Cancel:
      return cancelIcon(props)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
