import {
  ShipmentStatus,
  ShipmentEventType,
  ItineraryStatus,
  TransportRequestStatus,
  IolConnectionStatus,
} from 'types'

export const shipment = {
  status: {
    [ShipmentStatus.Available]: '#00c853',
    [ShipmentStatus.Reserved]: '#ff9100',
    [ShipmentStatus.Transit]: '#6c0dd9',
    [ShipmentStatus.Delivered]: '#03a9f4',
    [ShipmentStatus.OnHold]: '#616161',
    [ShipmentStatus.Cancelled]: '#e32020', //#616161
  },
}

export const itinerary = {
  status: {
    [ItineraryStatus.Created]: '#025dd4',
    [ItineraryStatus.Assigned]: '#ff9100',
    [ItineraryStatus.Completed]: '#00c853',
    [ItineraryStatus.Cancelled]: '#e32020',
    [ItineraryStatus.OnHold]: '#616161',
    [ItineraryStatus.InProgress]: '#6c0dd9',
  },
}

export const transportRequest = {
  status: {
    [TransportRequestStatus.Accepted]: '#00c853',
    [TransportRequestStatus.Pending]: '#ff9100',
    [TransportRequestStatus.Rejected]: '#e32020',
  },
}

export const connection = {
  status: {
    [IolConnectionStatus.Authorized]: '#00c853',
    [IolConnectionStatus.Pending]: '#ff9100',
    [IolConnectionStatus.Denied]: '#e32020',
    [IolConnectionStatus.Revoked]: '#616161',
  },
}

export const event = {
  eventType: {
    [ShipmentEventType.Assign]: '#025dd4',
    [ShipmentEventType.Unassign]: '#ff9100',
    [ShipmentEventType.Deliver]: '#00c853',
    [ShipmentEventType.Cancel]: '#e32020',
    [ShipmentEventType.Pickup]: '#00c853',
    [ShipmentEventType.PutOnHold]: '#ff9100',
  },
}
