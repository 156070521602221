import React from 'react'
import { Button, Tooltip } from '@mui/material'
import { Replay } from '@mui/icons-material'
import { Translate } from 'react-redux-i18n'

import { useDispatch, useSelector } from 'react-redux'
import { selectNewDataAvailable } from 'store/shipments/selectors'
import { applyPollData } from 'store/shipments/actions'
import { useSettings } from 'helpers/hooks'

const PollIndicator = () => {
  const dispatch = useDispatch()
  const newDataAvailable = useSelector(selectNewDataAvailable)
  const [settings] = useSettings()

  const handleOnClick = () => {
    dispatch(applyPollData())
  }

  if (settings.autoUpdate || !newDataAvailable) return <></>

  return (
    <Tooltip title={<Translate value="UI.Settings.NewDataAvailable" />}>
      <Button
        onClick={handleOnClick}
        color={newDataAvailable ? 'error' : 'success'}
        disabled={!newDataAvailable}
      >
        <Replay />
      </Button>
    </Tooltip>
  )
}

export default PollIndicator
