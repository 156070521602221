import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import ReloadIcon from '@mui/icons-material/Replay'
import SaveIcon from '@mui/icons-material/SaveAs'
import { DisplayData, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables'
import { RootState } from 'store'
import { APIStatus, ShipmentAction, DisplayableShipment } from 'types'
import { getShipments, pickupShipment, unassignShipment } from 'store/shipments/actions'
import { useGetOnLoad } from 'helpers/hooks'
import { selectAllUsers } from 'store/users/selectors'
import { getGlobalData } from 'store/root'
import { getUsers } from 'store/users/actions'
import DataGrid, { getDataIndex, SORT_ORDER } from 'components/features/data/DataGrid'
import ActionButton from 'components/features/data/DataGrid/ActionButton'
import CancelShipmentDialog from 'components/features/shipments/CancelShipmentDialog'
import AssignUserDialog from 'components/features/shipments/AssignUserDialog'
import SaveConfigDialog from 'components/features/misc/SaveConfigDialog'
import flattenObject from 'helpers/flattenObject'

import getShipmentColumns, { ShipmentColumns } from './columns'
import ActionMenu from '../ActionMenu'
import getLocale from 'store/i18n/selectors'
import { CustomColumnOptions } from 'helpers/types'
import { PreserverProvider } from 'services/ConfigPreserver/PreserverContext'

const isLoading = (user: APIStatus, shipment: APIStatus): boolean => {
  if (user === APIStatus.Loading) {
    return true
  }
  return shipment === APIStatus.Loading ?? false
}

export type ShipmentsListProps = {
  name: string
  data: DisplayableShipment[]
  columnOptions?: CustomColumnOptions
}

const ShipmentsList = ({ name, data, columnOptions }: ShipmentsListProps) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState<MUIDataTableOptions>({})

  const userApiStatus = useSelector((state: RootState) => state.users.status)
  const users = useSelector(selectAllUsers)
  const shipmentsApiStatus = useSelector((state: RootState) => state.shipments.status)
  const flattenedData = useMemo(() => data.map(flattenObject), [data]) // Mui-Datatables prefers flattened data

  const [dialogShipments, setDialogShipments] = useState<DisplayableShipment[]>([])
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)

  const handlePerformedAction = (reloadData: boolean) => {
    if (reloadData) {
      setOptions({ rowsSelected: [] })
    }
  }

  useGetOnLoad(
    shipmentsApiStatus,
    getShipments,
    <Translate value="UI.Shipments.Table.Errors.LoadFailed" />
  )

  useGetOnLoad(userApiStatus, getUsers, <Translate value="UI.Users.Errors.LoadFailed" />)

  const handleShipmentAction = useCallback(
    (action: ShipmentAction, shipment: DisplayableShipment | DisplayableShipment[]) => {
      const shipments = Array.isArray(shipment) ? shipment : [shipment]

      switch (action) {
        case ShipmentAction.Assign:
          setDialogShipments(shipments)
          setIsAssignDialogOpen(true)
          break
        case ShipmentAction.Unassign:
          handlePerformedAction(true)
          dispatch(unassignShipment(shipments))
          break
        case ShipmentAction.Pickup:
          dispatch(pickupShipment({ data: shipments }))
          break
        case ShipmentAction.Cancel:
          setDialogShipments(shipments)
          setIsCancelDialogOpen(true)
          break
        default:
          break
      }
    },
    [dispatch]
  )

  const actions = (_displayData: DisplayData, selectedIndices: number[]) => {
    const shipments = selectedIndices.map((i) => data[i])

    return <ActionMenu shipment={shipments} handleShipmentAction={handleShipmentAction} />
  }

  const rowActions = useCallback(
    (tableMeta: MUIDataTableMeta) => {
      const shipment = data[getDataIndex(tableMeta)]

      return <ActionMenu shipment={shipment} handleShipmentAction={handleShipmentAction} />
    },
    [data, handleShipmentAction]
  )

  const locale = useSelector(getLocale)

  const columns = useMemo(
    () => getShipmentColumns({ data, users, actions: rowActions, columnOptions, locale }),
    [columnOptions, data, rowActions, users, locale]
  )

  const defaults = {
    sort: {
      name: ShipmentColumns.Date,
      direction: SORT_ORDER.DESCENDING,
    },
  }

  const toolbar = () => (
    <>
      <ActionButton
        icon={<SaveIcon />}
        label={<Translate value="UI.Table.Reload" />}
        onClick={() => setIsSaveDialogOpen(true)}
      />
      <ActionButton
        icon={<ReloadIcon />}
        label={<Translate value="UI.Table.Reload" />}
        onClick={() => dispatch(getGlobalData())}
      />
    </>
  )

  return (
    <PreserverProvider name={name} columns={columns} defaults={defaults}>
      {isAssignDialogOpen && (
        <AssignUserDialog
          shipments={dialogShipments}
          onClose={(wasActionPerformed) => {
            handlePerformedAction(wasActionPerformed)
            setIsAssignDialogOpen(false)
          }}
          users={users}
        />
      )}
      {isCancelDialogOpen && (
        <CancelShipmentDialog
          shipments={dialogShipments}
          onClose={(wasActionPerformed) => {
            handlePerformedAction(wasActionPerformed)
            setIsCancelDialogOpen(false)
          }}
        />
      )}
      {isSaveDialogOpen && (
        <SaveConfigDialog
          onClose={() => {
            setIsSaveDialogOpen(false)
          }}
        />
      )}
      <DataGrid
        name={name}
        data={flattenedData}
        columns={columns ?? []}
        actions={actions}
        toolbar={toolbar}
        loading={isLoading(userApiStatus, shipmentsApiStatus)}
        options={options}
        defaults={defaults}
      />
    </PreserverProvider>
  )
}

export default ShipmentsList
