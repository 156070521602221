import { ShipmentAction } from 'types/Shipments'
import { I18n } from 'react-redux-i18n'

// ----- Assign -----

export const assignLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Shipments.Table.AssignShipments', options)
    : I18n.t('UI.Shipments.Table.AssignShipment', options)

// ----- Unassign -----

export const unassignLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Shipments.Table.UnassignShipments', options)
    : I18n.t('UI.Shipments.Table.UnassignShipment', options)

// ----- Pickup -----

export const pickupLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Shipments.Table.PickupShipments', options)
    : I18n.t('UI.Shipments.Table.PickupShipment', options)

// ----- Cancel -----

export const cancelLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Shipments.Table.CancelShipments', options)
    : I18n.t('UI.Shipments.Table.CancelShipment', options)

// ----- Generic -----

export const getLabel = (action: ShipmentAction, plural?: boolean, options?: any) => {
  switch (action) {
    case ShipmentAction.Assign:
      return assignLabel(plural, options)
    case ShipmentAction.Unassign:
      return unassignLabel(plural, options)
    case ShipmentAction.Pickup:
      return pickupLabel(plural, options)
    case ShipmentAction.Cancel:
      return cancelLabel(plural, options)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
