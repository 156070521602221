import React from 'react'
import { DoneOutlined, Cancel } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { TransportRequestAction } from 'types'

// ----- Accept -----

export const acceptIcon = (props?: SvgIconProps): React.ReactNode => <DoneOutlined {...props} />

// ----- Reject -----

export const rejectIcon = (props?: SvgIconProps): React.ReactNode => <Cancel {...props} />

// ----- Generic -----

export const getActionIcon = (
  action: TransportRequestAction,
  props?: SvgIconProps
): React.ReactNode => {
  switch (action) {
    case TransportRequestAction.Accept:
      return acceptIcon(props)
    case TransportRequestAction.Reject:
      return rejectIcon(props)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
