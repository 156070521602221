import { graphql } from 'gql/gql'

/**
 * Accept transport request
 */

export const AcceptTransportRequestMutation = graphql(`
  mutation AcceptTransportRequest($data: AcceptShipmentRequestInput!) {
    acceptShipmentRequest(data: $data) {
      ...TransportRequestFragment
    }
  }
`)

export const RejectTransportRequestMutation = graphql(`
  mutation RejectTransportRequest($data: RejectShipmentRequestInput!) {
    rejectShipmentRequest(data: $data) {
      ...TransportRequestFragment
    }
  }
`)
