import styled from 'styled-components'
import { TextField } from '@mui/material'

const defaultInputProps: any = {
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
}

export const InputField = styled(TextField).attrs(defaultInputProps)``
