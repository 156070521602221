import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { Translate } from 'react-redux-i18n'
import { ShipmentService as ShipmentServiceType } from 'types/Shipments'
import { Field, Label } from '../styled'
import { PotentiallyLast } from './styled'

type ShipmentServiceProps = {
  service: ShipmentServiceType
}

const ShipmentService = ({ service }: ShipmentServiceProps) => (
  <Box margin={2}>
    <Paper>
      <Box padding={2}>
        <Grid container direction="column" spacing={1}>
          <PotentiallyLast container spacing={1} $last>
            <Field as={Grid} item>
              <Label>
                <Translate value="UI.Shipments.Details.Service.ServiceName" />
              </Label>
              {service.serviceName}
            </Field>
            <Field as={Grid} item>
              <Label>
                <Translate value="UI.Shipments.Details.Service.Description" />
              </Label>
              <span>{service.description}</span>
            </Field>
          </PotentiallyLast>
        </Grid>
      </Box>
    </Paper>
  </Box>
)

export default ShipmentService
