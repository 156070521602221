import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import APIStatus from 'types/Status'
import { Itinerary } from 'types'
import { StoreNames } from 'types/Misc'
import { getItineraries, clearItineraries, setItinerary, setItineraries } from './actions'

type InitialState = EntityState<Itinerary> & {
  status: APIStatus
  error: string | undefined
}

export const entityAdapter = createEntityAdapter<Itinerary>({
  selectId: (itinerary) => itinerary.id,
})

const itinerariesSlice = createSlice({
  name: StoreNames.itineraries,
  initialState: entityAdapter.getInitialState({
    status: APIStatus.Idle,
    error: undefined,
  }) as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getItineraries.pending, (state) => {
      state.status = APIStatus.Loading
    })
    builder.addCase(getItineraries.success, (state, { payload }) => {
      state.status = APIStatus.Succeeded
      entityAdapter.setAll(state, payload)
    })
    builder.addCase(getItineraries.failure, (state, { payload }) => {
      state.status = APIStatus.Failed
      state.error = payload
    })
    builder.addCase(setItinerary, (state, { payload }) => {
      entityAdapter.setOne(state, payload)
    })
    builder.addCase(setItineraries, (state, { payload }) => {
      entityAdapter.setMany(state, payload)
    })
    builder.addCase(clearItineraries, (state) => {
      state.status = APIStatus.Idle
      state.error = undefined
      entityAdapter.setAll(state, [])
    })
  },
})

export default itinerariesSlice
