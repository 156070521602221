import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Shipment } from 'types/Shipments'
import APIStatus from 'types/Status'
import { StoreNames } from 'types/Misc'
import { getShipments, clearShipments, setShipment, setShipments, setPollData } from './actions'

type InitialState = EntityState<Shipment> & {
  status: APIStatus
  error: string | undefined
  poll: {
    data: Shipment[]
    newDataAvailable: boolean
  }
}

export const entityAdapter = createEntityAdapter<Shipment>({
  selectId: (shipment) => shipment.id,
})

const shipmentSlice = createSlice({
  name: StoreNames.shipments,
  initialState: entityAdapter.getInitialState({
    status: APIStatus.Idle,
    error: undefined,
    poll: {
      data: [],
      newDataAvailable: false,
    },
  }) as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShipments.pending, (state) => {
      state.status = APIStatus.Loading
    })
    builder.addCase(getShipments.success, (state, { payload }) => {
      state.status = APIStatus.Succeeded
      entityAdapter.setAll(state, payload)
    })
    builder.addCase(getShipments.failure, (state, { payload }) => {
      state.status = APIStatus.Failed
      state.error = payload
    })
    builder.addCase(setShipment, (state, { payload }) => {
      entityAdapter.setOne(state, payload)
    })
    builder.addCase(setShipments, (state, { payload }) => {
      entityAdapter.setMany(state, payload)
    })
    builder.addCase(setPollData, (state, { payload }) => {
      state.poll.data = payload
      state.poll.newDataAvailable = !!payload.length
    })
    builder.addCase(clearShipments, (state) => {
      state.status = APIStatus.Idle
      state.error = undefined
      entityAdapter.setAll(state, [])
    })
  },
})

export default shipmentSlice
