import * as GQL from 'gql/graphql'

export type ItineraryStopAction = GQL.ItineraryStopAction
export type ItineraryStop = GQL.ItineraryStop
export type Itinerary = GQL.Itinerary

export enum ItineraryAction {
  Cancel = 'cancel',
}

export {
  ItineraryStatus,
  ActionType as ItineraryStopActionType,
  ActionStatus as ItineraryStopActionStatus,
} from 'gql/graphql'

export default Itinerary
