import { graphql } from 'gql/gql'

export const ShipmentCoreFragment = graphql(`
  fragment ShipmentCoreFragment on Shipment {
    id
    iolIdentity
    assignedUserId
    carrierCode
    carrierName
    carrierProductCode
    consignmentId
    status
    shipmentNumber
    shipmentDate
    itineraryIds
  }
`)

export const ExtraShipmentInformationFragment = graphql(`
  fragment ExtraShipmentInformationFragment on Shipment {
    bookingInstruction
    productName
    productCode
    paymentIndicator
    freeTextItems
    deliveryInstruction
    termsOfDeliveryText
    termsOfDeliveryLocation
    termsOfDeliveryCode
  }
`)

export const DangerousGoodsFragment = graphql(`
  fragment DangerousGoodsFragment on DangerousGoodsItem {
    netWeight
    unNumber
    typeOfPackageName
    tunnelRestrictions
    properties {
      dataType
      name
      value
    }
    packingGroup
    numberOfPackages
    netVolume
    marinePollutant
    identificationNameAlt
    identificationName
    id
    grossWeight
    flamingPoint
    classificationCode
  }
`)

export const ShipmentAddressFragment = graphql(`
  fragment ShipmentAddressFragment on ShipmentAddress {
    id
    zipCode
    smsNumber
    province
    properties {
      dataType
      name
      value
    }
    phoneNumber
    name
    iolIdentity
    identifiers {
      name
      value
    }
    geoLocation {
      lat
      lng
    }
    gateCode
    faxNumber
    email
    countryName
    countryCode
    contactPerson
    city
    addressType
    address3
    address2
    address1
  }
`)

export const PackageItemsFragment = graphql(`
  fragment PackageItemsFragment on PackageItem {
    width
    volume
    typeOfPackages
    typeOfPackageCode
    shippingMark
    packageNumber
    loadingmeters
    length
    iolIdentity
    id
    height
    grossWeight
    goodsDescription
    circumference
  }
`)

export const ShipmentEventsFragment = graphql(`
  fragment ShipmentEventsFragment on ShipmentEvent {
    createdAt
    eventType
    geoPoint {
      lat
      lng
    }
    reasonCode
    reasonText
    createdBy
    assignedUserId
  }
`)

export const ShipmentServicesFragment = graphql(`
  fragment ShipmentServicesFragment on ShipmentService {
    serviceName
    serviceCode
    properties {
      dataType
      name
      value
    }
    description
    carrierServiceCode
  }
`)

export const ShipmentPropertiesFragment = graphql(`
  fragment ShipmentPropertiesFragment on NamedProperty {
    dataType
    name
    value
  }
`)

export const ShipmentFragment = graphql(`
  fragment ShipmentFragment on Shipment {
    ...ShipmentCoreFragment
    ...ExtraShipmentInformationFragment
    sourceAddress {
      ...ShipmentAddressFragment
    }
    destinationAddress {
      ...ShipmentAddressFragment
    }
    shipmentAddresses {
      ...ShipmentAddressFragment
    }
    shipmentServices {
      ...ShipmentServicesFragment
    }
    dangerousGoodsItems {
      ...DangerousGoodsFragment
    }
    properties {
      ...ShipmentPropertiesFragment
    }
    packageItems {
      ...PackageItemsFragment
    }
    shipmentIdentifiers {
      value
      name
    }
    events {
      ...ShipmentEventsFragment
    }
  }
`)

/**
 * Get single shipment
 */

export const GetShipmentQuery = graphql(`
  query GetShipment($shipmentId: String!) {
    shipment(id: $shipmentId) {
      ...ShipmentFragment
    }
  }
`)

/**
 * Get multiple shipments
 */

export const GetShipmentsQuery = graphql(`
  query GetShipments($filter: ShipmentFilterInput) {
    shipments(filter: $filter) {
      ...ShipmentFragment
    }
  }
`)
