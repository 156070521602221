import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Translate } from 'react-redux-i18n'
import { AddCircleOutline, CheckCircleOutlined } from '@mui/icons-material'

import { selectAllUsers } from 'store/users/selectors'
import { DisplayableShipment, User } from 'types'

import { useItineraryEligibleShipments, useDisjointShipments } from 'helpers/hooks'
import { createItinerary } from 'store/itineraries/actions'
import ItineraryShipmentList from 'components/features/itineraries/ItineraryShipmentList'
import AddShipmentsModal from 'components/features/itineraries/AddShipmentsModal'
import Title from 'components/ui/Title'
import SelectUser from '../shared/SelectUser'
import SelectDate from '../shared/SelectDate'
import SelectName from '../shared/SelectName'
import { isValidDate } from './util'

export const CREATE_ITINERARY_SHIPMENT_TABLE_CONFIG_NAME = 'CREATE_ITINERARY_SHIPMENT_LIST'

const CreateItinerary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const shipments = useItineraryEligibleShipments()
  const users = useSelector(selectAllUsers)

  const [user, setUser] = useState<User>()
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState<Date>(new Date())

  const [selectedShipments, setSelectedShipments] = useState<DisplayableShipment[]>([])
  const availableShipments = useDisjointShipments(shipments, selectedShipments)

  const [canBeCreated, setCanBeCreated] = useState(false)
  const [isAddShipmentsModalOpen, setIsAddShipmentsModalOpen] = useState(false)

  const handleSelectShipments = useCallback((selected: DisplayableShipment[]) => {
    setSelectedShipments((prevSelected) => [...prevSelected, ...selected])
  }, [])

  const handleRemoveShipments = useCallback(
    (shipment: DisplayableShipment | DisplayableShipment[]) => {
      const toBeRemoved = Array.isArray(shipment) ? shipment : [shipment]
      setSelectedShipments((prevSelected) =>
        prevSelected.filter((s1) => !toBeRemoved.find((s2) => s1.id === s2.id) ?? true)
      )
    },
    []
  )

  // update canBeCreated whenever any of the state variables changes
  useEffect(() => {
    const ok = isValidDate(startDate) && selectedShipments.length > 0
    setCanBeCreated(ok)
  }, [name, selectedShipments.length, startDate, user])

  // called from the saga
  const onCreatedCallback = (success: boolean, id?: string) => {
    if (success) navigate(`/itineraries/${id}`)
  }

  const handleCreateItinerary = () => {
    const newItinerary = {
      ...(name ? { name } : {}),
      startDate: startDate.toISOString(),
      assignedUserId: user?.id,
      shipmentIds: selectedShipments.map((s) => s.id),
    }

    dispatch(
      createItinerary({
        data: newItinerary,
        callback: onCreatedCallback,
      })
    )
  }

  return (
    <>
      <Title>
        <Translate value="UI.Itineraries.Create.Create" />
      </Title>
      <Grid container spacing={2} mb={4}>
        <Grid item lg="auto">
          <SelectUser user={user} users={users} onChange={setUser} />
        </Grid>
        <Grid item lg="auto">
          <SelectDate value={startDate} onChange={setStartDate} />
        </Grid>
        <Grid item xs={12} lg>
          <SelectName value={name} onChange={setName} />
        </Grid>
      </Grid>
      <Box mb={4}>
        <Button
          sx={{ mr: 2 }}
          color="success"
          variant="contained"
          disabled={!canBeCreated}
          startIcon={<CheckCircleOutlined />}
          onClick={() => handleCreateItinerary()}
        >
          <Translate value="UI.Itineraries.Create.Create" />
        </Button>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddCircleOutline />}
          onClick={() => setIsAddShipmentsModalOpen(true)}
        >
          <Translate value="UI.Itineraries.Create.AddShipments" />
        </Button>
      </Box>
      <Box mb={2}>
        <Typography variant="h5">
          <Translate value="UI.Itineraries.Create.Shipments" />
        </Typography>
      </Box>
      <Box mb={4}>
        <ItineraryShipmentList
          name={CREATE_ITINERARY_SHIPMENT_TABLE_CONFIG_NAME}
          data={selectedShipments}
          onRemove={handleRemoveShipments}
        />
      </Box>
      {isAddShipmentsModalOpen && (
        <AddShipmentsModal
          open={isAddShipmentsModalOpen}
          onClose={() => setIsAddShipmentsModalOpen(false)}
          data={availableShipments}
          onSelect={handleSelectShipments}
        />
      )}
    </>
  )
}

export default CreateItinerary
