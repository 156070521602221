import { RootState } from 'store'
import { entityAdapter } from './reducer'

export const {
  selectById: selectTransportRequestById,
  selectIds: selectTransportRequestIds,
  selectEntities: selectTransportRequestEntities,
  selectAll: selectAllTransportRequests,
  selectTotal: selectTotalTransportRequests,
} = entityAdapter.getSelectors((state: RootState) => state.transportRequests)
