import { ItineraryAction } from 'types'
import { I18n } from 'react-redux-i18n'

// ----- Cancel -----

export const cancelLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Itineraries.Table.Cancel.Multiple', options)
    : I18n.t('UI.Itineraries.Table.Cancel.Single', options)

// ----- Generic -----

export const getLabel = (action: ItineraryAction, plural?: boolean, options?: any) => {
  switch (action) {
    case ItineraryAction.Cancel:
      return cancelLabel(plural, options)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
