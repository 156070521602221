import * as GQL from 'gql/graphql'

export type GeoPoint = GQL.Point
export type Perhaps<T> = T | null | undefined

export enum StoreNames {
  root = 'root',
  shipments = 'shipments',
  users = 'users',
  auth = 'auth',
  user = 'user',
  snackbars = 'snackbars',
  itineraries = 'itineraries',
  transportRequests = 'transportRequests',
  carrierProducts = 'carrierProducts',
  connections = 'connections',
  i18n = 'i18n',
}
