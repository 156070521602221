import { useEffect, useMemo } from 'react'
import ConfigPreserver, { TableConfig } from './index'

export const useConfigPreserver = (
  name: string,
  columns: string[],
  defaults?: TableConfig
): ConfigPreserver => {
  const preserver = useMemo(
    () => new ConfigPreserver(name, columns, defaults),
    [name, columns, defaults]
  )

  useEffect(() => {
    if (!preserver.isConfigValid()) {
      preserver.setDefault()
    }
  })

  return preserver
}

export default useConfigPreserver
