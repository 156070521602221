import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { MUIDataTableColumn } from 'mui-datatables'
import { I18n, Translate } from 'react-redux-i18n'

import { DisplayableShipment, ShipmentStatus, User } from 'types'
import ShipmentStatusChip from 'components/features/shipments/ShipmentStatusChip'
import TextLink from 'components/ui/TextLink'
import { SORT_ORDER } from 'components/features/data/DataGrid'
import columnConstructors from 'components/features/data/columnConstructors'
import { dayMonth } from 'helpers/formatDate'
import { commaFormat } from 'helpers/formatString'
import { compareStrings } from 'helpers/compare'

export const UNASSIGNED = 'unassigned'

export const SHIPMENT_STATUS_INDEX = 3
export const SHIPMENT_ID_INDEX = 0

export enum ShipmentColumns {
  Id = 'id',
  Status = 'status',
  Recipient = 'destinationAddress.name',
  DeliveryCity = 'destinationAddress.city',
  FullName = 'user.fullName',
  Number = 'shipmentNumber',
  Shipper = 'sourceAddress.name',
  PickupCity = 'sourceAddress.city',
  Date = 'shipmentDate',
  Actions = 'actions',
}

const getColumns: (data: DisplayableShipment[], users: User[]) => MUIDataTableColumn[] = (
  data,
  users
) => {
  const columns: MUIDataTableColumn[] = [
    columnConstructors.hiddenField(ShipmentColumns.Id),
    {
      label: I18n.t('UI.Shipments.Table.Columns.ShipmentId'),
      name: ShipmentColumns.Number,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.ShipmentId" />,
        customBodyRender: (number, meta) => (
          <TextLink
            color="textSecondary"
            as={Link}
            to={`/shipments/${meta.rowData[SHIPMENT_ID_INDEX]}`}
          >
            {number}
          </TextLink>
        ),
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            (b - a) * (order === SORT_ORDER.DESCENDING ? 1 : -1),
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.AssignedUser'),
      name: ShipmentColumns.FullName,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.AssignedUser" />,
        customBodyRenderLite: (dataIndex) => {
          const { user } = data[dataIndex]
          if (user === undefined) {
            return <Translate value="Entities.Shipment.Unassigned" />
          }
          return (
            <TextLink color="textSecondary" as={Link} to={`/users/${user.id}`}>
              {user.fullName}
            </TextLink>
          )
        },
        customFilterListOptions: {
          render: (name) =>
            name === UNASSIGNED ? <Translate value="Entities.Shipment.Unassigned" /> : name,
        },
        filterOptions: {
          renderValue: (name) =>
            name === UNASSIGNED ? I18n.t('Entities.Shipment.Unassigned') : name,
          names: users.map((user) => user.fullName).concat([UNASSIGNED]),
          logic: (value, filters) => {
            if (filters.length === 0) return false
            if (value === undefined && filters.includes(UNASSIGNED)) return false
            return !filters.includes(value)
          },
        },
        filterType: 'multiselect',
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Column.Status'),
      name: ShipmentColumns.Status,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Column.Status" />,
        customBodyRenderLite: (dataIndex) => {
          const { status } = data[dataIndex]

          return <ShipmentStatusChip status={status} />
        },
        customFilterListOptions: {
          render: (status) => <Translate value={`Entities.Shipment.Status.${status}`} />,
        },
        filterOptions: {
          renderValue: (status) => I18n.t(`Entities.Shipment.Status.${status}`),
          names: Object.values(ShipmentStatus),
        },
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.ShipmentDate'),
      name: ShipmentColumns.Date,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.ShipmentDate" />,
        customBodyRender: dayMonth,
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.Shipper'),
      name: ShipmentColumns.Shipper,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.Shipper" />,
        customBodyRenderLite: (dataIndex) => {
          const { sourceAddress } = data[dataIndex]
          const { name, address1, city } = sourceAddress
          return (
            <>
              <Typography>{name}</Typography>
              <Typography variant="caption">{`${address1}, ${city}`}</Typography>
            </>
          )
        },
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t(`UI.Shipments.Table.Columns.PickupCity`),
      name: ShipmentColumns.PickupCity,
      options: {
        customHeadLabelRender: () => <Translate value={`UI.Shipments.Table.Columns.PickupCity`} />,
        filterType: 'multiselect',
        display: false,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.Recipient'),
      name: ShipmentColumns.Recipient,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.Recipient" />,
        customBodyRenderLite: (dataIndex) => {
          const { destinationAddress } = data[dataIndex]
          const { name, address1, city } = destinationAddress
          return (
            <>
              <Typography>{name}</Typography>
              <Typography variant="caption">{commaFormat(address1, city)}</Typography>
            </>
          )
        },
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t(`UI.Shipments.Table.Columns.DeliveryCity`),
      name: ShipmentColumns.DeliveryCity,
      options: {
        customHeadLabelRender: () => (
          <Translate value={`UI.Shipments.Table.Columns.DeliveryCity`} />
        ),
        filterType: 'multiselect',
        display: false,
      },
    },
  ]

  return columns
}

export default getColumns
