import React from 'react'
import { Link } from 'react-router-dom'
import { I18n, Translate } from 'react-redux-i18n'
import { Dictionary } from '@reduxjs/toolkit'
import { MUIDataTableColumn } from 'mui-datatables'

import { ShipmentEvent, User } from 'types'
import TextLink from 'components/ui/TextLink'
import shortenUUID from 'helpers/shortenUUID'

import ColoredEvent from './styled'

export enum ShipmentEventColumns {
  CreatedAt = 'createdAt',
  EventType = 'eventType',
  CreatedBy = 'createdBy',
  AssignedUserId = 'assignedUserId',
}

const capitalizeFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

const formatTime = (timestamp: string) => {
  const date = new Date(timestamp)
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

  return `${hour}:${min}`
}

const formatDate = (timestamp: string, locale: string) => {
  const date = new Date(timestamp)
  const isToday = new Date().getDate() === date.getDate()

  const month = date.toLocaleString(locale, { month: 'long' })
  const day = isToday
    ? I18n.t('UI.Shipments.Details.Today')
    : date.toLocaleString(locale, { weekday: 'long' })

  return `${capitalizeFirst(day)} ${formatTime(
    timestamp
  )} -  ${date.getUTCDate()} ${month}, ${date.getUTCFullYear()}`
}

const getColumns: (
  data: ShipmentEvent[],
  users: Dictionary<User>,
  locale: string
) => MUIDataTableColumn[] = (data, users, locale) => {
  const columns: MUIDataTableColumn[] = [
    {
      label: I18n.t('UI.Shipments.Events.Table.Columns.CreatedAt'),
      name: ShipmentEventColumns.CreatedAt,
      options: {
        customHeadLabelRender: () => (
          <Translate value="UI.Shipments.Events.Table.Columns.CreatedAt" />
        ),
        customBodyRender: (createdAt) => formatDate(createdAt, locale),
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Shipments.Events.Table.Columns.EventType'),
      name: ShipmentEventColumns.EventType,
      options: {
        customHeadLabelRender: () => (
          <Translate value="UI.Shipments.Events.Table.Columns.EventType" />
        ),
        customBodyRender: (event) => <ColoredEvent $eventType={event}>{event}</ColoredEvent>,
        sort: false,
      },
    },
    {
      label: I18n.t('UI.Shipments.Events.Table.Columns.CreatedBy'),
      name: ShipmentEventColumns.CreatedBy,
      options: {
        customHeadLabelRender: () => (
          <Translate value="UI.Shipments.Events.Table.Columns.CreatedBy" />
        ),
        customBodyRender: (id) => {
          if (!id) return '-'

          const user = users[id]
          const displayString = user?.fullName ?? shortenUUID(id)

          return (
            <TextLink color="textSecondary" as={Link} to={`/users/${id}`}>
              {displayString}
            </TextLink>
          )
        },
        filter: false,
        sort: false,
      },
    },
    {
      label: I18n.t('UI.Shipments.Events.Table.Columns.AssignedUser'),
      name: ShipmentEventColumns.AssignedUserId,
      options: {
        customHeadLabelRender: () => (
          <Translate value="UI.Shipments.Events.Table.Columns.AssignedUser" />
        ),
        customBodyRender: (id) => {
          if (!id) return '-'

          const user = users[id]
          const displayString = user?.fullName ?? shortenUUID(id)

          return (
            <TextLink color="textSecondary" as={Link} to={`/users/${id}`}>
              {displayString}
            </TextLink>
          )
        },
        filter: false,
        sort: false,
      },
    },
  ]

  return columns
}

export default getColumns
