/**
 * Converts a nested object into a flattened one
 */

function flattenObject(obj: Record<string, any>) {
  const toReturn: Record<string, any> = {}

  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const flatObject = flattenObject(obj[key])
      for (const flatKey of Object.keys(flatObject)) {
        toReturn[`${key}.${flatKey}`] = flatObject[flatKey]
      }
    } else {
      toReturn[key] = obj[key]
    }
  }
  return toReturn
}

export default flattenObject
