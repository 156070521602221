import { ShipmentAction } from 'types/Shipments'

export type ActionType = 'default' | 'danger'

export const getActionType = (action: ShipmentAction) => {
  switch (action) {
    case ShipmentAction.Cancel:
      return 'danger'
    default:
      return 'default'
  }
}
