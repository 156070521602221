import React from 'react'
import { Clear } from '@mui/icons-material'

import ActionButton from 'components/features/data/DataGrid/ActionButton'
import { Translate } from 'react-redux-i18n'

type ActionMenuProps = {
  onClick: () => void
  disabled?: boolean
}

const ActionMenu = ({ onClick, disabled }: ActionMenuProps) => {
  return (
    <ActionButton
      icon={<Clear />}
      color="error"
      label={<Translate value="UI.Itineraries.Create.Remove" />}
      onClick={onClick}
      size="large"
      disabled={disabled}
    />
  )
}

export default ActionMenu
