import { Button, ButtonProps } from '@mui/material'
import React, { ReactNode } from 'react'
import { FloatingCircularProgress, OptionalText } from './styled'

// Button which has built-in loading indicator

const LoadingButton = ({
  isLoading,
  label,
  ...props
}: ButtonProps & {
  label: ReactNode
  isLoading: boolean
}) => (
  <Button {...props}>
    <OptionalText isHidden={isLoading}>{label}</OptionalText>
    {isLoading && <FloatingCircularProgress />}
  </Button>
)

export default LoadingButton
