import React, { ReactNode } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

export type IBProps = IconButtonProps & {
  label?: string | ReactNode
  icon: string | ReactNode
}

const ActionButton = ({ label, icon, ...rest }: IBProps) => {
  const button = (
    <IconButton {...rest}>
      <>{icon}</>
    </IconButton>
  )
  return (
    <>
      {(label && (
        <Tooltip title={label}>
          <>
            {button}
            <span />
          </>
        </Tooltip>
      )) ||
        button}
    </>
  )
}

export default ActionButton
