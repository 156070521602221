import React, { ReactNode } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import styled from 'styled-components'

const ActionWrapper = styled.div`
  margin: -12px;
`

/**
 * Field not shown in table. Primarily used to access specific key in data from tableMeta object.
 * @param field
 */
const hiddenField: (field: string) => MUIDataTableColumn = (field) => ({
  name: field,
  options: {
    display: false,
    viewColumns: false,
    sort: false,
    searchable: false,
    filter: false,
  },
})

const actions: (
  element: (tableMeta: MUIDataTableMeta) => ReactNode,
  name: string
) => MUIDataTableColumn = (element, name) => ({
  label: I18n.t('UI.Table.Actions'),
  name,
  options: {
    sort: false,
    filter: false,
    empty: true,
    viewColumns: false,
    customHeadLabelRender: () => <Translate value="UI.Table.Actions" />,
    customBodyRender: (unused, tableMeta) => <ActionWrapper>{element(tableMeta)}</ActionWrapper>,
  },
})

export default {
  actions,
  hiddenField,
}
