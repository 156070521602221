import { Grid } from '@mui/material'
import styled from 'styled-components'

export const Cross = styled.span`
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 0.9em;
`
export type PotentiallyLastProps = {
  $last: boolean
}

export const PotentiallyLast = styled(Grid)<PotentiallyLastProps>`
  ${(p) =>
    p.$last &&
    `
  margin-bottom: -${p.theme.spacing(2)} 
`}
`
