import AuthAPI from './auth'
import ShipmentAPI from './shipment'
import UserAPI from './user'
import ItineraryAPI from './itinerary'
import TransportRequestAPI from './transportRequest'
import CarrierProductAPI from './carrierProduct'
import ConnectionAPI from './connection'

import { setToken } from './client'

export default {
  Auth: AuthAPI,
  Shipment: ShipmentAPI,
  User: UserAPI,
  Itinerary: ItineraryAPI,
  TransportRequest: TransportRequestAPI,
  CarrierProduct: CarrierProductAPI,
  IolConnection: ConnectionAPI,
  setToken,
}
