import React, { useState, useEffect, useCallback } from 'react'
import { Translate } from 'react-redux-i18n'
import { isEqual } from 'lodash'

import api from 'api'
import { RootState } from 'store'
import { APIStatus, Shipment } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { newSnackbar } from 'store/snackbars/actions'
import { selectShipmentById } from 'store/shipments/selectors'

export const useShipment = (
  id: string | undefined
): [Shipment | undefined, (s: Shipment | undefined) => void] => {
  const dispatch = useDispatch()
  const [shipment, setShipment] = useState<Shipment>()

  const storedShipment = useSelector((state: RootState) => selectShipmentById(state, id ?? ''))

  const getShipment = useCallback(
    async (shipmentId: string) => {
      try {
        const s = await api.Shipment.get(shipmentId)
        setShipment(s)
      } catch (error: any) {
        dispatch(
          newSnackbar({
            message: <Translate value="UI.Shipments.Details.Errors.LoadFailed" />,
            status: APIStatus.Failed,
          })
        )
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (storedShipment) {
      setShipment((prevShipment) =>
        isEqual(prevShipment, storedShipment) ? prevShipment : storedShipment
      )
    } else {
      if (id) getShipment(id)
    }
  }, [id, getShipment, storedShipment])

  return [shipment, setShipment]
}

export default useShipment
