import { apolloClient } from 'api/client'
import { OrganizationConnectToIolMutation } from 'api/organization/mutations'
import { parseResult } from 'api/util'
import { CreateConnectionMutation } from './mutations'
import { GetConnectionsQuery } from './queries'

import * as GQL from 'gql/graphql'

const api = {
  getAll: async () => {
    const res = await apolloClient.query({
      query: GetConnectionsQuery,
    })

    return (parseResult(res, 'connections') ?? []) as GQL.IolConnection[]
  },
  createConnection: async (data: GQL.CreateConnectionInput) => {
    const res = await apolloClient.mutate({
      mutation: CreateConnectionMutation,
      variables: { data },
    })

    const payload = parseResult(res, 'createConnection') as GQL.CreateConnectionPayload
    return payload.connection as GQL.IolConnection
  },
  connectToIol: async (data: GQL.ConnectOrganizationToIolInput) => {
    const res = await apolloClient.mutate({
      mutation: OrganizationConnectToIolMutation,
      variables: { data },
    })

    const payload = parseResult(
      res,
      'connectOrganizationToIol'
    ) as GQL.ConnectOrganizationToIolPayload

    return payload.connectionUrl
  },
}

export default api
