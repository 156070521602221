import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItem,
  Divider,
  TextField,
} from '@mui/material'
import { AccountCircle, PersonAddOutlined } from '@mui/icons-material'
import { I18n, Translate } from 'react-redux-i18n'
import { usePreserver } from 'services/ConfigPreserver/PreserverContext'

export type SaveConfigDialogProps = {
  onClose: (() => void) | ((wasActionPerformed: boolean) => void)
}

const SaveConfigDialog = ({ onClose }: SaveConfigDialogProps) => {
  const [selected, setSelected] = useState<string | null>(null)
  const [configName, setConfigName] = useState<string>('')

  const preserver = usePreserver()
  const existing = preserver.getProfileNames()
  const hasProfiles = !!existing.length

  const onLoad = () => {
    if (selected) {
      preserver.loadProfile(selected)
      onClose(true)
    }
  }

  const onSave = () => {
    preserver.saveProfile(configName)
    onClose(true)
  }

  const onRemove = () => {
    if (selected) {
      preserver.removeProfile(selected)
      setSelected(null)
    }
  }

  return (
    <Dialog
      open
      onClose={() => onClose(false)}
      aria-labelledby="assign-user-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="assign-user-dialog-title">
        <Translate value={`UI.ConfigDialog.Title`} />
      </DialogTitle>
      <DialogContent dividers>
        {hasProfiles && (
          <DialogContentText>
            <Translate value={`UI.ConfigDialog.Choose`} />
          </DialogContentText>
        )}
        <List>
          {existing.map((name) => (
            <ListItemButton
              key={name}
              onClick={() => setSelected(name)}
              selected={selected === name}
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} />
            </ListItemButton>
          ))}
          {hasProfiles && <Divider sx={{ marginY: '1em' }} />}
          <DialogContentText>
            <Translate value={`UI.ConfigDialog.Save`} />
          </DialogContentText>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PersonAddOutlined />
              </Avatar>
            </ListItemAvatar>
            <TextField
              fullWidth={true}
              size="small"
              variant="outlined"
              onFocus={() => setSelected(null)}
              value={configName}
              onChange={(e: any) => setConfigName(e.target.value)}
              label={I18n.t('UI.ConfigDialog.Name')}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        {selected && (
          <>
            <Button onClick={onRemove} color="error">
              <Translate value="General.Remove" />
            </Button>
            <Button onClick={onLoad} color="success">
              <Translate value="General.Load" />
            </Button>
          </>
        )}
        {configName && (
          <Button disabled={existing.includes(configName)} onClick={onSave} color="success">
            <Translate value="General.Save" />
          </Button>
        )}
        <Button onClick={() => onClose(false)} color="primary">
          <Translate value="General.Close" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveConfigDialog
