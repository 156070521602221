import styled from 'styled-components'
import MUIDataTable from 'mui-datatables'
import { alpha } from '@mui/material'

export const ToolbarWrapper = styled.div`
  margin-right: 24px;
`

export const StyledMUIDataTable = styled(MUIDataTable)`
  .MuiTableCell-footer {
    border-bottom: none;
  }

  .MuiTableCell-head * {
    font-weight: bold;
  }

  div[class*='MUIDataTableToolbarSelect-root'] {
    background: ${(p) => alpha(p.theme.palette.primary.main, 0.16)};
  }

  th[class*='MUIDataTableSelectCell-fixedLeft'] {
    left: inherit;
    position: inherit;
  }

  td[class*='MUIDataTableSelectCell-fixedLeft'] {
    left: inherit;
    position: inherit;
  }

  .MuiTableCell-root {
    padding: 0.2rem 0.5rem;
    border: none;
    white-space: nowrap;

    &:last-child {
      padding-right: 16px;
    }

    &:first-child {
      padding-left: 16px;
    }
  }
`
