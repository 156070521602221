import { TransportRequestAction } from 'types'
import { I18n } from 'react-redux-i18n'

// ----- Accept -----

export const acceptLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Requests.Table.AcceptRequests', options)
    : I18n.t('UI.Requests.Table.AcceptRequest', options)

// ----- Reject -----

export const rejectLabel = (plural?: boolean, options?: any): string =>
  plural
    ? I18n.t('UI.Requests.Table.RejectRequests', options)
    : I18n.t('UI.Requests.Table.RejectRequest', options)

// ----- Generic -----

export const getLabel = (action: TransportRequestAction, plural?: boolean, options?: any) => {
  switch (action) {
    case TransportRequestAction.Accept:
      return acceptLabel(plural, options)
    case TransportRequestAction.Reject:
      return rejectLabel(plural, options)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
