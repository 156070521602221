import { createEntityAdapter, createSlice, nanoid } from '@reduxjs/toolkit'
import APIStatus from 'types/Status'
import { StoreNames } from 'types/Misc'
import { newRequest, newSnackbar, clearRequests, removeRequest, Request } from './actions'

export const entityAdapter = createEntityAdapter<Request>()

const requestsSlice = createSlice({
  name: StoreNames.snackbars,
  initialState: entityAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newRequest.pending, (state, { payload }) => {
      entityAdapter.addOne(state, {
        id: payload.id,
        status: APIStatus.Loading,
        message: payload.message,
        timestamp: Date.now(),
      })
    })
    builder.addCase(newRequest.success, (state, { payload }) => {
      entityAdapter.updateOne(state, {
        id: payload.id,
        changes: {
          status: APIStatus.Succeeded,
          message: payload.message,
        },
      })
    })
    builder.addCase(newRequest.partialSuccess, (state, { payload }) => {
      entityAdapter.updateOne(state, {
        id: payload.id,
        changes: {
          status: APIStatus.PartiallySucceeded,
          message: payload.message,
        },
      })
    })
    builder.addCase(newRequest.failure, (state, { payload }) => {
      entityAdapter.updateOne(state, {
        id: payload.id,
        changes: {
          status: APIStatus.Failed,
          message: payload.message,
        },
      })
    })
    builder.addCase(newSnackbar, (state, { payload }) => {
      entityAdapter.addOne(state, {
        id: nanoid(),
        status: payload.status,
        message: payload.message,
        timestamp: Date.now(),
      })
    })
    builder.addCase(clearRequests, (state) => {
      entityAdapter.removeAll(state)
    })
    builder.addCase(removeRequest, (state, action) => {
      entityAdapter.removeOne(state, action.payload)
    })
  },
})

export default requestsSlice
