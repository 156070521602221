import { RootState } from 'store'
import { entityAdapter } from './reducer'

export const {
  selectById: selectRequestById,
  selectIds: selectRequestIds,
  selectEntities: selectRequestEntities,
  selectAll: selectAllRequests,
  selectTotal: selectTotalRequests,
} = entityAdapter.getSelectors((state: RootState) => state.snackbars)

export const selectNextSnackbar = (state: RootState) => {
  const requests = selectAllRequests(state)

  if (requests.length === 0) {
    return undefined
  }

  return requests.reduce((current, next) => {
    return current.timestamp <= next.timestamp ? next : current
  }, requests[0])
}
