import React, { useState } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { Content, LayoutWrapper, Container } from './styled'
import Snackbar from 'components/features/misc/Snackbar'
import Header from '../ui/Header'
import Navbar from '../ui/Navbar'

const Layout = () => {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const [isNavCollapsed, setNavCollapsed] = useState(true)

  return (
    <LayoutWrapper>
      <Snackbar />
      <Navbar
        isCollapsed={isNavCollapsed}
        onClose={() => setNavCollapsed(true)}
        isPermanent={isLarge}
      />
      <Content>
        <Header onNavOpen={() => setNavCollapsed(false)} />
        <Container>
          <>
            <Outlet />
            <Box padding={2} />
          </>
        </Container>
      </Content>
    </LayoutWrapper>
  )
}

export default Layout
