import styled from 'styled-components'
import MuiAvatar from '@mui/material/Avatar'

// Generate pseudorandom color based on string as seed
function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const Avatar = styled(MuiAvatar)<{ colorseed: string }>`
  color: ${(p) => p.theme.palette.getContrastText(stringToColor(p.colorseed))};
  background-color: ${(p) => stringToColor(p.colorseed)};
`
export default Avatar
