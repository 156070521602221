import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'

import { setMomentLocale } from 'helpers/formatDate'

import en from './translations/en.json'
import sv from './translations/sv.json'
import pt from './translations/pt.json'

export const LANGUAGE_SETTING_KEY = 'lang'

type LanguageOption = {
  name: string
  code: string
}

// New languages are added here
const translations: Record<LanguageCode, any> = { en, sv, pt }
export const languageOptions: LanguageOption[] = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Svenska',
    code: 'sv',
  },
]

export const supportedLanguages = languageOptions.map((language) => language.code)

export type LanguageCode = typeof supportedLanguages[number]

export const FALLBACK_LANG: LanguageCode = 'en'

const checkIfSupported = (lang: string) => supportedLanguages.includes(lang)

const getLang: () => LanguageCode = () => {
  let lang = window.localStorage.getItem(LANGUAGE_SETTING_KEY)
  if (!lang) {
    ;[lang] = navigator.language.split('-')
    if (!checkIfSupported(lang)) {
      lang = FALLBACK_LANG
    }
    window.localStorage.setItem(LANGUAGE_SETTING_KEY, lang)
    return lang
  }
  if (!checkIfSupported(lang)) {
    lang = FALLBACK_LANG
  }
  return lang
}

export const configureTranslations = (store: any) => {
  syncTranslationWithStore(store)
  const language: LanguageCode = getLang()
  store.dispatch(loadTranslations(translations))
  store.dispatch(setLocale(language))
  setMomentLocale(language)
}
