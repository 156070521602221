import { apolloClient } from 'api/client'
import { parseResult } from 'api/util'
import { AcceptTransportRequestMutation, RejectTransportRequestMutation } from './mutations'

import { GetTransportRequestsQuery } from './queries'

import * as GQL from 'gql/graphql'

const api = {
  getAll: async () => {
    const res = await apolloClient.query({
      query: GetTransportRequestsQuery,
    })

    return parseResult(res, 'request') as GQL.ShipmentRequest[]
  },
  accept: async (params: GQL.AcceptShipmentRequestInput) => {
    const res = await apolloClient.mutate({
      mutation: AcceptTransportRequestMutation,
      variables: { data: params },
    })

    return parseResult(res, 'acceptShipmentRequest') as GQL.ShipmentRequest
  },
  reject: async (params: GQL.RejectShipmentRequestInput) => {
    const res = await apolloClient.mutate({
      mutation: RejectTransportRequestMutation,
      variables: { data: params },
    })

    return parseResult(res, 'rejectShipmentRequest') as GQL.ShipmentRequest
  },
}

export default api
