import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DisplayData, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables'
import { Replay as ReloadIcon } from '@mui/icons-material'

import { getTransportRequests, rejectTransportRequest } from 'store/transportRequests/actions'
import { getGlobalData } from 'store/root'
import { RootState } from 'store'
import { APIStatus, CarrierProduct, TransportRequest, TransportRequestAction } from 'types'
import DataGrid, { getDataIndex, SORT_ORDER } from 'components/features/data/DataGrid'
import ActionButton from 'components/features/data/DataGrid/ActionButton'
import { CustomColumnOptions } from 'helpers/types'

import getTransportRequestColumns, { TransportRequestColumns } from './columns'
import { useGetOnLoad } from 'helpers/hooks'
import flattenObject from 'helpers/flattenObject'
import ActionMenu from '../ActionMenu'
import AcceptRequestDialog from '../AcceptRequestDialog'

const isLoading = (requests: APIStatus): boolean => requests === APIStatus.Loading ?? false

export type TransportRequestListProps = {
  name: string
  data: TransportRequest[]
  carrierProducts: CarrierProduct[]
  columnOptions?: CustomColumnOptions
}

const TransportRequestList = ({ name, data, carrierProducts }: TransportRequestListProps) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState<MUIDataTableOptions>({})

  const [dialogRequests, setDialogRequests] = useState<TransportRequest[]>([])
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false)

  const requestAPIStatus = useSelector((state: RootState) => state.transportRequests.status)

  useGetOnLoad(
    requestAPIStatus,
    getTransportRequests,
    <Translate value="UI.Requests.Table.Errors.LoadFailed" />
  )

  const flattenedData = useMemo(() => data.map(flattenObject), [data])

  const handleTransportRequestAction = (
    action: TransportRequestAction,
    request: TransportRequest | TransportRequest[]
  ) => {
    const requests = Array.isArray(request) ? request : [request]

    switch (action) {
      case TransportRequestAction.Accept:
        setDialogRequests(requests)
        setIsAcceptDialogOpen(true)
        break
      case TransportRequestAction.Reject:
        requests.forEach((r) => dispatch(rejectTransportRequest({ requestId: r.id })))
        break
      default:
        break
    }
  }

  const handlePerformedAction = (reloadData: boolean) => {
    if (reloadData) {
      setOptions({ rowsSelected: [] })
    }
  }

  const actions = (_displayData: DisplayData, selectedIndices: number[]) => {
    const request = selectedIndices.map((i) => data[i])

    return (
      <ActionMenu
        transportRequest={request}
        handleTransportRequestAction={handleTransportRequestAction}
      />
    )
  }

  const rowActions = (tableMeta: MUIDataTableMeta) => {
    const request = data[getDataIndex(tableMeta)]

    return (
      <ActionMenu
        transportRequest={request}
        handleTransportRequestAction={handleTransportRequestAction}
      />
    )
  }

  const columns = getTransportRequestColumns(data, rowActions)

  const defaults = {
    sort: {
      name: TransportRequestColumns.LatestDelivery,
      direction: SORT_ORDER.DESCENDING,
    },
  }

  const toolbar = () => (
    <ActionButton
      icon={<ReloadIcon />}
      label={<Translate value="UI.Table.Reload" />}
      onClick={() => dispatch(getGlobalData())}
    />
  )

  return (
    <>
      {isAcceptDialogOpen && (
        <AcceptRequestDialog
          transportRequests={dialogRequests}
          carrierProducts={carrierProducts}
          onClose={(wasActionPerformed) => {
            handlePerformedAction(wasActionPerformed)
            setIsAcceptDialogOpen(false)
          }}
        />
      )}
      <DataGrid
        name={name}
        data={flattenedData}
        columns={columns ?? []}
        actions={actions}
        toolbar={toolbar}
        loading={isLoading(requestAPIStatus)}
        defaults={defaults}
        options={options}
      />
    </>
  )
}

export default TransportRequestList
