import React, { ReactNode } from 'react'
import { Box, CircularProgress, Fade } from '@mui/material'

export type LoadingBoxProps = {
  children?: ReactNode
}

const LoadingBox = ({ children }: LoadingBoxProps) => (
  <Box display="flex" justifyContent="center" alignItems="center" padding={10}>
    <Fade in unmountOnExit timeout={400} style={{ transitionDelay: '800ms' }}>
      <Box textAlign="center">
        <CircularProgress />
        <Box margin={2}>{children}</Box>
      </Box>
    </Fade>
  </Box>
)

export default LoadingBox
