import { blue } from '@mui/material/colors'
import { Theme, ThemeOptions, createTheme, Components } from '@mui/material/styles'

import { shipment, event, itinerary, transportRequest, connection } from './domain-specific'

// Allow for MUIDataTable overrides
declare module '@mui/material/styles' {
  interface Components {
    [key: string]: ThemeOptions['components'] & any
  }
}

export type MoveThemeType = 'light' | 'dark'

export type MoveTheme = Theme & {
  shipment: typeof shipment
  itinerary: typeof itinerary
  transportRequest: typeof transportRequest
  connection: typeof connection
  event: typeof event
  drawer: {
    width: number
    background: string
  }
}

const lightTheme = createTheme({
  palette: {
    primary: {
      main: blue[800],
    },
    background: {
      default: '#f6f6f6',
    },
    mode: 'light',
  },
})

const darkTheme = createTheme({
  palette: {
    primary: {
      main: blue[600],
    },
    mode: 'dark',
    background: {
      paper: '#181818',
    },
  },
})

const components: Components = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'small',
    },
  },
}

const createMoveTheme = (type: MoveThemeType, options?: ThemeOptions): MoveTheme => {
  const muiTheme = createTheme({
    ...(type === 'light' ? lightTheme : darkTheme),
    ...options,
  })

  return {
    ...muiTheme,
    components,
    shipment,
    itinerary,
    transportRequest,
    connection,
    event,
    drawer: {
      width: 58,
      background: '#181818',
    },
  }
}

export default {
  dark: createMoveTheme('dark'),
  light: createMoveTheme('light'),
}
