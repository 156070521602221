import { graphql } from 'gql/gql'

export const TransportRequestFragment = graphql(`
  fragment TransportRequestFragment on ShipmentRequest {
    id
    earliestPickupDate
    latestPickupDate
    earliestDeliveryDate
    latestDeliveryDate
    createdAt
    status
    sourceAddress {
      ...ShipmentAddressFragment
    }
    destinationAddress {
      ...ShipmentAddressFragment
    }
    packageItems {
      ...PackageItemsFragment
    }
    maxFreightCharge
    currencyCode
  }
`)

export const GetTransportRequestsQuery = graphql(`
  query GetTransportRequests {
    request {
      ...TransportRequestFragment
    }
  }
`)
