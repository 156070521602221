import React from 'react'
import { useDispatch } from 'react-redux'

import { useInterval } from 'helpers/hooks'
import { pollGlobalData } from 'store/root'

const POLL_INTERVAL = 10 // seconds

const BackgroundWorker = () => {
  const dispatch = useDispatch()

  useInterval(() => {
    dispatch(pollGlobalData())
  }, 1000 * POLL_INTERVAL)

  return <></>
}

export default BackgroundWorker
