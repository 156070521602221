import React, { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'

import { TransportRequest, TransportRequestAction } from 'types'
import {
  useTransportRequestActions,
  ActionType as TRActionType,
} from 'services/TransportRequestActions'
import Menu from '../../data/DataGrid/ActionMenu'
import { ListItemIcon } from './styled'

export type ActionType = TRActionType

export type ActionMenuProps = {
  transportRequest: TransportRequest | TransportRequest[]
  handleTransportRequestAction: (
    action: TransportRequestAction,
    transportRequest: TransportRequest | TransportRequest[]
  ) => void
}

const ActionMenu = ({ transportRequest, handleTransportRequestAction }: ActionMenuProps) => {
  const actions = useTransportRequestActions(transportRequest)
  const [availableActions, setAvailableActions] = useState<TransportRequestAction[]>([])

  useEffect(() => {
    const available = actions.getAvailableActions()

    setAvailableActions(available)
  }, [actions])

  return (
    <Menu disabled={Boolean(!availableActions.length)}>
      {({ onClick }) =>
        availableActions.map((action) => (
          <MenuItem
            key={action}
            onClick={() => {
              onClick()
              handleTransportRequestAction(action, transportRequest)
            }}
          >
            <ListItemIcon type={actions[action].getType()}>
              {actions[action].getIcon()}
            </ListItemIcon>
            {actions[action].getLabel()}
          </MenuItem>
        ))
      }
    </Menu>
  )
}

export default ActionMenu
