import api from 'api'
import { call, put, takeLatest } from 'redux-saga/effects'
import { CarrierProduct } from 'types'
import { getCarrierProducts } from './actions'

function* getHandler({ payload = {} }: ReturnType<typeof getCarrierProducts>) {
  try {
    if (!payload.silent) yield put(getCarrierProducts.pending())

    const data: CarrierProduct[] = yield call(api.CarrierProduct.getAll)
    yield put(getCarrierProducts.success(data))
  } catch (error: any) {
    if (!payload.silent) yield put(getCarrierProducts.failure(error.message))
  }
}

export default [takeLatest(getCarrierProducts.type, getHandler)]
