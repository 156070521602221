import { Replay as ReloadIcon } from '@mui/icons-material'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'

import DataGrid, { SORT_ORDER } from 'components/features/data/DataGrid'
import ActionButton from 'components/features/data/DataGrid/ActionButton'
import { RootState } from 'store'
import { getGlobalData } from 'store/root'
import { getConnections } from 'store/connections/actions'
import { APIStatus, IolConnection } from 'types'

import flattenObject from 'helpers/flattenObject'
import { useGetOnLoad } from 'helpers/hooks'
import getConnectionColumns, { ConnectionColumns } from './columns'

const isLoading = (requests: APIStatus): boolean => requests === APIStatus.Loading ?? false

export type ConnectionsListProps = {
  name: string
  data: IolConnection[]
}

const ConnectionsList = ({ name, data }: ConnectionsListProps) => {
  const dispatch = useDispatch()

  const connectionAPIStatus = useSelector((state: RootState) => state.connections.status)

  useGetOnLoad(
    connectionAPIStatus,
    getConnections,
    <Translate value="UI.Connections.Table.Errors.LoadFailed" />
  )

  const flattenedData = useMemo(() => data.map(flattenObject), [data])

  const columns = getConnectionColumns(data)

  const defaults = {
    sort: {
      name: ConnectionColumns.CreatedAt,
      direction: SORT_ORDER.DESCENDING,
    },
  }

  const toolbar = () => (
    <ActionButton
      icon={<ReloadIcon />}
      label={<Translate value="UI.Table.Reload" />}
      onClick={() => dispatch(getGlobalData())}
    />
  )

  return (
    <>
      <DataGrid
        name={name}
        data={flattenedData}
        columns={columns ?? []}
        toolbar={toolbar}
        loading={isLoading(connectionAPIStatus)}
        defaults={defaults}
      />
    </>
  )
}

export default ConnectionsList
