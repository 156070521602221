import { graphql } from 'gql/gql'

/**
 * Invite user
 */

export const OrganizationInviteUserMutation = graphql(`
  mutation InviteUser($data: InviteUserInput!) {
    inviteUser(data: $data) {
      ...UserFragment
    }
  }
`)

/**
 * Remove user
 */

export const OrganizationRemoveUserMutation = graphql(`
  mutation RemoveUser($data: RemoveUserInput!) {
    removeUser(data: $data) {
      ...UserFragment
    }
  }
`)

export const OrganizationConnectToIolMutation = graphql(`
  mutation ConnectOrganizationToIol($data: ConnectOrganizationToIolInput!) {
    connectOrganizationToIol(data: $data) {
      connectionUrl
    }
  }
`)
