import { createAction } from '@reduxjs/toolkit'
import { GetDataArgs } from 'store/root'
import { createAsyncAction } from 'store/utils/actionCreators'
import { CarrierProduct, StoreNames } from 'types'

const name = StoreNames.carrierProducts

export const getCarrierProducts = createAsyncAction<
  GetDataArgs,
  undefined,
  CarrierProduct[],
  string
>(name)

export const clearCarrierProducts = createAction(`${name}_CLEAR`)
