import { RootState } from 'store'
import { entityAdapter } from './reducer'

export const {
  selectById: selectItineraryById,
  selectIds: selectItineraryIds,
  selectEntities: selectItineraryEntities,
  selectAll: selectAllItineraries,
  selectTotal: selectTotalItineraries,
} = entityAdapter.getSelectors((state: RootState) => state.itineraries)
