import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from 'store/utils/actionCreators'
import { GetDataArgs } from 'store/root'
import { StoreNames, TransportRequest } from 'types'
import { TransportRequestRejectParams } from 'api/types'

const name = StoreNames.transportRequests

export const getTransportRequests = createAsyncAction<
  GetDataArgs,
  undefined,
  TransportRequest[],
  string
>(name)

export const rejectTransportRequest = createAction<TransportRequestRejectParams>(`${name}_REJECT`)
export const pollTransportRequests = createAction(`${name}_POLL`)
export const clearTransportRequests = createAction(`${name}_CLEAR`)
