import { Box, Grid, Link, Paper, Typography } from '@mui/material'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { ShipmentAddress } from '../../../../../types/Shipments'
import { Field, Label } from '../styled'
import { commaFormat } from 'helpers/formatString'

type SourceDestinationProps = {
  name: string
  isDestination?: boolean
}
const SourceDestination = ({ name, isDestination }: SourceDestinationProps) => (
  <Field>
    <Label>
      <Translate value={`UI.Shipments.Details.${isDestination ? 'To' : 'From'}`} />
    </Label>
    <Typography variant="h5">{name}</Typography>
  </Field>
)

type AddressDetailsProps = {
  address: ShipmentAddress
  isDestination?: boolean
}

const AddressDetails = ({ address, isDestination }: AddressDetailsProps) => {
  const {
    name,
    address1,
    address2,
    address3,
    city,
    zipCode,
    province,
    countryName,
    contactPerson,
    email,
    phoneNumber,
  } = address

  return (
    <Grid item xs={12} md={6}>
      <Paper>
        <Box p={4} height="100%">
          <SourceDestination name={name} isDestination={isDestination} />
          <Box display="flex">
            <Box display="flex" flexDirection="column" mr={4}>
              <Label>
                <Translate value="UI.Shipments.Details.Address.Address" />
              </Label>
              <Typography variant="subtitle2">
                {address1}
                {address2 && (
                  <>
                    <br />
                    {address2}
                  </>
                )}
                {address3 && (
                  <>
                    <br />
                    {address3}
                  </>
                )}
              </Typography>
              <Typography variant="subtitle2">
                {commaFormat(city, zipCode && `${zipCode.substring(0, 3)} ${zipCode.substring(3)}`)}
              </Typography>
              <Typography variant="subtitle2">{commaFormat(province, countryName)}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Label>
                <Translate value="UI.Shipments.Details.Contact" />
              </Label>
              <Typography variant="subtitle2">{contactPerson}</Typography>
              <Typography variant="subtitle2">
                {email ?? <Link href={`mailto:${email}`}>{email}</Link>}
              </Typography>
              <Typography variant="subtitle2"> {phoneNumber}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default AddressDetails
