import { RootState } from 'store'
import { selectShipmentById } from 'store/shipments/selectors'
import { selectUserById } from 'store/users/selectors'
import { DisplayableShipment } from 'types'

export const selectShipmentsById = (
  state: RootState,
  shipmentIds: string[]
): DisplayableShipment[] =>
  shipmentIds
    .map((id) => selectShipmentById(state, id))
    .map((s) => ({
      ...s,
      user: s?.assignedUserId ? selectUserById(state, s.assignedUserId) : undefined,
    })) as DisplayableShipment[]

export const isValidDate = (d: any): boolean =>
  // eslint-disable-next-line no-restricted-globals
  d instanceof Date && !isNaN(d.getTime())
