import { createAction, EntityId } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { createAsyncAction } from 'store/utils/actionCreators'
import APIStatus from 'types/Status'
import { StoreNames } from 'types/Misc'

const name = StoreNames.snackbars

export type Request = {
  id: string
  status: APIStatus
  message: string | ReactNode
  timestamp: number
}

type LiteRequest = Pick<Request, 'id' | 'message'>

export const newRequest = createAsyncAction<undefined, LiteRequest, LiteRequest, LiteRequest>(
  `${name}_NEW_REQUEST`
)

type Snackbar = Pick<Request, 'message' | 'status'>

export const newSnackbar = createAction<Snackbar>(`${name}_NEW`)
export const clearRequests = createAction(`${name}_CLEAR`)
export const removeRequest = createAction<EntityId>(`${name}_REMOVE_ONE`)
