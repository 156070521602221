import { TransportRequest, TransportRequestAction } from 'types'

// ----- Accept -----

export const isAcceptAvailable = (request: TransportRequest) => request && true

export const isAcceptable = <T extends TransportRequest>(request: T | T[]): boolean => {
  if (Array.isArray(request)) {
    return request.every((r) => isAcceptAvailable(r))
  }

  return isAcceptAvailable(request)
}

export const isAnyAcceptable = <T extends TransportRequest>(request: T | T[]): boolean => {
  if (Array.isArray(request)) {
    return request.some((r) => isAcceptAvailable(r))
  }

  return isAcceptAvailable(request)
}

// ----- Reject -----

export const isRejectAvailable = (request: TransportRequest) => request && true

export const isRejectable = <T extends TransportRequest>(request: T | T[]): boolean => {
  if (Array.isArray(request)) {
    return request.every((r) => isRejectAvailable(r))
  }

  return isRejectAvailable(request)
}

export const isAnyRejectable = <T extends TransportRequest>(request: T | T[]): boolean => {
  if (Array.isArray(request)) {
    return request.some((r) => isRejectAvailable(r))
  }

  return isRejectAvailable(request)
}

// ----- Generic -----

export const isActionAvailable = <T extends TransportRequest>(
  request: T | T[],
  action: TransportRequestAction
): boolean => {
  switch (action) {
    case TransportRequestAction.Accept:
      return isAcceptable(request)
    case TransportRequestAction.Reject:
      return isRejectable(request)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}

// ----- Generic -----

export const isActionAvailableOnAny = <T extends TransportRequest>(
  request: T | T[],
  action: TransportRequestAction
): boolean => {
  switch (action) {
    case TransportRequestAction.Accept:
      return isAnyAcceptable(request)
    case TransportRequestAction.Reject:
      return isAnyRejectable(request)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
