import { createAction } from '@reduxjs/toolkit'
import { AssignShipmentParams } from 'api/types'
import { GetDataArgs } from 'store/root'
import { StoreNames } from 'types/Misc'
import { createActionWithCallback, createAsyncAction } from 'store/utils/actionCreators'
import { Shipment } from 'types/Shipments'
import { User } from 'types'

const name = StoreNames.shipments

type AssignParams = Pick<AssignShipmentParams, 'shipmentId'> & {
  user: User
}

export const setShipment = createAction<Shipment>(`${name}_SET_ONE`)
export const setShipments = createAction<Shipment[]>(`${name}_SET_MANY`)
export const assignShipment = createAction<AssignParams>(`${name}_ASSIGN`)
export const unassignShipment = createAction<Shipment | Shipment[]>(`${name}_UNASSIGN`)
export const pickupShipment = createActionWithCallback<Shipment | Shipment[]>(`${name}_PICKUP`)
export const getShipments = createAsyncAction<GetDataArgs, undefined, Shipment[], string>(name)
export const clearShipments = createAction(`${name}_CLEAR`)

export const pollShipments = createAction(`${name}_POLL`)
export const setPollData = createAction<Shipment[]>(`${name}_SET_POLL_DATA`)
export const applyPollData = createAction(`${name}_APPLY_POLL_DATA`)
