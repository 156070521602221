import { FetchResult } from '@apollo/client'

type Options = {
  min: number
  max: number
}

const defaultOptions = {
  min: 1000,
  max: 3000,
}

/**
 * Randomly resolves/rejects sometime within min and max
 */
export const chaosPromise = <T>(res: T, options: Options = defaultOptions) =>
  new Promise<T>((resolve, reject) => {
    const didFail = Math.random() > 0.5
    const timeout = Math.random() * (options.max - options.min) + options.min

    setTimeout(() => {
      if (didFail) reject()
      resolve(res)
    }, timeout)
  })

export const NOT_IMPLEMENTED = () => Promise.reject(new Error('Not implemented'))

export const parseResult = <T>(res: FetchResult<T>, dataKey: keyof T): T[keyof T] => {
  if (res.data) return res.data[dataKey]

  if (res.errors) {
    throw new Error(`GQL error ${res.errors.map((e) => e.message).join(' ')}`)
  }

  throw new Error('Unknown error')
}
