import { createSlice } from '@reduxjs/toolkit'
import User, { Role } from 'types/User'
import { StoreNames } from 'types/Misc'
import { setUser, clearUser, setDarkMode, setSettings } from './actions'

export interface UserSettings {
  darkMode: boolean
  autoUpdate: boolean
}

interface InitialState {
  profile: User
  settings: UserSettings
}

const initialState: InitialState = {
  profile: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: Role.Courier,
    fullName: '',
  },
  settings: {
    darkMode: false,
    autoUpdate: true,
  },
}

const userSlice = createSlice({
  name: StoreNames.user,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, { payload }) => {
      state.profile = payload
    })
    builder.addCase(clearUser, (state) => {
      state.profile = initialState.profile
    })
    builder.addCase(setDarkMode, (state, { payload }) => {
      state.settings.darkMode = payload
    })
    builder.addCase(setSettings, (state, { payload }) => {
      state.settings = payload
    })
  },
})

export default userSlice
