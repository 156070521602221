import { graphql } from 'gql/gql'

export const CreateConnectionMutation = graphql(`
  mutation CreateConnection($data: CreateConnectionInput!) {
    createConnection(data: $data) {
      connection {
        id
        connectionName
        createdAt
        status
      }
    }
  }
`)
