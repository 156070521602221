import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { Translate } from 'react-redux-i18n'

export type SelectNameProps = {
  value: string
  onChange: (newName: string) => void
}

const SelectName = ({ value, onChange }: SelectNameProps) => {
  const [text, setText] = useState(value)

  useEffect(() => {
    setText(value)
  }, [value])

  const handleChange = (newText: string) => {
    setText(newText)
    onChange(newText)
  }

  return (
    <TextField
      sx={{ width: '100%' }}
      value={text}
      onChange={(e) => handleChange(e.target.value)}
      label={<Translate value="UI.Itineraries.Properties.Name" />}
      variant="outlined"
    />
  )
}

export default SelectName
