import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { Clear, Replay as ReloadIcon, MoreHoriz } from '@mui/icons-material'

import { getUsers } from 'store/users/actions'
import { selectAllUsers } from 'store/users/selectors'
import { newSnackbar } from 'store/snackbars/actions'
import { getGlobalData } from 'store/root'
import { RootState } from 'store'
import { APIStatus, User } from 'types'
import DataGrid, { SORT_ORDER } from 'components/features/data/DataGrid'
import ActionButton from 'components/features/data/DataGrid/ActionButton'
import { CustomColumnOptions } from 'helpers/types'

import getUserColumns, { UserColumns, USER_ID_INDEX } from './columns'
import RemoveDialog from './RemoveDialog'
import { IconButton } from '@mui/material'

const TABLE_CONFIG_NAME = 'USERS'

type UserListProps = {
  actions?: ReactNode
  columnOptions?: CustomColumnOptions
  currentUserId?: string
}

const UserList = ({ actions, columnOptions, currentUserId }: UserListProps) => {
  const dispatch = useDispatch()

  const data: User[] = useSelector(selectAllUsers)
  const apiStatus = useSelector((state: RootState) => state.users.status)

  useEffect(() => {
    if (apiStatus === APIStatus.Idle) {
      dispatch(getUsers())
    } else if (apiStatus === APIStatus.Failed) {
      dispatch(
        newSnackbar({
          message: <Translate value="UI.Users.Errors.LoadFailed" />,
          status: APIStatus.Failed,
        })
      )
    }
  }, [apiStatus, dispatch])

  const [userToRemove, setUserToRemove] = useState<User['id'] | undefined>()

  const rowActions = useCallback(
    (tableMeta: MUIDataTableMeta) => {
      const userId = tableMeta.rowData[USER_ID_INDEX]
      const isSelf = userId === currentUserId

      return (
        !isSelf && (
          <ActionButton
            icon={<Clear />}
            color="error"
            label={<Translate value="UI.Users.Table.Remove" />}
            onClick={() => setUserToRemove(tableMeta.rowData[0])}
          />
        )
      )
    },
    [currentUserId]
  )

  const columns: MUIDataTableColumn[] = useMemo(
    () => getUserColumns(rowActions, columnOptions),
    [rowActions, columnOptions]
  )

  const defaults = {
    sort: {
      name: UserColumns.FullName,
      direction: SORT_ORDER.ASCENDING,
    },
  }

  const toolbar = () => (
    <>
      <ActionButton
        icon={<ReloadIcon />}
        label={<Translate value="UI.Table.Reload" />}
        onClick={() => dispatch(getGlobalData())}
      />
      {actions}
    </>
  )

  return (
    <>
      <RemoveDialog
        isOpen={userToRemove !== undefined}
        userId={userToRemove ?? ''}
        onClose={() => setUserToRemove(undefined)}
      />
      <DataGrid
        name={TABLE_CONFIG_NAME}
        data={data}
        columns={columns}
        toolbar={toolbar}
        loading={apiStatus === APIStatus.Loading}
        defaults={defaults}
        options={{ selectableRows: 'multiple' }}
        actions={() => (
          <IconButton disabled>
            <MoreHoriz sx={{ marginY: 0.5 }} />
          </IconButton>
        )}
      />
    </>
  )
}

export default UserList
