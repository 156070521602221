import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import { ReactComponent as Svg } from './path.svg'

const DeliverIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 35" color="primary">
      <Svg />
    </SvgIcon>
  )
}

export default DeliverIcon
