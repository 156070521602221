import { I18nState } from 'react-redux-i18n'
import { RootState } from 'store'
import { StoreNames } from 'types/Misc'

const getState = (state: RootState) => state[StoreNames.i18n]

// Some smelly hacks to get this selector working
export const getLocale = (state: RootState) =>
  (getState(state) as unknown as I18nState).locale ?? undefined

export default getLocale
