import { createAction } from '@reduxjs/toolkit'
import { ConnectionCreateParams } from 'api/types'
import { GetDataArgs } from 'store/root'
import { createAsyncAction, createActionWithCallback } from 'store/utils/actionCreators'
import { IolConnection, StoreNames } from 'types'

const name = StoreNames.connections

export const getConnections = createAsyncAction<GetDataArgs, undefined, IolConnection[], string>(
  name
)
export const clearConnections = createAction(`${name}_CLEAR`)

export const createConnection = createAction<ConnectionCreateParams>(`${name}_CREATE`)

export const connectToIol = createActionWithCallback<undefined>(`${name}_CONNECTTOIOL`)
