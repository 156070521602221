import { I18n } from 'react-redux-i18n'

const emailRegEx = /^[^@\s]+@[^@\s]+\.[^@\s]+$/

export type ValidationError = {
  valid: boolean
  error: string | undefined
}

export const validateFirstName = (name: string | undefined): ValidationError => {
  // No interaction from user if value is null
  if (name === undefined) return { valid: false, error: undefined }

  let error

  if (!name) {
    error = I18n.t('UI.Users.Invite.Validation.Name')
  } else if (name.length < 3) {
    error = I18n.t('UI.Users.Invite.Validation.NameLength')
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}

export const validateLastName = validateFirstName

export const validatePassword = (password: string | undefined): ValidationError => {
  // No interaction from user if value is null
  if (password === undefined) return { valid: false, error: undefined }

  let error

  if (!password) {
    error = I18n.t('UI.Users.Invite.Validation.Password')
  } else if (password.length < 8) {
    error = I18n.t('UI.Users.Invite.Validation.PasswordLength')
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}

export const validateEmail = (email: string | undefined): ValidationError => {
  // No interaction from user if value is null
  if (email === undefined) return { valid: false, error: undefined }

  let error

  if (!email) {
    error = I18n.t('UI.Users.Invite.Validation.Email')
  } else if (!emailRegEx.test(email)) {
    error = I18n.t('UI.Users.Invite.Validation.EmailFormat')
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}
