import { MUISortOptions, MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables'

export { useConfigPreserver } from './hooks'

/**
 * Used to preserve the current table config across re-renders and page reloads.
 *
 * Includes:
 *  Users current filters
 *  Shown columns
 *  Column order
 *  Sort configuration
 */

const baseKey = 'TABLE_CONFIG'

const getKey = (name: string) => `${baseKey}_${name}`
const getProfileKey = (name: string) => `${baseKey}_${name}_PROFILES`

export type TableConfig = {
  filters?: string[][]
  order?: number[]
  sort?: MUISortOptions
  showColumns?: string[]
}

export type ProfileConfigs = Record<string, TableConfig>

// Generate default table config
export const defaultConfig = (columns: string[], defaults?: TableConfig) =>
  ({
    filters: columns.map(() => []),
    showColumns: columns,
    ...defaults,
  } as TableConfig)

export class ConfigPreserver {
  name: string

  columns: string[]

  defaults?: TableConfig

  // Static methods for updating config outside DataGrid
  static getConfig = (name: string) =>
    JSON.parse(localStorage.getItem(getKey(name)) || '{}') as TableConfig

  static setConfig = (name: string, config: TableConfig) =>
    localStorage.setItem(getKey(name), JSON.stringify(config))

  static getProfiles = (name: string) =>
    JSON.parse(localStorage.getItem(getProfileKey(name)) || '{}') as ProfileConfigs

  // How do I load a config inside component (through DataGrid & preserver hook)?
  static addProfile = (name: string, profile: string, config: TableConfig) =>
    localStorage.setItem(
      getProfileKey(name),
      JSON.stringify({ ...ConfigPreserver.getProfiles(name), [profile]: config })
    )

  static removeProfile = (name: string, profile: string) =>
    localStorage.setItem(
      getProfileKey(name),
      JSON.stringify({ ...ConfigPreserver.getProfiles(name), [profile]: undefined })
    )

  // Preserver requires a name and all valid columns, optionally default TableConfig options
  constructor(name: string, columns: string[], defaults?: TableConfig) {
    this.name = name
    this.columns = columns
    this.defaults = defaults
  }

  // Updates columns with config values
  sync = (columns: MUIDataTableColumn[]) => {
    const config = this.getConfig()

    return columns.map((column, index) => {
      column.options = column.options ?? {}

      if (config.filters && column.options.filter !== false) {
        column.options.filterList = config.filters[index]
      }
      // Verify shown columns with localstore on reload
      if (config.showColumns && column.options.viewColumns !== false) {
        column.options.display = config.showColumns.includes(column.name)
      }
      return column
    })
  }

  // Updates options with caching callbacks
  apply = (options: MUIDataTableOptions): MUIDataTableOptions => {
    const config = this.getConfig()

    return {
      ...options,
      sortOrder: config.sort,
      columnOrder: config.order,
      onFilterChange: (_, filterList) => this.setFilters(filterList),
      onViewColumnsChange: (changedColumn, action: string) =>
        this.setShowColumns(changedColumn, action === 'add'),
      onColumnOrderChange: (columnOrder) => this.setOrder(columnOrder),
      onColumnSortChange: (changedColumn, direction) =>
        this.setSort({
          name: changedColumn,
          direction,
        }),
    }
  }

  loadProfile = (profile: string) => {
    const loaded = ConfigPreserver.getProfiles(this.name)[profile]
    return loaded && this.setConfig(loaded)
  }

  saveProfile = (profile: string) =>
    ConfigPreserver.addProfile(this.name, profile, this.getConfig())

  removeProfile = (profile: string) => ConfigPreserver.removeProfile(this.name, profile)

  getProfileNames = () => Object.keys(ConfigPreserver.getProfiles(this.name))

  getConfig = () => ConfigPreserver.getConfig(this.name)

  setConfig = (config: TableConfig) => ConfigPreserver.setConfig(this.name, config)

  setDefault = () => this.setConfig(defaultConfig(this.columns, this.defaults))

  setFilters = (filters: TableConfig['filters']) => this.setConfig({ ...this.getConfig(), filters })

  setOrder = (order: TableConfig['order']) => this.setConfig({ ...this.getConfig(), order })

  setSort = (sort: TableConfig['sort']) => this.setConfig({ ...this.getConfig(), sort })

  setShowColumns = (column: string, shouldExist: boolean) => {
    const newColumns = this.getConfig().showColumns ?? []
    const isIncluded = newColumns.includes(column)

    if (shouldExist && !isIncluded) {
      newColumns.push(column)
    } else if (!shouldExist && isIncluded) {
      newColumns.splice(newColumns.indexOf(column), 1)
    }

    this.setConfig({ ...this.getConfig(), showColumns: newColumns })
  }

  isConfigValid = (): boolean => {
    const { showColumns = [], order, sort, filters } = this.getConfig()

    const validColumns = showColumns.every((col) => this.columns.includes(col))
    const validOrder = !order || order?.length === this.columns.length
    const validSort = !sort || this.columns.includes(sort?.name)
    const validFilter = !filters || filters.length === this.columns.length

    return this.isConfigCached() && validColumns && validOrder && validSort && validFilter
  }

  isConfigCached = (): boolean => Object.keys(this.getConfig()).length !== 0
}

export default ConfigPreserver
