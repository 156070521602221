import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, DialogActions, DialogContent, DialogTitle, Grid, ListItem } from '@mui/material'
import { Translate } from 'react-redux-i18n'

import { UserInviteParams } from 'api/types'
import { inviteUser } from 'store/users/actions'
import { Role } from 'types'

import LoadingButton from 'components/ui/LoadingButton'
import InviteUserForm from './InviteUserForm'

import { InviteDialog, InviteUserList } from './styled'

export type UserTemplate = {
  email: string
  password: string
  firstName: string
  lastName: string
  role: Role
}

export type InviteUserModalProps = {
  onClose: () => void
  onInvite: (success: boolean) => void
  isVisible: boolean
}

export const InviteUserModal = ({ onClose, onInvite, isVisible: isOpen }: InviteUserModalProps) => {
  const dispatch = useDispatch()

  const [user, setUser] = useState<UserTemplate>()
  const [inputsValidated, setInputsValidated] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleInvite = () => {
    setLoading(true)
    dispatch(
      inviteUser({
        data: user as UserInviteParams,
        callback: (success: boolean) => {
          setLoading(false)
          onInvite(success)
        },
      })
    )
  }

  const handleUserUpdate = useCallback((u: UserTemplate) => {
    setUser(u)
  }, [])

  const handleValidationUpdate = (valid: boolean) => {
    setInputsValidated(valid)
  }

  return (
    <InviteDialog open={isOpen} onClose={onClose} aria-labelledby="invite-user-dialog-title">
      <DialogTitle id="invite-user-dialog-title">
        <Translate value="UI.Users.Invite.InviteUser" />
      </DialogTitle>
      <DialogContent>
        <InviteUserList>
          <ListItem dense disableGutters>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <InviteUserForm onChange={handleUserUpdate} onValidation={handleValidationUpdate} />
              </Grid>
            </Grid>
          </ListItem>
        </InviteUserList>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleInvite}
          color="primary"
          variant="outlined"
          disabled={!inputsValidated}
          label={<Translate value="UI.Users.Invite.Invite" />}
          isLoading={isLoading}
        />
        <Button onClick={onClose} color="error">
          <Translate value="General.Cancel" />
        </Button>
      </DialogActions>
    </InviteDialog>
  )
}
export default InviteUserModal
