import { graphql } from 'gql/gql'

export const ItineraryCoreFragment = graphql(`
  fragment ItineraryCoreFragment on Itinerary {
    id
    name
    createdAt
    startDate
    status
    shipmentIds
  }
`)

export const ItineraryStopFragment = graphql(`
  fragment ItineraryStopFragment on ItineraryStop {
    id
    addressString
    city
    order
    geoLocation {
      lat
      lng
    }
  }
`)

export const ItineraryStopActionFragment = graphql(`
  fragment ItineraryStopActionFragment on ItineraryStopAction {
    type
    status
    shipment {
      ...ShipmentFragment
    }
  }
`)

export const ItineraryFragment = graphql(`
  fragment ItineraryFragment on Itinerary {
    ...ItineraryCoreFragment
    assignedUser {
      ...UserFragment
    }
    stops {
      ...ItineraryStopFragment
      actions {
        ...ItineraryStopActionFragment
      }
    }
  }
`)

/**
 * Get itinerary
 */

export const GetItineraryQuery = graphql(`
  query GetItinerary($itineraryId: String!) {
    itinerary(itineraryId: $itineraryId) {
      ...ItineraryFragment
    }
  }
`)

/**
 * Get itineraries
 */

export const GetItinerariesQuery = graphql(`
  query GetItineraries($filter: ItineraryFilterInput!) {
    itineraries(filter: $filter) {
      ...ItineraryFragment
    }
  }
`)
