import React from 'react'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { ItineraryAction } from 'types'

// ----- Cancel -----

export const cancelIcon = (props?: SvgIconProps): React.ReactNode => <CancelIcon {...props} />

// ----- Generic -----

export const getActionIcon = (action: ItineraryAction, props?: SvgIconProps): React.ReactNode => {
  switch (action) {
    case ItineraryAction.Cancel:
      return cancelIcon(props)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
