import React, { ReactNode } from 'react'
import { PaperProps } from '@mui/material'
import { ShipmentStatus } from 'types/Shipments'
import ShipmentStatusChip from 'components/features/shipments/ShipmentStatusChip'

import { StyledBox, Header, ChipWrapper } from './styled'

type NumberBoxProps = {
  header: string | ReactNode
  onClick?: () => void
  value: number
  statuses: ShipmentStatus[]
}
const NumberBox = ({ header, onClick, value, statuses, ...props }: PaperProps & NumberBoxProps) => (
  <StyledBox onClick={onClick} clickable={(onClick !== undefined).toString()} {...props}>
    <div>
      <Header>{header}</Header>
      <ChipWrapper>
        {statuses.map((s) => (
          <ShipmentStatusChip status={s} key={s} />
        ))}
      </ChipWrapper>
    </div>
    <span>{value}</span>
  </StyledBox>
)

export default NumberBox
