import { I18n } from 'react-redux-i18n'

const tableLabels = () => ({
  body: {
    noMatch: I18n.t('UI.Shipments.Table.NoMatch'),
    toolTip: I18n.t('UI.Shipments.Table.Toolbar.Sort'),
  },
  pagination: {
    next: I18n.t('UI.Shipments.Table.Pagination.NextPage'),
    previous: I18n.t('UI.Shipments.Table.Pagination.PreviousPage'),
    rowsPerPage: I18n.t('UI.Shipments.Table.Pagination.RowsPerPage'),
    displayRows: I18n.t('UI.Shipments.Table.Pagination.OutOf'),
  },
  toolbar: {
    search: I18n.t('UI.Shipments.Table.Toolbar.Search'),
    downloadCsv: I18n.t('UI.Shipments.Table.Toolbar.DownloadCSV'),
    print: I18n.t('UI.Shipments.Table.Toolbar.Print'),
    viewColumns: I18n.t('UI.Shipments.Table.Toolbar.ViewColumns'),
    filterTable: I18n.t('UI.Shipments.Table.Toolbar.FilterTable'),
  },
  filter: {
    all: I18n.t('UI.Shipments.Table.Filter.All'),
    title: I18n.t('UI.Shipments.Table.Filter.Title'),
    reset: I18n.t('UI.Shipments.Table.Filter.Reset'),
  },
  viewColumns: {
    title: I18n.t('UI.Shipments.Table.ViewColumns.Title'),
  },
  selectedRows: {
    text: I18n.t('UI.Shipments.Table.SelectedRows.SelectedAffix'),
    delete: I18n.t('UI.Shipments.Table.SelectedRows.Delete'),
  },
})

export default tableLabels
