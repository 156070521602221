import { put, takeLatest, call } from 'redux-saga/effects'
import { getTransportRequests, pollTransportRequests, rejectTransportRequest } from './actions'
import { TransportRequest } from 'types'
import api from 'api'
import { nanoid } from '@reduxjs/toolkit'
import { I18n } from 'react-redux-i18n'
import { newRequest } from 'store/snackbars/actions'
import { getGlobalData } from 'store/root'

function* getHandler({ payload = {} }: ReturnType<typeof getTransportRequests>) {
  try {
    if (!payload.silent) yield put(getTransportRequests.pending())

    const data: TransportRequest[] = yield call(api.TransportRequest.getAll)
    yield put(getTransportRequests.success(data))
  } catch (error: any) {
    if (!payload.silent) yield put(getTransportRequests.failure(error.message))
  }
}

function* pollHandler() {
  try {
    const data: TransportRequest[] = yield call(api.TransportRequest.getAll)
    yield put(getTransportRequests.success(data))
  } catch (error: any) {}
}

function* rejectHandler({ payload }: ReturnType<typeof rejectTransportRequest>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Requests.StatusMessages.Reject.Loading'),
    success: I18n.t('UI.Requests.StatusMessages.Reject.Success', {
      id: payload.requestId,
    }),
    failure: I18n.t('UI.Requests.StatusMessages.Reject.Failed', {
      id: payload.requestId,
    }),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    yield call(api.TransportRequest.reject, payload)
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    yield put(getGlobalData())
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

export default [
  takeLatest(getTransportRequests.type, getHandler),
  takeLatest(pollTransportRequests.type, pollHandler),
  takeLatest(rejectTransportRequest.type, rejectHandler),
]
