import { graphql } from 'gql/gql'

export const UserFragment = graphql(`
  fragment UserFragment on User {
    firstName
    lastName
    fullName @client
    role
    id
    email
  }
`)

/**
 * Get user
 */

export const GetUserQuery = graphql(`
  query GetUser($userId: String!) {
    user(id: $userId) {
      ...UserFragment
    }
  }
`)

/**
 * Get self (user)
 */

export const GetMeQuery = graphql(`
  query GetMe {
    me {
      ...UserFragment
    }
  }
`)
