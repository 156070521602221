import React, { useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'

import { selectDisplayableShipments } from 'store/shipments/selectors'
import { ShipmentStatus, DisplayableShipment } from 'types/Shipments'
import { ConfigPreserver } from 'services/ConfigPreserver'
import Title from 'components/ui/Title'
import ShipmentsList from 'components/features/shipments/ShipmentList'
import {
  SHIPMENT_STATUS_INDEX,
  ShipmentColumns,
} from 'components/features/shipments/ShipmentList/columns'

import NumberBox from './NumberBox'
import { CustomColumnOptions } from 'helpers/types'

export const SHIPMENT_TABLE_CONFIG_NAME = 'SHIPMENTS'

const filters: Record<string, ShipmentStatus[]> = {
  unassigned: [ShipmentStatus.Available],
  assigned: [ShipmentStatus.Reserved],
  inDelivery: [ShipmentStatus.Transit],
  issues: [ShipmentStatus.OnHold],
}

const filterData = (data: DisplayableShipment[], filter: ShipmentStatus[]) =>
  data.filter((shipment) => filter.includes(shipment.status))

const Shipments = () => {
  const theme = useTheme()
  const data = useSelector(selectDisplayableShipments)

  const [customColumnOptions, setCustomOptions] = useState<CustomColumnOptions>({})

  const setFilter = (filter: string[] | undefined) => {
    const oldConfig = ConfigPreserver.getConfig(SHIPMENT_TABLE_CONFIG_NAME)
    const newFilters = oldConfig.filters ?? Object.keys(ShipmentColumns).map(() => [])
    newFilters[SHIPMENT_STATUS_INDEX] = filter ?? []
    ConfigPreserver.setConfig(SHIPMENT_TABLE_CONFIG_NAME, {
      ...oldConfig,
      filters: newFilters,
    })
    setCustomOptions({
      status: {
        filterList: filter,
      },
    })
  }

  // TODO: Make this a separate component, maybe find a more suitable name
  const StatisticBox = ({
    i18nLabel,
    filterKey,
  }: {
    i18nLabel: string
    filterKey: keyof typeof filters
  }) => {
    const value = filterData(data, filters[filterKey]).length
    return (
      <Grid item xs={6} sm={3}>
        <NumberBox
          onClick={() => setFilter(filters[filterKey])}
          header={<Translate value={`UI.Shipments.Statistics.${i18nLabel}`} />}
          style={{ width: '100%', height: '100%' }}
          value={value}
          statuses={filters[filterKey]}
          key={i18nLabel}
        />
      </Grid>
    )
  }

  return (
    <div>
      <Title>
        <Translate value="UI.Shipments.Title" />
      </Title>
      <Grid container spacing={2} style={{ marginBottom: theme.spacing(2) }}>
        <StatisticBox filterKey="unassigned" i18nLabel="Unassigned" />
        <StatisticBox filterKey="assigned" i18nLabel="Assigned" />
        <StatisticBox filterKey="inDelivery" i18nLabel="InDelivery" />
        <StatisticBox filterKey="issues" i18nLabel="Issues" />
      </Grid>
      <ShipmentsList
        name={SHIPMENT_TABLE_CONFIG_NAME}
        data={data}
        columnOptions={customColumnOptions}
      />
    </div>
  )
}

export default Shipments
