import { apolloClient } from 'api/client'
import { parseResult } from 'api/util'
import { GetCarrierProductsQuery } from './queries'

import * as GQL from 'gql/graphql'

const api = {
  getAll: async () => {
    const res = await apolloClient.query({
      query: GetCarrierProductsQuery,
    })

    return (parseResult(res, 'products') ?? []) as GQL.Product[]
  },
}

export default api
