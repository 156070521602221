import styled from 'styled-components'

export const Container = styled.div`
  padding: 48px;

  ${(p) => p.theme.breakpoints.down('md')} {
    padding: 16px;
  }
`

export const Content = styled.div`
  ${(p) => p.theme.breakpoints.up('md')} {
    margin-left: ${(p) => p.theme.drawer.width}px;
  }
`
export const LayoutWrapper = styled.div`
  min-height: 100%;
  max-width: 100%;
  overflow: hidden;
`
