import styled from 'styled-components'

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(p) => p.theme.spacing(2)};

  &:last-child {
    margin-bottom: 0;
  }
`

export const Label = styled.div`
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: ${(p) => p.theme.spacing(1)};
`
