import { graphql } from 'gql/gql'

export const GetConnectionsQuery = graphql(`
  query GetConnections {
    connections {
      id
      connectionName
      createdAt
      status
      connectionToSelf
    }
  }
`)
