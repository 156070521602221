import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'

import TransportRequestList from 'components/features/transportRequests/TransportRequestList'
import Title from 'components/ui/Title'
import { selectAllCarrierProducts } from 'store/carrierProducts/selectors'
import { selectAllTransportRequests } from 'store/transportRequests/selectors'

export const TRANSPORTREQUEST_LIST = 'TRANSPORTREQUEST_LIST'

const TransportRequests = () => {
  const requests = useSelector(selectAllTransportRequests)
  const carrierProducts = useSelector(selectAllCarrierProducts)

  return (
    <>
      <Title>
        <Translate value="UI.Requests.Title" />
      </Title>
      <TransportRequestList
        name={TRANSPORTREQUEST_LIST}
        data={requests}
        carrierProducts={carrierProducts}
      />
    </>
  )
}

export default TransportRequests
