import { Box } from '@mui/material'
import styled from 'styled-components'

export const ChangePasswordBox = styled(Box)`
  & > * {
    margin-bottom: ${(p) => p.theme.spacing(2)};
  }

  width: 500px;

  ${(p) => p.theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export default ChangePasswordBox
