import React, { useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { InputField } from '../styled'

export type SelectDateProps = {
  value: Date | null
  onChange: (selectedDate: Date | null) => void
  label: string | React.ReactNode
  helperText?: string
}

const SelectDate = ({ label, value, onChange, helperText }: SelectDateProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value)

  useEffect(() => {
    setSelectedDate(value)
  }, [value])

  const handleChange = (newDate: Date | null) => {
    setSelectedDate(newDate)
    onChange(newDate)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleChange}
        renderInput={(params) => (
          <InputField
            required
            helperText={helperText}
            size="small"
            sx={{ width: '100%' }}
            {...params}
          />
        )}
        clearable={false}
      />
    </LocalizationProvider>
  )
}

export default SelectDate
