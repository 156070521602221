import React from 'react'
import { MUIDataTableColumn } from 'mui-datatables'
import { I18n, Translate } from 'react-redux-i18n'

import { IolConnection } from 'types'
import { dayMonth } from 'helpers/formatDate'
import mergeColumnOptions from 'helpers/mergeColumnOptions'
import { CustomColumnOptions } from 'helpers/types'
import ConnectionStatusChip from '../ConnectionStatusChip'

export enum ConnectionColumns {
  Name = 'connectionName',
  Status = 'status',
  CreatedAt = 'createdAt',
}

const getConnectionColumns: (
  data: IolConnection[],
  columnOptions?: CustomColumnOptions
) => MUIDataTableColumn[] = (data, columnOptions) => {
  const columns: MUIDataTableColumn[] = [
    {
      label: I18n.t('UI.Connection.Table.Name'),
      name: ConnectionColumns.Name,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Connection.Table.Name" />,
        filter: true,
      },
    },
    {
      label: I18n.t('UI.Connection.Table.Status'),
      name: ConnectionColumns.Status,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Connection.Table.Status" />,
        customBodyRenderLite: (dataIndex) => {
          const { status } = data[dataIndex]
          return <ConnectionStatusChip status={status} />
        },
        filter: true,
        sort: false,
      },
    },
    {
      label: I18n.t('UI.Connection.Table.CreatedAt'),
      name: ConnectionColumns.CreatedAt,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Connection.Table.CreatedAt" />,
        customBodyRender: dayMonth,
        filter: true,
      },
    },
  ]

  mergeColumnOptions(columnOptions, columns)

  return columns
}

export default getConnectionColumns
