import { Itinerary, ItineraryAction, ItineraryStatus } from 'types'

// ----- Cancel -----

export const isCancelAvailable = (status: ItineraryStatus) => status !== ItineraryStatus.Cancelled

export const isCancellable = <T extends Itinerary>(itinerary: T | T[]): boolean => {
  if (Array.isArray(itinerary)) {
    return itinerary.every((s) => isCancelAvailable(s.status))
  }

  return isCancelAvailable(itinerary.status)
}

// ----- Generic -----

export const isActionAvailable = <T extends Itinerary>(
  itinerary: T | T[],
  action: ItineraryAction
): boolean => {
  switch (action) {
    case ItineraryAction.Cancel:
      return isCancellable(itinerary)
    default:
      throw new Error(`Unsupported action ${action}`)
  }
}
