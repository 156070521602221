import { nanoid } from '@reduxjs/toolkit'
import { I18n } from 'react-redux-i18n'
import { put, takeLatest, call } from 'redux-saga/effects'
import { newRequest } from 'store/snackbars/actions'
import { Itinerary } from 'types'
import api from 'api'
import {
  setItinerary,
  getItineraries,
  createItinerary,
  addShipmentsToItinerary,
  removeShipmentsFromItinerary,
  reorderItineraryStops,
  setItineraryStopOrder,
  assignItinerary,
  unassignItinerary,
  getItinerary,
  pollItineraries,
} from './actions'
import { getShipments } from 'store/shipments/actions'

function* getAllHandler({ payload = {} }: ReturnType<typeof getItineraries>) {
  let data: Itinerary[]

  try {
    if (!payload.silent) {
      yield put(getItineraries.pending())
    }
    data = yield call(api.Itinerary.getAll)
    yield put(getItineraries.success(data))
  } catch (error: any) {
    if (!payload.silent) {
      yield put(getItineraries.failure(error.message))
    }
  }
}

function* pollHandler() {
  try {
    const data: Itinerary[] = yield call(api.Itinerary.getAll)
    yield put(getItineraries.success(data))
  } catch (error: any) {}
}

function* getHandler({ payload }: ReturnType<typeof getItinerary>) {
  let data: Itinerary

  try {
    data = yield call(api.Itinerary.get, payload.id)
    yield put(setItinerary(data))
  } catch (error: any) {}
}

function* createHandler({ payload }: ReturnType<typeof createItinerary>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.Create.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.Create.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.Create.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.create, payload.data)
    yield put(setItinerary(i))
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    payload.callback?.(true, i.id)
  } catch (error: any) {
    payload.callback?.(false)
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* addShipmentsHandler({ payload }: ReturnType<typeof addShipmentsToItinerary>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.Shipments.Add.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.Shipments.Add.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.Shipments.Add.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.addShipments, payload)
    yield put(setItinerary(i))
    yield put(getShipments())
    yield put(newRequest.success({ id: snackId, message: messages.success }))
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* removeShipmentsHandler({ payload }: ReturnType<typeof removeShipmentsFromItinerary>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.Shipments.Remove.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.Shipments.Remove.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.Shipments.Remove.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.removeShipments, payload)
    yield put(setItinerary(i))
    yield put(getShipments())
    yield put(newRequest.success({ id: snackId, message: messages.success }))
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* reorderStopHandler({ payload }: ReturnType<typeof reorderItineraryStops>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.Stops.Reorder.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.Stops.Reorder.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.Stops.Reorder.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.reorderStop, payload)
    yield put(setItinerary(i))
    yield put(newRequest.success({ id: snackId, message: messages.success }))
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* setStopOrderHandler({ payload }: ReturnType<typeof setItineraryStopOrder>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.Stops.SetOrder.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.Stops.SetOrder.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.Stops.SetOrder.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.setStopOrder, payload)
    yield put(setItinerary(i))
    yield put(newRequest.success({ id: snackId, message: messages.success }))
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* assignHandler({ payload }: ReturnType<typeof assignItinerary>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.User.Assign.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.User.Assign.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.User.Assign.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.assign, payload.data)
    yield put(setItinerary(i))
    yield put(getShipments())
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    payload?.callback?.(true)
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
    payload?.callback?.(false)
  }
}

function* unassignHandler({ payload }: ReturnType<typeof unassignItinerary>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Itineraries.StatusMessages.User.Unassign.Loading'),
    success: I18n.t('UI.Itineraries.StatusMessages.User.Unassign.Success'),
    failure: I18n.t('UI.Itineraries.StatusMessages.User.Unassign.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const i: Itinerary = yield call(api.Itinerary.unassign, payload.data)
    yield put(setItinerary(i))
    yield put(getShipments())
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    payload?.callback?.(true)
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
    payload?.callback?.(false)
  }
}

export default [
  takeLatest(getItineraries.type, getAllHandler),
  takeLatest(getItinerary, getHandler),
  takeLatest(createItinerary.type, createHandler),
  takeLatest(addShipmentsToItinerary.type, addShipmentsHandler),
  takeLatest(removeShipmentsFromItinerary.type, removeShipmentsHandler),
  takeLatest(reorderItineraryStops.type, reorderStopHandler),
  takeLatest(setItineraryStopOrder.type, setStopOrderHandler),
  takeLatest(assignItinerary.type, assignHandler),
  takeLatest(unassignItinerary.type, unassignHandler),
  takeLatest(pollItineraries.type, pollHandler),
]
