import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Translate } from 'react-redux-i18n'
import { Dictionary } from '@reduxjs/toolkit'

import {
  DisplayableShipment,
  Itinerary,
  Perhaps,
  Shipment,
  ShipmentAction,
  ShipmentStatus,
  User,
} from 'types'
import {
  addShipmentsToItinerary,
  assignItinerary,
  getItinerary,
  removeShipmentsFromItinerary,
  unassignItinerary,
} from 'store/itineraries/actions'
import { selectAllUsers, selectUserEntities } from 'store/users/selectors'
import ItineraryShipmentList from 'components/features/itineraries/ItineraryShipmentList'
import shortenUUID from 'helpers/shortenUUID'
import LoadingBox from 'components/ui/LoadingBox'
import Title from 'components/ui/Title'

import useItinerary from '../shared/useItinerary'
import ItineraryStopList from './ItineraryStopList'
import ItineraryDetailData from './ItineraryDetailData'
import SelectUser from '../shared/SelectUser'
import { getActionIcon } from 'services/ShipmentActions/icons'
import { pickupShipment } from 'store/shipments/actions'
import { AddCircleOutline } from '@mui/icons-material'
import AddShipmentsModal from 'components/features/itineraries/AddShipmentsModal'
import { useDisjointShipments, useItineraryEligibleShipments } from 'helpers/hooks'
import { useShipmentActions } from 'services/ShipmentActions'

const ITINERARY_SHIPMENT_TABLE_CONFIG_NAME = 'ITINERARY_SHIPMENT_LIST'

const getItineraryShipments = (
  itinerary: Itinerary,
  users: Dictionary<User>
): DisplayableShipment[] => {
  const itineraryShipments = itinerary.stops.reduce<{ [id: string]: Shipment }>((acc, next) => {
    next.actions.forEach((a) => {
      acc[a.shipment.id] = a.shipment
    })

    return acc
  }, {})

  return Object.values(itineraryShipments ?? {}).map((s) => {
    return {
      ...s,
      user: s.assignedUserId ? users[s.assignedUserId] : undefined,
    }
  })
}

const ItineraryDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [itinerary] = useItinerary(id)

  const users = useSelector(selectAllUsers)
  const userDict = useSelector(selectUserEntities)
  const allShipments = useItineraryEligibleShipments(id)

  const [itineraryShipments, setItineraryShipments] = useState<DisplayableShipment[]>([])
  const availableShipments = useDisjointShipments(allShipments, itineraryShipments)
  const [user, setUser] = useState(itinerary?.assignedUser)

  const [isAddShipmentsModalOpen, setIsAddShipmentsModalOpen] = useState(false)
  const shipmentActions = useShipmentActions(itineraryShipments)

  useEffect(() => {
    if (itinerary) {
      const is = getItineraryShipments(itinerary, userDict)
      setItineraryShipments(is)
    }
  }, [allShipments, itinerary, userDict])

  useEffect(() => {
    setUser(itinerary?.assignedUser)
  }, [itinerary])

  if (!itinerary) {
    return (
      <LoadingBox>
        <Translate value="UI.Itineraries.Details.Loading" />
      </LoadingBox>
    )
  }

  const handleChangeUserCallback =
    (currentUser: Perhaps<User>, newUser: User | undefined) => (success: boolean) => {
      if (!success) setUser(currentUser)
      else {
        setUser(newUser)
        dispatch(getItinerary({ id: itinerary.id }))
      }
    }

  const handleChangeUser = (u: User | undefined) => {
    if (u) {
      dispatch(
        assignItinerary({
          data: { courierId: u.id, itineraryId: itinerary.id },
          callback: handleChangeUserCallback(user, u),
        })
      )
    } else {
      dispatch(
        unassignItinerary({
          data: { itineraryId: itinerary.id },
          callback: handleChangeUserCallback(user, u),
        })
      )
    }
  }

  const handlePickupAllShipments = () => {
    const eligibleShipments = itineraryShipments.filter((s) => s.status === ShipmentStatus.Reserved)

    dispatch(
      pickupShipment({
        data: eligibleShipments,
        callback: (successful) => {
          if (successful) dispatch(getItinerary({ id: itinerary.id }))
        },
      })
    )
  }

  const handleAddShipments = (shipment: DisplayableShipment | DisplayableShipment[]) => {
    dispatch(
      addShipmentsToItinerary({
        itineraryId: itinerary.id,
        shipmentIds: Array.isArray(shipment) ? shipment.map((s) => s.id) : [shipment.id],
      })
    )
  }

  const handleRemoveShipment = (shipment: DisplayableShipment | DisplayableShipment[]) => {
    dispatch(
      removeShipmentsFromItinerary({
        itineraryId: itinerary.id,
        shipmentIds: Array.isArray(shipment) ? shipment.map((s) => s.id) : [shipment.id],
      })
    )
  }

  return (
    <>
      <Title id={id}>
        <Translate value="Entities.Itinerary.Singular" />{' '}
        <span style={{ fontWeight: 'bold' }}>{itinerary.name ?? shortenUUID(itinerary.id)}</span>
      </Title>
      <ItineraryDetailData itinerary={itinerary} />
      <Typography variant="h5" sx={{ mb: 4 }}>
        <Translate value="UI.Itineraries.Details.Courier" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item mb={4}>
          <SelectUser user={user} users={users} onChange={handleChangeUser} />
        </Grid>
      </Grid>
      <Typography variant="h5" sx={{ mb: 4 }}>
        <Translate value="UI.Itineraries.Details.Stops.Title" />
      </Typography>
      <ItineraryStopList itineraryId={itinerary.id} stops={itinerary.stops} />
      <Box marginY={4}>
        <Typography variant="h5">
          <Translate value="UI.Itineraries.Details.Shipments" />
        </Typography>
      </Box>
      <Box mb={4}>
        <Button
          sx={{ mr: 2 }}
          color="primary"
          variant="outlined"
          startIcon={<AddCircleOutline />}
          onClick={() => setIsAddShipmentsModalOpen(true)}
        >
          <Translate value="UI.Itineraries.Create.AddShipments" />
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!shipmentActions.pickup.isAvailableOnAny()}
          startIcon={getActionIcon(ShipmentAction.Pickup)}
          onClick={() => handlePickupAllShipments()}
        >
          <Translate value="UI.Itineraries.Details.PickupAll" />
        </Button>
      </Box>
      <ItineraryShipmentList
        name={ITINERARY_SHIPMENT_TABLE_CONFIG_NAME}
        data={itineraryShipments}
        onRemove={handleRemoveShipment}
      />
      {isAddShipmentsModalOpen && (
        <AddShipmentsModal
          open={isAddShipmentsModalOpen}
          onClose={() => setIsAddShipmentsModalOpen(false)}
          data={availableShipments}
          onSelect={handleAddShipments}
        />
      )}
    </>
  )
}

export default ItineraryDetails
