import { Box } from '@mui/material'
import { AssignmentInd, AssignmentLate, Cancel } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useShipmentActions } from 'services/ShipmentActions'
import { Shipment, User } from 'types'
import { selectAllUsers } from 'store/users/selectors'
import { unassignShipment } from 'store/shipments/actions'
import AssignUserDialog from 'components/features/shipments/AssignUserDialog'
import CancelShipmentDialog from 'components/features/shipments/CancelShipmentDialog'
import { Button } from './styled'

type ShipmentActionsProps = {
  shipment: Shipment
  user?: User
}

const ShipmentActions = ({ shipment, user }: ShipmentActionsProps) => {
  const dispatch = useDispatch()
  const users = useSelector(selectAllUsers)
  const actions = useShipmentActions(shipment)

  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const handleUnassign = (s: Shipment) => {
    dispatch(unassignShipment(s))
  }

  return (
    <Box mb={4}>
      {actions.assign.isAvailable() && (
        <Button onClick={() => setIsAssignDialogOpen(true)} variant="outlined" color="primary">
          <Box marginRight={1} alignItems="center" display="inline-flex">
            <AssignmentInd />
          </Box>
          {actions.assign.getLabel({ nullIfUnavailable: true })}
        </Button>
      )}
      {isAssignDialogOpen && (
        <AssignUserDialog
          users={users}
          onClose={() => {
            setIsAssignDialogOpen(false)
          }}
          shipments={[shipment]}
        />
      )}
      {actions.unassign.isAvailable() && (
        <Button onClick={() => handleUnassign(shipment)} variant="outlined" color="primary">
          <Box marginRight={1} alignItems="center" display="inline-flex">
            <AssignmentLate />
          </Box>
          {actions.unassign.getLabel({ nullIfUnavailable: true })}
        </Button>
      )}
      {actions.cancel.isAvailable() && (
        <Button onClick={() => setIsCancelDialogOpen(true)} variant="outlined" color="error">
          <Box marginRight={1} alignItems="center" display="inline-flex">
            <Cancel />
          </Box>
          {actions.cancel.getLabel({ nullIfUnavailable: true })}
        </Button>
      )}
      {isCancelDialogOpen && (
        <CancelShipmentDialog
          onClose={() => {
            setIsCancelDialogOpen(false)
          }}
          shipments={[{ ...shipment, user }]}
        />
      )}
    </Box>
  )
}

export default ShipmentActions
