import { nanoid } from '@reduxjs/toolkit'
import { I18n } from 'react-redux-i18n'
import { put, takeLatest, call } from 'redux-saga/effects'

import api from 'api'
import { newRequest } from 'store/snackbars/actions'
import { User } from 'types'
import { getUsers, uninviteUser, inviteUser, removeUser, setUser } from './actions'

function* getHandler({ payload = {} }: ReturnType<typeof getUsers>) {
  let data: User[]

  try {
    if (!payload.silent) {
      yield put(getUsers.pending())
    }
    data = yield call(api.User.getAll)
    yield put(getUsers.success(data))
  } catch (error: any) {
    if (!payload.silent) {
      yield put(getUsers.failure(error.message))
    }
  }
}

function* uninviteHandler({ payload }: ReturnType<typeof uninviteUser>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Users.StatusMessages.Remove.Loading'),
    success: I18n.t('UI.Users.StatusMessages.Remove.Success', {
      user: payload.userId,
    }),
    failure: I18n.t('UI.Users.StatusMessages.Remove.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    yield call(api.User.remove, payload)
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    yield put(removeUser(payload.userId))
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* inviteHandler({ payload }: ReturnType<typeof inviteUser>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Users.StatusMessages.Invite.Loading'),
    success: I18n.t('UI.Users.StatusMessages.Invite.Success'),
    failure: I18n.t('UI.Users.StatusMessages.Invite.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const u: User = yield call(api.User.invite, payload.data)
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    yield put(setUser(u))
    payload.callback?.(true)
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
    payload.callback?.(false)
  }
}

export default [
  takeLatest(getUsers.type, getHandler),
  takeLatest(uninviteUser.type, uninviteHandler),
  takeLatest(inviteUser.type, inviteHandler),
]
