import { Grid } from '@mui/material'
import styled from 'styled-components'

export type PotentiallyLastProps = {
  $last: boolean
}

export const PotentiallyLast = styled(Grid)<PotentiallyLastProps>`
  ${(p) =>
    p.$last &&
    `
  margin-bottom: -${p.theme.spacing(2)} 
`}
`
