import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import { ShipmentStatus } from 'types/Shipments'

type StatusChipProps = {
  status: ShipmentStatus
}

export const StatusChip = styled(Chip)<StatusChipProps>`
  /* color: white; */
  color: ${(p) => p.theme.shipment.status[p.status]};
  border-color: ${(p) => p.theme.shipment.status[p.status]};
  font-weight: 600;
`

export default StatusChip
